<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body">
      <div class="trade__modal">
        <div class="trade__modal__header">
          <h1>{{ $store.state.data.trades.calcDataModalProps.header }}</h1>
          <div class="trade__modal__header__buttons">
            <button href="#" class="btn" v-if="!isChanged" @click="saveCalc">Сохранить</button>
            <button href="#" class="btn" style="margin-left: 5px" v-if="$store.state.data.trades.calcDataModalProps.data && $store.state.data.trades.calcDataModalProps.data.id" @click="downloadCalc($store.state.data.trades.calcDataModalProps.data.id)">PDF</button>
            <button class="btn btn--gray" style="margin-left: 5px" @click.prevent="switchModal">Закрыть</button>
          </div>
        </div>
        <div class="trade__modal__body">
          <div class="ct__inputs">
            <div class="ct__col mb-30">
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="name">Наименование:</label>
                    <input type="text" id="name" v-model="createFields.name">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="propType">Тип имущества:</label>
                    <select v-model="createFields.propType" id="propType">
                      <option v-for="(item, mIndex) in propTypeSelect" :key="mIndex" :value="item.value">{{ item.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="price">Стоимость:</label>
                    <input type="text" id="price" v-model="modelPrice" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="prepaid">Аванс:</label>
                    <input type="text" id="prepaid" v-model="modelPrepaid"  onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="prepaidProc">Аванс(%)</label>
                    <input type="number" id="prepaidProc" v-model.number="prepaidProc">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="duration">Срок лизинга</label>
                    <input type="number" id="duration" v-model.number="createFields.duration">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="rate">Ставка привлечения</label>
                    <input type="number" id="rate" v-model.number="createFields.rate">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="managerCom">Комиссия менеджера</label>
                    <input type="number" id="managerCom" v-model.number="createFields.managerCom">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="agentCom">Комиссия агента</label>
                    <input type="number" id="agentCom" v-model.number="createFields.agentCom">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="graphType">Вид графика</label>
                    <select v-model="createFields.graphType" id="graphType">
                      <option v-for="(item, mIndex) in graphTypeSelect" :key="mIndex" :value="item.value">{{ item.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3" v-if="createFields.graphType === 'drawdown' || createFields.graphType === 'annuityStair'">
                  <div class="ct__input">
                    <label for="strength">Сила наклона</label>
                    <input type="number" min="0" max="300" step="1" id="strength" v-model.number="createFields.strength">
                  </div>
                </div>
                <div class="ct__col-3" v-if="createFields.graphType === 'annuityStair'">
                  <div class="ct__input">
                    <label for="stair">Месяцев в ступени</label>
                    <select id="stair" v-model="createFields.stair" class="dark-select">
                      <option
                        v-for="(item, mIndex) in graphStairSelect"
                        :key="mIndex"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3" v-if="['realEstate'].includes(createFields.propType)">
                  <div class="ct__input">
                    <label for="nds">НДС (%)</label>
                    <select v-model="createFields.nds">
                      <option value=null default>Без НДС</option>
                      <option value="20">20</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ct__col">
          <div class="graph" @click="handlerGraph = !handlerGraph">
            <h3>График</h3>
            <div>
              <svg v-if="!handlerGraph" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerGraph">
            <ApolloQuery
              v-if="input"
              :query="require('@/graphql/queries/TryCalc.gql')"
              :variables="{ input }"
              @error="handleErrors"
              class="calc__info"
              v-bind:class="{ 'calc__info--center': this.$store.state.me.role === 'partner' }"
            >
              <template slot-scope="{ result: { loading, error, data } }">
                <div v-if="error" class="messages apollo error">
                  <div class="messages__status error">{{ error.message }}</div>
                </div>
                <template v-if="!loading && !error">
                  <div class="calc__info__header" v-if="data">
                    <div v-if="$store.state.me.role !== 'partner'" class="_row">
                      <div class="calc__info__item calc__info__item--orange _col-3">
                        <span class="calc__info__item__title">Сумма договора</span>
                        <span class="calc__info__item__subtitle">{{ prettifyPrice(data.tryCalc.summ) }} руб.</span>
                      </div>
                      <div class="calc__info__item _col-3">
                        <span class="calc__info__item__title">Минимальная ставка</span>
                        <span class="calc__info__item__subtitle">{{ prettifyPrice(data.tryCalc.minRate) }} %</span>
                      </div>
                      <div class="calc__info__item _col-3">
                        <span class="calc__info__item__title">Максимальная комиссия</span>
                        <span class="calc__info__item__subtitle">
                          <span>{{ prettifyPrice(data.tryCalc.maxAgent) }} %</span>
                        </span>
                      </div>
                    </div>
                    <div v-if="$store.state.me.role !== 'partner'" class="_row">
                      <div class="calc__info__download _col-1">
                        <a href="#" class="btn" @click="downloadCalc('d669589a-71ad-11ea-80bf-00259047d1e4')">Скачать оферту.pdf</a>
                      </div>
                    </div>
                    <div class="_row">
                    <div v-bind:style="{ display: 'none' }">{{ readSum(prettifyPrice(data.tryCalc.summ)) }}</div>
                    <div :class="{ '_col-2': data.tryCalc.graph.length > 14, '_col-1': data.tryCalc.graph.length <= 14}">
                      <vue-good-table v-if="data.tryCalc.graph.length <= 14" :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(data.tryCalc.graph)" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                      <vue-good-table v-else :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(data.tryCalc.graph.slice(0, data.tryCalc.graph.length / 2))" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                    </div>
                    <div class="_col-2" v-if="data.tryCalc.graph.length > 14">
                      <vue-good-table :columns="columns" :pagination-options="{enabled: false}" :rows="data.tryCalc.graph.slice(data.tryCalc.graph.length / 2, data.tryCalc.graph.length)" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.plan) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                    </div>
                    <!-- <div class="calc__info__download _col-1">
                      <a href="#" class="btn btn--black" style="" @click.prevent>Завести проект</a>
                    </div> -->
                  </div>
                </div>
                </template>
              </template>
            </ApolloQuery>
            <div v-else class="messages apollo error">
              <div class="messages__status error">Заполните поля: "Стоимость", "Аванс" и "Срок лимита"</div>
            </div>
          </div>
        </div>
        <div class="ct__col" v-if="['first', 'created', 'meeting', 'documents'].includes($store.state.Lead.status) && $store.state.data.trades.calcDataModalProps.type === 'add'">
          <div class="graph" @click="handlerCalc = !handlerCalc">
            <h3>Созданные калькуляторы</h3>
            <div>
              <svg v-if="!handlerCalc" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerCalc">
            <ApolloQuery
              :query="require('@/graphql/queries/AllCalculators.gql')"
              :variables="{
                free: true,
                q: this.q,
                filter: {
                  user: this.$store.state.me.user_id,
                  groupId: this.$store.state.me.group_id
                },
                page: 1,
                perPage: 10
              }"
              class="calc__info"
            >
              <template slot-scope="{ result: { error, data } }">
                <div v-if="error" class="messages apollo error">
                  <div class="messages__status error">{{ error.message }}</div>
                </div>
                <div v-if="!error && !data" class="messages apollo error">
                  <div class="messages__status">Загрузка</div>
                </div>
                <div class="tbl" v-else-if="data">
                  <vue-good-table
                    :columns="columnsCalc"
                    :rows="data.allCalculators || []"
                    :select-options="{ selectOnCheckboxOnly: true, selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }"
                    @on-selected-rows-change="selectedRowsChange"
                    max-height="500px"
                    :line-numbers="true"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: {field: 'created_date', type: 'desc'}
                    }"
                  >
                    <div slot="table-actions">
                      <input type="text" v-model="q">
                    </div>
                    <div slot="emptystate">Данных нет</div>
                    <template slot="table-row" slot-scope="props">
                      <div v-if="props.column.field === 'name'"><span>{{ props.row.name }}</span></div>
                      <div v-else-if="props.column.field === 'product'"><span>{{ props.row.product }}</span></div>
                      <div v-else-if="props.column.field === 'price'"><span>{{ props.row.price }}</span></div>
                      <div v-else-if="props.column.field === 'created_date'"><span>{{ props.row.created_date ? new Date(props.row.created_date).toLocaleDateString() : null }}</span></div>
                    </template>
                  </vue-good-table>
                </div>
              </template>
            </ApolloQuery>
            <div class="buttons">
              <div class="btn" @click="saveCalcs">Сохранить</div>
            </div>
          </div>
        </div>
        <!-- <div class="trade__modal__footer">
          <a href="#" class="btn" v-if="!isChanged" @click="saveCalc">Сохранить</a>
          <a href="#" class="btn" style="margin-left: 5px" v-if="$store.state.data.trades.calcDataModalProps.data && $store.state.data.trades.calcDataModalProps.data.id" @click="downloadCalc($store.state.data.trades.calcDataModalProps.data.id)">PDF</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ADD_CALC from '@/graphql/mutations/AddCalc.gql'
import UPDATE_CALC from '@/graphql/mutations/UpdateCalc.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import MinusIco from '@/components/svg/minus'
import BIND_CALC from '@/graphql/mutations/BindCalc.gql'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TradeCalcModal',
  components: { MinusIco },
  data: () => {
    return {
      handlerGraph: false,
      handlerCalc: false,
      calcActive: true,
      q: '',
      columns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Сумма платежа',
          field: 'plan'
        }
      ],
      columnsCalc: [
        { label: 'Заголовок', field: 'name', hidden: false },
        { label: 'Дата создания', field: 'created_date', hidden: false },
        { label: 'Имущество', field: 'product', hidden: false },
        { label: 'Стоимость', field: 'price', hidden: false }
      ],
      createFields: {
        name: '',
        price: 0,
        rate: 0,
        prepaid: 0,
        duration: 0,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 0,
        graphType: 'annuity',
        propType: 'light',
        nds: null
      },
      graphTypeSelect: [{
        value: 'annuity',
        name: 'Равномерный'
      }, {
        value: 'drawdown',
        name: 'Убывающий'
      }, {
        value: 'annuityStair',
        name: 'Равномерный | Лестница'
      }],
      propTypeSelect: [{
        value: 'light',
        name: 'Легковой автотранспорт'
      }, {
        value: 'lightcommercial',
        name: 'Легкий коммерческий автотранспорт'
      }, {
        value: 'commercial',
        name: 'Коммерческий автотранспорт'
      }, {
        value: 'special',
        name: 'Спец техника'
      }, {
        value: 'equipment',
        name: 'Оборудование'
      }, {
        value: 'realEstate',
        name: 'Недвижимость'
      }, {
        value: 'residentialEstate',
        name: 'Жилая недвижимость'
      }],
      graphStairSelect: [
        {
          value: 1,
          name: 3
        },
        {
          value: 2,
          name: 6
        },
        {
          value: 3,
          name: 9
        },
        {
          value: 4,
          name: 12
        }
      ],
      selectedRows: []
    }
  },
  computed: {
    modelPrice: {
      get () {
        return parseFloat(this.createFields.price).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.createFields.price = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.createFields.price) || typeof this.createFields.price !== 'number') this.createFields.price = 0
      }
    },
    modelPrepaid: {
      get () {
        return parseFloat(this.createFields.prepaid).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.createFields.prepaid = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.createFields.prepaid) || typeof this.createFields.prepaid !== 'number') this.createFields.prepaid = 0
      }
    },
    showModal () {
      return this.$store.state.data.trades.calcModal
    },
    prepaidProc: {
      get () {
        return 100 * this.createFields.prepaid / this.createFields.price || 0
      },
      set (newValue) {
        this.createFields.prepaid = this.createFields.price * newValue / 100 || 0
      }
    },
    isChanged () {
      if (this.$store.state.data.trades.calcDataModalProps.type === 'update') {
        return (this.createFields.price === '' || this.createFields.price === this.$store.state.data.trades.calcDataModalProps.data.price) &&
          (this.createFields.prepaid === '' || this.createFields.prepaid === this.$store.state.data.trades.calcDataModalProps.data.prepaid) &&
          (this.createFields.duration === '' || this.createFields.duration === this.$store.state.data.trades.calcDataModalProps.data.duration) &&
          (this.createFields.rate === '' || this.createFields.rate === this.$store.state.data.trades.calcDataModalProps.data.rate)
      } else {
        return [
          this.createFields.price,
          this.createFields.rate,
          this.createFields.prepaid,
          this.createFields.duration,
          this.createFields.managerCom,
          this.createFields.agentCom,
          this.createFields.strength,
          this.createFields.stair].every(val => val === 0 || val === '0')
      }
    },
    input () {
      if (
        this.createFields.duration === 0 ||
        this.createFields.price === 0 ||
        this.createFields.prepaid === 0) return null
      return {
        ...this.createFields
      }
    }
  },
  methods: {
    saveCalcs () {
      if (this.selectedRows.length) {
        this.selectedRows.forEach(id => {
          this.$apollo
            .mutate({
              mutation: BIND_CALC,
              variables: {
                leadId: this.$route.params.id,
                calcId: id
              },
              update: (_, { data }) => {
                if (data) {
                  this.$notify({
                    group: 'lsg-notify',
                    text: `Калькулятор добавлен`
                  })
                  this.$router.go()
                }
              }
            })
        })
      }
    },
    selectedRowsChange ({ selectedRows }) {
      this.selectedRows = selectedRows.map(row => row.id)
    },
    handleErrors (e) {
      // this.$notify({
      //   group: 'lsg-notify',
      //   text: e.message
      // })
    },
    readSum (value) {
      this.sum = value
      return value
    },
    readPayment (value) {
      this.payment = value[1].plan
      return value
    },
    switchModal () {
      this.$store.state.data.trades.calcModal = !this.$store.state.data.trades.calcModal
      this.$store.state.data.trades.calcDataModalProps.leadId = null
      this.$store.state.data.trades.calcDataModalProps.type = null
      this.$store.state.data.trades.calcDataModalProps.header = null
      this.$store.state.data.trades.calcDataModalProps.data = null
    },
    removeCalc (e) {
      e.preventDefault()

      this.$emit('removeAddCalc')
    },
    async saveCalc (e) {
      e.preventDefault()

      let currentInput = {
        leadId: this.$store.state.data.trades.calcDataModalProps.leadId,
        ...this.createFields
        // price: Number(this.createFields.price),
        // rate: Number(this.createFields.rate),
        // prepaid: Number(this.createFields.prepaid),
        // duration: Number(this.createFields.duration)
      }

      if (this.$store.state.data.trades.calcDataModalProps.type !== 'add') currentInput['id'] = this.$store.state.data.trades.calcDataModalProps.data.id

      await this.$apollo.mutate({
        mutation: this.$store.state.data.trades.calcDataModalProps.type === 'add' ? ADD_CALC : UPDATE_CALC,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString()
    },
    downloadCalc (calcId) {
      window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=` + calcId, '_blank')
    }
  },
  watch: {
    'createFields.propType' () {
      if (this.createFields.propType === 'realEstate') this.createFields.nds = '20'
      else this.createFields.nds = null
    },
    '$store.state.data.trades.calcModal' () {
      if (this.$store.state.data.trades.calcDataModalProps.type === 'update') {
        this.createFields.name = this.$store.state.data.trades.calcDataModalProps.data.name
        this.createFields.propType = this.$store.state.data.trades.calcDataModalProps.data.propType
        this.createFields.graphType = this.$store.state.data.trades.calcDataModalProps.data.graphType
        this.createFields.price = Number(this.$store.state.data.trades.calcDataModalProps.data.price)
        this.createFields.prepaid = Number(this.$store.state.data.trades.calcDataModalProps.data.prepaid)
        this.createFields.strength = Number(this.$store.state.data.trades.calcDataModalProps.data.strength)
        this.createFields.stair = Number(this.$store.state.data.trades.calcDataModalProps.data.stair)
        this.createFields.duration = Number(this.$store.state.data.trades.calcDataModalProps.data.duration)
        this.createFields.rate = Number(this.$store.state.data.trades.calcDataModalProps.data.rate)
        this.createFields.managerCom = Number(this.$store.state.data.trades.calcDataModalProps.data.managerCom)
        this.createFields.agentCom = Number(this.$store.state.data.trades.calcDataModalProps.data.agentCom)
      } else {
        this.createFields = {
          name: '',
          price: 0,
          rate: 0,
          prepaid: 0,
          duration: 0,
          managerCom: 0,
          agentCom: 0,
          strength: 0,
          stair: 0,
          graphType: 'annuity',
          propType: 'light',
          nds: null
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.modal__body {
  width 60%

  +below(703px) {
    width 90%
  }
}
.ct__inputs {
  max-width none
}
.trade__modal__body {
  margin-bottom 0
}
.graph {
  display flex
  flex-direction row
  justify-content space-between
  cursor pointer
  align-items center
}
</style>

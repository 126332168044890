<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModalCancel"></div>
    <div class="modal__body modal__edo">
      <div class="modal__attention__title modal__edo__title"><h3>Создание маршрута</h3></div>
      <div class="modal__edo__item modal__edo--center">
        <SelectComponent
          v-if="signerList && signerList.length"
          v-model="selectedSigner"
          :options="signerList"
          :key="rerenderKey + 'sc'"
          :value="getDefaultSigner(signerList)"
          label-name="name"
          title="Выберите подписанта"
          style="margin-bottom: 10px"
        />
      </div>
      <div v-if="isStandartRoute" class="modal__edo__container" :key="rerenderKey">
        <draggable v-model="waypoints" group="waypoints">
          <div
            class="modal__edo__waypoint"
            v-for="(field, index) in waypoints"
            :key="index + rerenderKey + 's'"
            :class="{ 'modal__edo__waypoint--move': move && moveWaypoint === index && field.type !== 'Поручитель' }"
          >
            <div
              v-if="field.type !== 'Поручитель'"
              class="modal__edo__move"
              @mousedown="move(index, 'down')"
              @mouseleave="move(index, 'leave')"
            >
              <MoveIcon/>
            </div>
            <div
              v-else
              class="modal__edo__move modal__edo__move--disabled"
            >
              <MoveIcon/>
            </div>
            <div class="modal__edo__item">
              <div class="modal__edo__select-stub">{{ field ? field.type: '' }}</div>
            </div>
            <div class="modal__edo__item">
              <SelectComponent
                v-model="field.selectedOperator"
                :options="operatorList"
                :value="field.selectedOperator"
                label-name="name"
                title="Выберите оператора"
                style="margin-bottom: 10px"
              />
            </div>
            <div class="modal__edo__item">
              <div class="modal__edo__select-stub">{{ field.selectedRecipient && field.selectedRecipient.name }}</div>
            </div>
          </div>
        </draggable>
        <div class="modal__edo__files">
          <span>Файлы:</span>
          <ul>
            <li v-for="(file, index) in getAvailableFiles('Индивидуальный маршрут')" :key="index + 123">
              <a v-if="file" :href="'https://' + file.path" download>{{ file.filename }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="modal__attention__btns">
        <div class="modal__attention__btn" @click="postFiles">Создать</div>
        <div class="modal__attention__btn" @click="switchModalCancel">Отмена</div>
      </div>
    </div>
  </div>
</template>
<script>

import SelectComponent from '@/components/ui/select'
import axios from 'axios'

import SIGNERS from '@/graphql/queries/EdoSigners.gql'
import CREATE_ROUTE from '@/graphql/mutations/CreateEdoRoute.gql'
import CREATE_WAYPOINT from '@/graphql/mutations/CreateEdoWaypoint.gql'
import CREATE_FILES from '@/graphql/mutations/CreateEdoWaypointFiles.gql'
import MoveIcon from '@/components/svg/MoveIcon'

export default {
  name: 'EdoModal',
  components: {
    SelectComponent,
    MoveIcon
  },
  computed: {
    isDkpRoute () {
      if (this.getAvailableFiles('Маршрут по поставщику').length) return true

      return false
    },
    isDpRoute () {
      if (this.getAvailableFiles('Маршрут по поручителю').length) return true

      return false
    },
    isStandartRoute () {
      if (this.getAvailableFiles('Индивидуальный маршрут').length) return true

      return false
    },
    showModal () {
      return this.$store.state.data.edo.modal.show
    },
    recipient () {
      return this.$store.state.data.edo.modal.recipient
    },
    recipientList () {
      return this.$store.state.data.edo.modal.recipientList
    },
    defaultSelectedSigner () {
      const result = this.signerList.find(elem => (elem.group.id === this.$store.state.me.group.id && (elem.roles.find(item => (item === 'Руководитель подразделения (Лизинг)' || item === 'Генеральный директор')))))
      return result
    }
  },
  watch: {
    showModal: function (newValue) {
      if (newValue) {
        const waypoints = [
          {
            selectedOperator: {
              name: 'Диадок',
              field: 'diadoc'
            },
            selectedRecipient: this.$store.state.data.edo.modal.recipient,
            selectedSigner: null,
            type: 'Лизингополучатель'
          }
        ]

        if (this.isDkpRoute && this.$store.state.data.edo.modal.recipientList[1]) {
          waypoints.push({
            selectedOperator: {
              name: 'Диадок',
              field: 'diadoc'
            },
            selectedRecipient: this.$store.state.data.edo.modal.recipientList[1],
            selectedSigner: null,
            type: 'Поставщик'
          })
        }

        if (this.isDpRoute && this.$store.state.data.edo.modal.guarantor) {
          waypoints.push({
            selectedOperator: {
              name: 'Диадок',
              field: 'diadoc'
            },
            selectedRecipient: this.$store.state.data.edo.modal.guarantor,
            selectedSigner: null,
            type: 'Поручитель'
          })
        }

        this.waypoints = waypoints
      }
    },
    selectedOperator: async function (newValue) {
      if (newValue.field === 'СБИС') this.operatorForSigner = 'sbis'
      else if (newValue.field === 'Диадок') this.operatorForSigner = 'diadoc'

      await this.getSigners()
    },
    defaultSelectedSigner: function (newValue) {
      this.selectedSigner = newValue
    },
    recipient: function (newValue, oldValue) {
      this.selectedRecipient = newValue
    }
  },
  data () {
    return {
      baseURL: process.env.VUE_APP_HTTP,
      operatorForSigner: null,
      rerenderKey: 0,
      isMove: false,
      moveWaypoint: -1,
      dkpRoute: false,
      dpRoute: false,
      standartRoute: true,
      createRouteList: [],
      waypoints: [
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: this.$store.state.data.edo.modal.recipient,
          selectedSigner: null,
          type: 'Лизингополучатель'
        },
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: this.$store.state.data.edo.modal.recipientList[1],
          selectedSigner: null,
          type: 'Поставщик'
        }
      ],
      providerWaypoints: [
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: null,
          selectedSigner: null
        },
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: this.$store.state.data.edo.modal.recipient || null,
          selectedSigner: null
        }
      ],
      guarantorWaypoints: [
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: null,
          selectedSigner: null
        },
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: null,
          selectedSigner: null
        },
        {
          selectedOperator: {
            name: 'Диадок',
            field: 'diadoc'
          },
          selectedRecipient: null,
          selectedSigner: null
        }
      ],
      operatorList: [
        {
          name: 'СБИС',
          field: 'sbis'
        },
        {
          name: 'Диадок',
          field: 'diadoc'
        }
      ],
      selectedOperator: {
        name: 'Диадок',
        field: 'diadoc'
      },
      selectedRecipient: null,
      selectedRecipientProvider: null,
      selectedRecipientGuarantor: null,
      selectedSigner: null,
      signerList: [],
      dkpRouteFileViews: [ // Поставщик
        'Проект Договора купли-продажи',
        'Проект Договора куплипродажи',
        'Проект Доп.Соглашения к ДКП',
        'Проект Соглашения о расторжении ДКП'
      ],
      dpRouteFileViews: [ // Поручитель
        'Проект договора поручительства',
        'Проект Доп.Соглашения к ДП'
      ]
    }
  },
  async created () {
    await this.getSigners()
    this.waypoints[0].selectedRecipient = this.$store.state.data.edo.modal.recipient
  },
  methods: {
    getAvailableFiles (routeName) {
      const files = [...this.$store.state.data.edo.modal.edoDataArray]

      if (routeName === 'Индивидуальный маршрут') {
        return files
      } else if (routeName === 'Маршрут по поставщику') {
        return files.map(file => {
          if ([...this.dkpRouteFileViews].includes(file.documentType)) return file
        }).filter(file => file)
      } else if (routeName === 'Маршрут по поручителю') {
        return files.map(file => {
          if ([...this.dpRouteFileViews].includes(file.documentType)) return file
        }).filter(file => file)
      }

      return files
    },
    async getSigners () {
      await this.$apollo.mutate({
        mutation: SIGNERS,
        variables: {
          operator: this.operatorForSigner || 'diadoc'
        },
        update: (store, { data }) => {
          if (data && data.edoSigners) {
            this.signerList = data.edoSigners
            this.waypoints[0].selectedSigner = this.getDefaultSigner(this.signerList)
          }
        }
      })
    },
    getDefaultSigner (signers) {
      const defaultSigner = signers
        .find(elem => (
          elem.group.id === this.$store.state.me.group.id &&
          (
            elem.roles.find(item => (
              item === 'Руководитель подразделения (Лизинг)' ||
              item === 'Генеральный директор')
            )
          )
        ))
      return defaultSigner
    },
    move (index, mouseEvent) {
      if (mouseEvent === 'down') {
        this.isMove = true
        this.moveWaypoint = index
      } else if (mouseEvent === 'leave') {
        this.isMove = false
        this.moveWaypoint = -1
      }
    },
    removeWaypoint (index) {
      this.waypoints.splice(index, 1)
    },
    addWaypoint (index) {
      this.waypoints.splice(index + 1, 0, {
        selectedOperator: {
          name: 'Диадок',
          field: 'Диадок'
        },
        selectedRecipient: null,
        selectedSigner: null
      })
    },
    async createRouteFiles (files) {
      const result = await this.$apollo
        .mutate({
          mutation: CREATE_FILES,
          variables: {
            edoWaypointFiles: files
          }
        })

      return result
    },
    async createRouteWaypoint (waypoint) {
      const result = await this.$apollo
        .mutate({
          mutation: CREATE_WAYPOINT,
          variables: {
            edoWaypoint: waypoint
          }
        })

      return result
    },
    async createRoute (route) {
      const result = await this.$apollo
        .mutate({
          mutation: CREATE_ROUTE,
          variables: {
            edoRoute: route
          }
        })

      return result
    },
    doneLoadingSigners ({ data }) {
      this.signerList = data.edoSigners
    },
    async sendFileInDiadoc ({ id, filename, inn, contractId, documentView, signer }) {
      const result = await axios.post(`${this.baseURL}/api/diadoc?id=${id}&inn=${inn}&filename=${filename}&contract_id=${contractId}&documentView=${documentView}&signer=${signer}`, {}, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then((res) => {
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Документ успешно отправлен'
          })
          return res
        })
      return result
    },
    async sendFileInSbis ({ id, filename, inn, contractId, documentView, signer, recipientName }) {
      const result = await axios.post(`${this.baseURL}/api/sbis/send-to-sign?id=${id}&inn=${inn}&filename=${filename}&contract_id=${contractId}&counteragent=${recipientName}&documentView=${documentView}&signer=${signer}`, {}, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('apollo-token')
        }
      })
        .then((res) => {
          this.$notify({
            group: 'lsg-notify',
            duration: 1000,
            text: 'Документ успешно отправлен'
          })
          return res
        })
      return result
    },
    async postFiles () {
      this.$store.dispatch({ type: 'showOverlay', show: true, text: 'Подождите, идет обработка...' })
      try {
        const tempDataArray = this.$store.state.data.edo.modal.edoDataArray

        const resultDataArray = tempDataArray.map(elem => ({
          id: elem.id,
          inn: this.selectedRecipient ? this.selectedRecipient.inn : elem.inn,
          filename: elem.filename,
          contractId: elem.contractId,
          documentView: elem.documentView,
          signer: this.selectedSigner.id
        }))

        if (resultDataArray.length) {
          const createRouteData = {
            creator_id: this.$store.state.me._id,
            responsible_id: this.selectedSigner.id,
            contract_id: resultDataArray[0].contractId
          }
          const createRouteResult = await this.createRoute(createRouteData)

          const createRouteWaypointsResult = this.waypoints.map(async (waypoint, index) => {
            const createRouteFilesResult = []

            const waypointTypeObj = {
              'Лизингополучатель': 'Стандартный маршрут',
              'Поручитель': 'Маршрут по поручителю',
              'Поставщик': 'Маршрут по поставщику'
            }

            const createRouteFilesData = this.getAvailableFiles(waypointTypeObj[waypoint.type]).map(elem => {
              return {
                document_id: elem.id,
                document_view: elem.documentView,
                filename: elem.filename,
                fileext: 'pdf',
                status: ''
              }
            })
            const createRouteFilesResultTemp = await this.createRouteFiles(createRouteFilesData)
            createRouteFilesResult.push(createRouteFilesResultTemp)

            const createRouteWaypointData = {
              route_id: createRouteResult.data.createEdoRoute.id,
              to_name: waypoint.selectedRecipient.name,
              to_inn: waypoint.selectedRecipient.inn,
              documents: createRouteFilesResultTemp.data.createEdoWaypointFiles.map(file => file.id),
              operator: waypoint.selectedOperator.field,
              status: '',
              pack: false
            }

            const result = await this.createRouteWaypoint(createRouteWaypointData)
            if (result) {
              const sendFilesResult = createRouteFilesData.map(async elem => {
                if (waypoint.type === 'Лизингополучатель' || waypoint.type === 'Поручитель') {
                  const sendFilesInput = {
                    id: elem.document_id,
                    filename: elem.filename,
                    inn: this.waypoints[0].selectedRecipient.inn,
                    contractId: resultDataArray[0].contractId,
                    documentView: elem.document_view,
                    signer: this.selectedSigner.id,
                    recipientName: this.waypoints[0].selectedRecipient.name
                  }
                  const sendFileResult = await this.sendFiles(sendFilesInput, waypoint.selectedOperator.field)
                  return sendFileResult
                }
              })
              const sendFiles = await Promise.all(sendFilesResult)
              return sendFiles
            }
          })

          const routeResult = await Promise.all([createRouteResult, ...createRouteWaypointsResult])
            .then((res) => {
              return true
            })
            .catch((err) => {
              this.$notify({
                group: 'lsg-notify',
                text: err
              })
              return null
            })

          if (routeResult) {
            this.$router.go()
          }

          return routeResult
        }
      } finally {
        this.$store.dispatch({ type: 'hideOverlay' })
      }
    },
    async sendFiles (input, operator) {
      let result = null
      if (operator === 'sbis') {
        result = await this.sendFileInSbis(input)
      } else if (operator === 'diadoc') {
        result = await this.sendFileInDiadoc(input)
      }

      return result
    },
    switchModalCancel () {
      this.$store.state.data.edo.modal.show = !this.$store.state.data.edo.modal.show

      this.dkpRoute = false
      this.dpRoute = false
      this.standartRoute = true
    }
  }
}
</script>

<style lang="stylus">
  .modal__edo {
    display flex
    flex-direction column
    // max-width 95%
    min-width 350px
    align-items flex-start !important

    &__title {
      width 100%
      display flex
      justify-content center
    }

    &__label {
      display flex
      flex-direction row
    }

    &__select-stub {
      display flex
      align-items center
      height 52px
      width 100%
      padding-left 15px
      border 1px solid $black
    }

    &__container {
      display flex
      flex-direction column
      flex-direction start

      > *:not(:last-child) {
        margin-bottom 20px
      }
    }

    &__waypoint {
      display flex
      // flex-direction column

      +below(1000px) {
        flex-direction column
      }

      > *:not(:first-child) {
        flex-direction row
      }

      // &:not(:last-child)::after {
      //   content ''
      //   bottom -50px
      //   left 0
      //   width inherit
      //   border-bottom 1px solid black
      // }

      &--move {
        background-color $orange
        opacity 0.7
      }
    }

    &__move {
      display flex
      justify-content center
      align-items center
      margin-right 20px
      height 52px
      width 100%
      cursor move

      > svg {
        width 20px
        height 20px
      }

      &--disabled {
        cursor no-drop
      }
    }

    &__item {
      min-width 250px

      &:not(:last-child) {
        margin-right 20px
      }

      &--buttons {
        width 100px
        min-width 100px
        height inherit
      }
    }
  }

  .edo-buttons {
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    width 100px
  }
</style>

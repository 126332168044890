<template>
  <div class="bn__form">
    <form @submit="submitForm" class="bn__inputs" v-if="contactForm">
      <div class="ct__row">
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="surname">Фамилия:</label>
            <input id="surname" type="text" v-model="contactForm.surname.value" @change="contact.surname = contactForm.surname.value">
            <span class="ct__message" v-if="!!contactForm.surname.message">{{ contactForm.surname.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="name">Имя:</label>
            <input id="name" type="text" v-model="contactForm.name.value" @change="contact.name = contactForm.name.value">
            <span class="ct__message" v-if="!!contactForm.name.message">{{ contactForm.name.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="patron">Отчество:</label>
            <input id="patron" type="text" v-model="contactForm.patron.value" @change="contact.patron = contactForm.patron.value">
            <span class="ct__message" v-if="!!contactForm.patron.message">{{ contactForm.patron.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col-3">
          <div class="ct__input" :class="{ required: contactForm.birthday.required && contactForm.birthday.error }">
            <label>Дата рождения:</label>
            <date-picker :input-attr="{required: contactForm.birthday.required}" valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="contactForm.birthday.value" @input="checkErrors" @change="contact.birthday = contactForm.birthday.value"></date-picker>
            <span class="ct__message" v-if="!!contactForm.birthday.message">{{ contactForm.birthday.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="post">Должность:</label>
            <input id="post" type="text" v-model="contactForm.post.value" @change="contact.post = contactForm.post.value">
            <span class="ct__message" v-if="!!contactForm.post.message">{{ contactForm.post.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="basis">Основание полномочий:</label>
            <input id="basis" type="text" v-model="contactForm.basis.value" @change="contact.basis = contactForm.basis.value">
            <span class="ct__message" v-if="!!contactForm.basis.message">{{ contactForm.basis.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col-2">
          <div class="ct__input">
            <label>Дата предоставления согласия:</label>
            <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="contactForm.agreement.value"  @change="contact.agreement = contactForm.agreement.value"></date-picker>
            <span class="ct__message" v-if="!!contactForm.agreement.message">{{ contactForm.agreement.message }}</span>
          </div>
        </div>
        <div class="ct__col-2">
          <div class="ct__input">
            <label for="birthplace">Место рождения:</label>
            <input id="birthplace" type="text" v-model="contactForm.birthplace.value" @change="contact.birthplace = contactForm.birthplace.value">
            <span class="ct__message" v-if="!!contactForm.birthplace.message">{{ contactForm.birthplace.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col-2">
          <div class="ct__input" :class="{ required: contactForm.passportId.required && contactForm.passportId.error }">
            <label for="passportId">Серия и номер паспорта (без пробелов):</label>
            <input id="passportId" type="number" v-model="contactForm.passportId.value" @change="contact.passportId = contactForm.passportId.value" :required="contactForm.passportId.required" @input="checkErrors">
            <span class="ct__message" v-if="!!contactForm.passportId.message">{{ contactForm.passportId.message }}</span>
          </div>
        </div>
        <div class="ct__col-2">
          <div class="ct__input" :class="{ required: contactForm.passportDate.required && contactForm.passportDate.error }">
            <label>Дата выдачи паспорта:</label>
            <date-picker :input-attr="{ required: contactForm.passportDate.required }" valueType="DD.MM.YYYY" @input="checkErrors" :formatter="momentFormat" v-model="contactForm.passportDate.value" @change="contact.passportDate = contactForm.passportDate.value"></date-picker>
            <span class="ct__message" v-if="!!contactForm.passportDate.message">{{ contactForm.passportDate.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="passportPlace">Место выдачи паспорта:</label>
            <input id="passportPlace" type="text" v-model="contactForm.passportPlace.value" @change="contact.passportPlace = contactForm.passportPlace.value">
            <span class="ct__message" v-if="!!contactForm.passportPlace.message">{{ contactForm.passportPlace.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="passportCode">Код подразделения:</label>
            <input id="passportCode" type="text" v-model="contactForm.passportCode.value" @change="contact.passportCode = contactForm.passportCode.value">
            <span class="ct__message" v-if="!!contactForm.passportCode.message">{{ contactForm.passportCode.message }}</span>
          </div>
        </div>
        <div class="ct__col-3">
          <div class="ct__input">
            <label for="inn">ИНН:</label>
            <input id="inn" type="number" v-model="contactForm.inn.value" @change="contact.inn = contactForm.inn.value">
            <span class="ct__message" v-if="!!contactForm.inn.message">{{ contactForm.inn.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col">
          <div class="ct__input">
            <label for="adresD">Адрес для информирования:</label>
            <input id="adresD" type="text" v-model="contactForm.adresD.value" @change="contact.adresD = contactForm.adresD.value">
            <span class="ct__message" v-if="!!contactForm.adresD.message">{{ contactForm.adresD.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__row">
        <div class="ct__col-2">
          <div class="ct__input">
            <label for="phone">Телефон:</label>
            <input id="phone" type="tel" v-model="contactForm.phone.value" @change="contact.phone = contactForm.phone.value">
            <span class="ct__message" v-if="!!contactForm.phone.message">{{ contactForm.phone.message }}</span>
          </div>
        </div>
        <div class="ct__col-2">
          <div class="ct__input">
            <label for="email">E-mail:</label>
            <input id="email" type="email" v-model="contactForm.email.value" @change="contact.email = contactForm.email.value">
            <span class="ct__message" v-if="!!contactForm.email.message">{{ contactForm.email.message }}</span>
          </div>
        </div>
      </div>
      <div class="ct__bottom" style="margin-top: 2rem; margin-bottom: 2rem">
        <div class="ct__buttons">
          <button class="btn" type="submit" @click="checkErrors">
            Далее
            <i>
              <ArrowRightIcon/>
            </i>
          </button>
          <div class="ct__prefill">
            <span class="ct__prefill__title">
              Автоматически заполнить поля
            </span>
            <Switcher @updateTable="toggleUpdate" :value="togglePrefillContact" />
          </div>
          <button class="btn hidden" type="button"></button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import ArrowRightIcon from '@/components/svg/arrow_right'
import PREFILL_PROJECT from '@/graphql/queries/PrefillProject.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import Switcher from '@/components/ui/SwitcherAlt'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CheckContact',
  components: {
    DatePicker,
    Switcher,
    ArrowRightIcon
  },
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      togglePrefillContact: false,
      prefillContact: null,
      contactForm: {
        adresD: {
          value: '',
          message: ''
        },
        agreement: {
          value: '',
          message: ''
        },
        basis: {
          value: '',
          message: ''
        },
        birthday: {
          value: '',
          message: '',
          required: true,
          error: false
        },
        birthplace: {
          value: '',
          message: ''
        },
        email: {
          value: '',
          message: ''
        },
        id: {
          value: '',
          message: ''
        },
        inn: {
          value: '',
          message: ''
        },
        name: {
          value: '',
          message: ''
        },
        passportCode: {
          value: '',
          message: ''
        },
        passportDate: {
          value: '',
          message: '',
          required: true,
          error: false
        },
        passportId: {
          value: '',
          message: '',
          required: true,
          error: false
        },
        passportPlace: {
          value: '',
          message: ''
        },
        patron: {
          value: '',
          message: ''
        },
        phone: {
          value: '',
          message: ''
        },
        post: {
          value: '',
          message: ''
        },
        surname: {
          value: '',
          message: ''
        }
      }
    }
  },
  created () {
    if (this.contact !== null || Object.keys(this.contact).length) {
      Object.keys(this.contact).forEach(field => {
        if (field === 'agreement' || field === 'passportDate' || field === 'birthday') {
          if (this.contact[field].length > 0) {
            this.contactForm[field].value = moment(this.contact[field]).format('DD.MM.YYYY')
          } else {
            if (this.contactForm[field]) {
              this.contactForm[field].value = null
            }
          }
        } else {
          if (this.contactForm[field]) {
            this.contactForm[field].value = this.contact[field]
          }
        }
      })
    }
    this.getPrefillContact()
  },
  beforeDestroy () {
    this.$emit('next', this.contactForm)
  },
  computed: {
    haveErrors () {
      return Object.keys(this.contactForm).filter(key => this.contactForm[key].error).length > 0
    }
  },
  methods: {
    updateContact () {
      if (this.togglePrefillContact) {
        Object.keys(this.prefillContact).forEach(field => {
          if (this.contactForm[field].value === '') this.contactForm[field].value = this.prefillContact[field]
        })
      } else {
        Object.keys(this.contact).forEach(field => {
          if (field !== 'contact' && field !== 'files') {
            if (this.contactForm[field]) {
              this.contactForm[field].value = this.contact[field]
            }
          }
        })
      }
    },
    toggleUpdate (e) {
      this.togglePrefillContact = e
      this.updateContact()
    },
    async getPrefillContact () {
      await this.$apollo.mutate({
        mutation: PREFILL_PROJECT,
        variables: {
          leadId: this.$route.params.id
        },
        update: (store, { data }) => {
          this.prefillContact = data.PrefillProject.contact_data
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    submitForm (e) {
      e.preventDefault()
      this.checkErrors()
      this.$emit('next', this.contactForm)
      this.nextPage()
    },
    checkErrors () {
      Object.keys(this.contactForm).forEach(key => {
        this.contactForm[key].error = !!(this.contactForm[key].required && (this.contactForm[key].value === '' || this.contactForm[key].value === null))
      })
    },
    nextPage () {
      if (!this.$store.state.data.trades.checkGuarantorModal) {
        this.$store.state.step = 3
      } else {
        this.$store.state.step = 4
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.ct__prefill {
  display flex
  flex-direction row
  align-items center

  &__title {
    font-weight 500
    font-size 15px
    line-height 18px
    color #7a7a7a
    margin-right 10px
    margin-top -8px
  }

  +below(980px) {
    &__title {
      display none
    }
  }
}
</style>

<template>
  <svg class="RingItem" viewBox="0 0 32 32">
    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 28c-6.627 0-12-5.373-12-12s5.373-12 12-12c6.627 0 12 5.373 12 12s-5.373 12-12 12z"></path>
  </svg>
</template>

<script>
export default {
  name: 'RingItem'
}
</script>

<style lang="stylus">
.RingItem {
  font-size 2rem
  display flex
  width 1rem
  height 1rem
  stroke darkgray
  fill darkgray
}
</style>

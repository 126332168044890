<template>
  <div class="field">
    <input class="field__input" type="text" v-model="textModel" @input="inputInn" placeholder="Введите ИНН" />
    <div class="field__list" v-if="providers.length && !!Number(textModel) && textModel.length > 7">
      <div class="field__list__item" @click="selectProvider(provider)" v-for="(provider, i) in providers" :key="i">{{ provider.name }}</div>
    </div>
    <div v-else-if="!providers.length && textModel.length > 7" class="field__list">
      <div v-if="loadCompany" class="field__list__item">Загрузка...</div>
      <div v-else class="field__list__item" @click="getCompany(textModel)">Добавить поставщика</div>
    </div>
    <div v-else-if="!providers.length && textModel.length < 8 && textModel.length > 0" class="field__list">
      <div class="field__list__item">Введите еще {{ 8 - textModel.length }} символов</div>
    </div>
  </div>
</template>

<script>

import GET_COMPANY from '@/graphql/queries/GetCompany.gql'

import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'ProviderInput',
  props: {
    default: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      inputText: null,
      timeout: null,
      providers: [],
      loadCompany: false
    }
  },
  created () {
    this.inputText = this.default
  },
  computed: {
    textModel: {
      get () {
        this.getProviders()
        return this.inputText
      },
      set (value) {
        this.inputText = value
        this.getProviders()
      }
    }
  },
  methods: {
    /** Хз как избавиться от бага, который проявляется тем, что
     * список появляется только после последующего изменения текста
     * Поэтому пока такой костыль, прости Господи
     */
    inputInn () {
      this.textModel = this.textModel + ' '
      this.textModel = this.textModel.trim()
    },
    // Получение поставщиков после ввода больше 7 цифр из ИНН
    async getProviders () {
      if (this.inputText && this.inputText.length > 7) {
        const isNumber = !!Number(this.inputText)
        await this.$store.dispatch('providersList', {
          context: this,
          data: {
            inn: isNumber ? this.inputText : '165'
          }
        })
      }
      this.sortProviders()
    },
    sortProviders (e) {
      this.providers = this.$store.state.providerList.slice(0, 15)
    },
    selectProvider (provider) {
      this.inputText = provider.name
      this.providers = []
      this.$emit('input', {
        name: provider.name,
        id: provider.id
      })
    },
    async getCompany (inn) {
      this.loadCompany = true
      await this.$apollo.mutate({
        mutation: GET_COMPANY,
        variables: {
          inn
        },
        update: (store, { data: { getCompany } }) => {
          if (Object.keys(getCompany).length) {
            this.$store.state.providerList.push(getCompany)
            this.sortProviders()
          } else {
            this.$notify({
              group: 'lsg-notify',
              text: 'Компания не существует'
            })
          }
        }
      })
        .then(() => {
          this.loadCompany = false
        })
        .catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
          this.loadCompany = false
        })
    }
  }
}
</script>

<style lang="stylus">
.field {
  position relative

  &__list {
    absolute left bottom
    transform translateY(100%)
    z-index 999
    background $white
    border-radius 0 0 5px 5px
    box-shadow: 0 10px 30px rgba($darker, 0.17);
    user-select none
    max-height 150px
    display block
    overflow-y auto

    &__item {
      padding 10px
      font-size 12px
      line-height 16px
      font-weight bold
      cursor pointer
      color $dark
      transition .3s

      &:hover {
        background $orange
        color $white
      }
    }
  }
}
</style>

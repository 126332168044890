<template>
  <transition name="modal__transition">
    <div class="ctmodal" v-if="($store.state.data.trades.checkTradeModal || $store.state.data.trades.checkGuarantorModal) && !minimized">
      <div class="ctmodal__back" @click="minimized = true"></div>
      <div class="ctmodal__body">
        <div v-if="$store.state.data.trades.checkGuarantorModal" class="ctmodal__fixed">
          <div class="ctmodal__fixed__item">
            <div @click="changeStep(1)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 1 }" class="bn__header__item"/>
            </div>
            <LineItem :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__lineitem"/>
            <div @click="changeStep(2)" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__item"/>
            </div>
            <LineItem :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__lineitem"/>
            <div @click="changeStep(4)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__item"/>
            </div>
          </div>
          <mq-layout :mq="[ 'md', 'lg', 'xlg', 'xl']">
            <div class="ctmodal__fixed__text">
              <div @click="changeStep(1)" :class="{ 'bn__header__item--active': $store.state.step >= 1 }" class="bn__header__textitem">
                <strong>Поручитель</strong>
              </div>
              <div style="position: relative; right: 1rem" @click="changeStep(2)" :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__textitem">
                <strong>Контактное лицо</strong>
              </div>
              <div @click="changeStep(4)" :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__textitem">
                <strong>Документы</strong>
              </div>
            </div>
          </mq-layout>
        </div>
        <div v-else class="ctmodal__fixed" :class="{ 'ctmodal__fixed--dl': ['approved', 'dl'].includes($store.state.data.trades.status) }">
          <div class="ctmodal__fixed__item">
            <div @click="changeStep(1)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 1 }" class="bn__header__item"/>
            </div>
            <LineItem :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__lineitem" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"/>
            <div @click="changeStep(2)" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__item"/>
            </div>
            <LineItem :class="{ 'bn__header__item--active': $store.state.step >= 3 }" class="bn__header__lineitem"/>
            <div @click="changeStep(3)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 3 }" class="bn__header__item"/>
            </div>
            <LineItem :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__lineitem" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"/>
            <div @click="changeStep(4)">
              <RingItem :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__item" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"/>
            </div>
          </div>
          <mq-layout :mq="[ 'md', 'lg', 'xlg', 'xl']">
            <div class="ctmodal__fixed__text">
              <div @click="changeStep(1)" :class="{ 'bn__header__item--active': $store.state.step >= 1 }" class="bn__header__textitem">
                <strong>Контрагент</strong>
              </div>
              <div style="position: relative; right: 1rem" @click="changeStep(2)" :class="{ 'bn__header__item--active': $store.state.step >= 2 }" class="bn__header__textitem" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)">
                <strong>Контактное лицо</strong>
              </div>
              <div @click="changeStep(3)" :class="{ 'bn__header__item--active': $store.state.step >= 3 }" class="bn__header__textitem">
                <strong>Калькулятор</strong>
              </div>
              <div @click="changeStep(4)" :class="{ 'bn__header__item--active': $store.state.step >= 4 }" class="bn__header__textitem" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)">
                <strong>Документы</strong>
              </div>
            </div>
          </mq-layout>
        </div>
        <div class="ctmodal__title">
          <strong class="bn__title" style="opacity: 1; cursor: default">{{ title }}</strong>
          <i class="fas fa-times" @click="close"></i>
          <i class="fas fa-chevron-down" @click="minimized = true"></i>
        </div>
        <div class="bn__body">
          <CheckCompany :company="company" @next="saveFirstStep" v-if="$store.state.step === 1 && loaded"/>
          <CheckContact :contact="contact" @next="saveSecondStep" v-if="$store.state.step === 2 && loaded"/>
          <CheckCalc :calc="calc" @next="saveThirdStep" v-if="$store.state.step === 3 && loaded"/>
          <CheckFiles :files="files" @next="saveFourthStep" v-if="$store.state.step === 4 && loaded"/>
        </div>
      </div>
    </div>
    <div class="ctmodal__body__minimized" v-if="($store.state.data.trades.checkTradeModal || $store.state.data.trades.checkGuarantorModal) && minimized">
      <div class="ctmodal__title">
        <a class="bn__title" @click="minimized = false" style="color: white">{{ title }}</a>
        <i class="fas fa-times" @click="close" style="color: white"></i>
        <i class="fas fa-chevron-up" @click="minimized = false" style="color: white"></i>
      </div>
    </div>
  </transition>
</template>

<script>
import RingItem from '../../../components/svg/RingItem'
import LineItem from '../../../components/svg/LineItem'
import CheckCompany from './components/CheckCompany'
import CheckContact from './components/CheckContact'
import CheckCalc from './components/CheckCalc'
import CheckFiles from './components/CheckFiles'
import moment from 'moment'
import axios from 'axios'
import UPDATE_PROJECT from '@/graphql/mutations/UpdateProject.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CheckTradeModal',
  components: {
    RingItem,
    LineItem,
    CheckCompany,
    CheckContact,
    CheckCalc,
    CheckFiles
  },
  data () {
    return {
      title: '',
      minimized: false,
      step: 1,
      loaded: false,
      company: null,
      contact: {},
      calc: null,
      files: []
    }
  },
  watch: {
    '$store.state.data.trades.loadCheckTradeData': 'fetchData',
    '$store.state.data.trades.checkGuarantorModal': 'fetchGuarantorData'
  },
  methods: {
    loadTitle () {
      this.title = this.$store.state.Lead.name ? this.$store.state.Lead.name : 'Сделка'
    },
    fetchGuarantorData () {
      this.loadTitle()
      this.$store.dispatch('guarantor', {
        context: this,
        data: {
          id: this.$store.state.data.trades.guarantorData.id,
          inn: this.$store.state.data.trades.guarantorData.inn
        }
      }).then(() => {
        this.files = []
        this.company = this.$store.state.checkProject.Company
        this.files.push({
          title: 'Поручитель (обязательно подгрузите паспорт и согласие)',
          files: this.company.files,
          filetypes: this.company.filetypes
        })
        if (typeof this.$store.state.checkProject.Company.contact === 'object') {
          this.contact = this.$store.state.checkProject.Company.contact
        }
        this.calc = this.$store.state.checkProject.Calc
        // this.$store.state.checkProject.Calc.forEach(calc => {
        //   this.files.push({
        //     title: 'Поставщик: ' + calc.provider.name,
        //     files: calc.provider.files,
        //     filetypes: calc.provider.filetypes
        //   })
        // })
        // this.files.push({
        //   title: 'Коммерческое предложение',
        //   files: this.$store.state.checkProject.Project.files,
        //   filetypes: this.$store.state.checkProject.Project.filetypes
        // })
        this.loaded = true
      })
    },
    fetchData () {
      this.loadTitle()
      this.$store.dispatch('agentsList', this)
      this.$store.dispatch('providersList', {
        context: this,
        data: {
          inn: '16586'
        }
      })
      this.$store.dispatch('checkProject', {
        context: this,
        data: {
          id: this.$route.params.id
        }
      }).then(() => {
        this.company = this.$store.state.checkProject.Company
        this.files = []
        this.files.push({
          title: 'Лизингополучатель (обязательно подгрузите паспорт и согласие)',
          files: this.company.files,
          filetypes: this.company.filetypes
        })
        if (typeof this.$store.state.checkProject.Company.contact === 'object') {
          this.contact = this.$store.state.checkProject.Company.contact
        }
        this.calc = this.$store.state.checkProject.Calc
        this.$store.state.checkProject.Calc.forEach(calc => {
          this.files.push({
            title: 'Поставщик: ' + calc.provider.name,
            files: calc.provider.files,
            filetypes: calc.provider.filetypes
          })
        })
        this.files.push({
          title: 'Коммерческое предложение',
          files: this.$store.state.checkProject.Project.files,
          filetypes: this.$store.state.checkProject.Project.filetypes
        })
        this.loaded = true
      })
    },
    close () {
      this.minimized = false
      this.$store.state.data.trades.checkTradeModal = false
      this.$store.state.data.trades.checkTradeClose = true
      this.$store.state.data.trades.checkGuarantorModal = false
      this.$forceUpdate()
    },
    async updateProject (data) {
      await this.$apollo.mutate({
        mutation: UPDATE_PROJECT,
        variables: data
      }).then(() => {
        this.$notify({
          group: 'lsg-notify',
          duration: 10000,
          text: 'Данные сохранены'
        })
      })
        .catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
    },
    formatData (data) {
      let newData = {}
      Object.keys(data).forEach(item => {
        newData[item] = data[item].value
      })
      return newData
    },
    saveFirstStep (data) {
      this.updateProject({
        objectType: 'company',
        objectId: data.id.value,
        input: this.formatData(data)
      })
    },
    saveSecondStep (data) {
      let d = this.formatData(data)
      d.birthday = moment(d.birthday, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')
      d.passportDate = moment(d.passportDate, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')
      d.agreement = moment(d.agreement, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')
      if (data.id && data.id.value && data.id.value.length) {
        this.updateProject({
          objectType: 'contact',
          objectId: data.id.value,
          input: d
        })
      } else {
        this.updateProject({
          objectType: 'company',
          objectId: this.company.id,
          input: {
            contact: d
          }
        })
      }
    },
    saveThirdStep (data) {
      data.forEach(item => {
        let i = this.formatData(item)
        i.prepaidDate = moment(i.prepaidDate, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss')
        i.agent = item.agent.value.id
        i.provider = item.provider.value.id
        this.updateProject({
          objectType: 'calc',
          objectId: item.id.value,
          input: i
        })
      })
    },
    saveFourthStep (data) {
      let formData = new FormData()
      let index = 0
      data.forEach(dat => {
        dat.files.forEach((item) => {
          formData.append(`file[${index}][file]`, item.file)
          Object.keys(item.info.code).forEach(key => {
            formData.append(`file[${index}][${key}]`, item.info.code[key])
          })
          formData.append(`file[${index}][user]`, this.$store.state.me.name)
          index += 1
        })
      })
      const BASE_URL = process.env.VUE_APP_HTTP
      const url = `${BASE_URL}/upload`
      axios.post(url, formData).then(res => {
        if (res.status === 200) {
          this.$notify({
            group: 'lsg-notify',
            duration: 10000,
            text: 'Данные сохранены'
          })
        }
      })
    },
    changeStep (num) {
      this.step = num
      this.$store.state.step = num
    }
  }
}
</script>
<style lang="stylus">
.ctmodal {
  overflow-y auto
  box-sizing border-box
  fixed left top
  z-index 999
  width 100%
  height 100%
  display flex
  align-items flex-start
  justify-content center

  &__back {
    width 100%
    height 100vh
    fixed left top
    background rgba($black, 0.3)
  }

  &__body {
    display flex
    flex-direction column
    align-items center
    padding 15px
    width 60%
    background $white
    border 1px solid $pinky
    box-sizing border-bo
    box-shadow none
    border-radius 10px
    z-index 1
    margin-top 9rem

    +below(768px) {
      width 95%
    }

    &__minimized {
      display flex
      flex-direction column
      align-items center
      padding 15px
      background #ff8000
      border 1px solid $pinky
      box-sizing border-box
      box-shadow none
      border-radius 10px
      position fixed
      bottom 8rem
      right 4rem
      width auto
      z-index 1000
    }
  }

  &__fixed {
    display flex
    flex-direction column
    padding 15px
    background $white
    border 1px solid $pinky
    box-sizing border-box
    box-shadow none
    border-radius 10px
    align-content center
    position fixed
    top 2.5rem
    align-self center
    width 59.5%
    z-index 1000

    &--dl {
      width: auto !important
    }

    +below(768px) {
      width 95%
    }

    &__item
    &__text {
      display flex
      flex-direction row
      width 100%
      justify-content space-between
    }
  }

  &__title {
    font-weight bold
    font-size 15px
    width 100%

    .fa-chevron-up
    .fa-chevron-down {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      margin-right 1rem
      float right

      &:hover {
        opacity 0.7
      }
    }

    .fa-times {
      transition opacity 0.25s
      font-size 1.25rem
      color darkgray
      cursor pointer
      float right

      &:hover {
        opacity 0.7
      }
    }
    .bn__title {
      transition opacity 0.25s
      color gray
      cursor pointer
      margin-right 2rem

      &:hover {
        opacity 0.7
      }
    }
  }
}

  .bn {
    display flex
    flex-direction column
    width 100%
  }

  .bn__col {
    width 45%
    margin-left 3rem
  }

  @media only screen and (max-width: 757px) {
    .bn__col {
      width 100%
    }
  }

  .bn__inputs {
    display flex
    flex-direction column
    width 100%
  }

  .bn__body {
    width 90%
    margin-top 2rem
  }

  .bn__body__header {
    text-align center
    font-family font_regular
  }

  .bn__header__textitem
  .bn__header__item {
    transition opacity 0.25s
    color darkgray
    cursor pointer

    &--active {
      color #ff7700
      fill #ff7700
      stroke #ff7700
    }
    &:hover {
       opacity 0.7
    }
  }
  @media only screen and (max-width: 800px) {
    .bn__header__textitem {
      font-size 0.8rem
    }
  }

.ct {
  display flex
  flex-direction column

  &__doc {
    display flex
    flex-direction column
    margin-bottom 30px

    &__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $black;
      margin: 30px 0 15px !important;
    }

    &__link {
      display inline-flex
      align-items center

      div {
        margin-right 10px

        svg {
          width 10px
          height 10px
        }
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: $orange;
      }
    }

    &__type {
      background: rgba($darkgray, 0.3);
      border-radius: 5px;
      font-weight: bold;
      font-size: 13px;
      line-height: 16px;
      color: $black;
      padding 6px 13px;
      min-width 140px
      +below(768px) {
        width 100%
        margin-top 10px
      }
    }

    .v-select {
      width auto
    }

    &__drop {
      width 100%
      display flex
      align-items center
      margin-top 20px

      .dz-preview {
        display none
      }

      .dz-clickable {
        width 100%
      }

      .lk__dz {
        width 100%
        height 64px !important
      }
    }

    &__list {
      display flex
      flex-direction column
      padding 0
      background: $white;
      border: 1px solid $pinky;
      box-sizing: border-box;
      border-radius: 10px;
      width 100%

      &__item {
        padding 15px 20px
        display flex
        align-items center
        justify-content space-between
        +below(768px) {
          flex-direction column
          align-items flex-start
          justify-content flex-start
        }

        &:hover {
          background rgba($darkgray, 0.3);
        }

        &:not(:last-child) {
          border-bottom 1px solid $pinky
        }

        input {
          visibility hidden
          opacity 0
          width 0 !important
          height 0 !important
        }

        &.ct__doc__nl {
          > span {
            color $red
          }

          > label
          > a {
            color $red
          }
        }
      }
    }

    &s {
      display flex
      flex-direction column
      max-width 640px
      width 100%
    }
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    padding 20px 32px 20px 40px
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom 10px
    position relative
    +below(960px) {
      flex-direction column
    }

    &:after {
      content ""
      width calc(100% - 80px)
      absolute top left 40px bottom
      height 2px
      background $darkgray
      align-self center
      margin auto
      +below(960px) {
        display none
      }
    }

    &__item {
      display inline-flex
      align-items center
      background $white
      z-index 1
      padding 0 20px
      +below(960px) {
        width 100%
        padding 0
        margin 10px 0
      }

      &:first-child {
        padding-left 0
      }

      &:last-child {
        padding-right 0
      }

      i {
        width 50px
        height 50px
        display flex
        align-items center
        justify-content center
        border 1px solid $orange
        border-radius 100%
        background $orange

        svg {
          width 24px
          height 24px

          path[stroke] {
            stroke $white
          }

          path[fill] {
            fill $white
          }
        }
      }

      span {
        font-weight: bold;
        font-size: 15px;
        line-height: 18px;
        text-align: center;
        color $orange
        margin-left 10px
      }

      &--active {
        & ~ .ct__header__item {
          i {
            background $white
            border-color $darkgray

            svg {
              path[stroke] {
                stroke $c7A
              }

              path[fill] {
                fill $c7A
              }
            }
          }

          span {
            color rgba($black, 0.3)
          }
        }
      }
    }
  }

  &__body {
    background: $white;
    border: 1px solid rgba($darkgray, 0.3);
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba($dark, 0.17);
    border-radius: 10px;
    display flex
    flex-direction column
    padding 30px 40px 40px

    &__header {
      font-family font_regular
      font-weight 500
      font-size: 24px;
      line-height: 29px;
      color: $black;
      margin-bottom 28px
    }
  }

  &__form {
    display flex
    align-items center
  }

  &__col {
    display flex
    flex-direction column
    width 100%
  }

  &__col-2 {
    display flex
    flex-direction column
    width calc(50% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  &__col-3 {
    display flex
    flex-direction column
    width calc(33.333333% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  &__col-4 {
    display flex
    flex-direction column
    width calc(25% - (10px * 2))
    +below(768px) {
      width 100%
    }
  }

  .checkbox-component {
    margin-left 10px
    margin-top 15px

    label {
      display flex
      align-items center

      span:not(.input-box) {
        margin-left 10px
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        display: flex;
        align-items: center;
        color: $black;
      }
    }
  }

  &__bottom {
    display flex
    flex-direction column
    width 100%

    .ct__message {
      margin 0 0 15px
      text-align left
    }
  }

  &__buttons {
    display flex
    justify-content space-between

    .btn {
      &:first-child {
        background $dark
        border-color $dark
      }

      &.hidden {
        cursor default
        opacity 0
      }

      i {
        width 16px
        height 16px
        margin-left 10px

        svg {
          width 100%
          height 100%

          path[stroke] {
            stroke $white !important
          }

          path[fill] {
            fill $white !important
          }
        }
      }
    }
  }

  &__message {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    margin 5px -10px
    color: $red;
    text-align right
    width 100%
  }

  &__row {
    display flex
    align-items flex-end
    justify-content space-between
    width calc(100% + 20px)
    margin 0 -10px
    flex-wrap: wrap;

    > * {
      margin-left 10px !important
      margin-right 10px !important
    }

    .btn {
      background: rgba($darkgray, 0.3);
      border: 1px solid $darkgray;
      color $c4E555A
      flex-shrink 0
      margin 10px 0

      svg {
        width 18px
        height 18px

        path {
          stroke $c4E555A
        }
      }
    }
  }

  &__input {
    display flex
    flex-direction column
    align-items flex-start
    width 100%
    margin 10px 0

    &.required {
      > input
      select
      .vs__dropdown-toggle
      .graph-search-input input
      .mx-datepicker input
      .field input
      textarea {
        border-color $red !important
      }
    }

    > label {
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $c7A;
      margin-bottom 10px
    }

    .graph-search-input
    .field
    .mx-datepicker
    .v-select {
      width 100%
    }

    > input
    select
    .vs__dropdown-toggle
    .graph-search-input input
    .mx-datepicker input
    .field input
    textarea {
      resize vertical
      width 100%
      background: $white !important;
      border: 1px solid $c7A !important;
      box-sizing: border-box !important;
      border-radius: 10px !important;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
    }

    &s {
      max-width 640px
      width 100%
      display flex
      flex-direction column
      align-items flex-start
      justify-content flex-start
      margin -10px 0
      +below(1120px) {
        max-width 100%
      }
    }
  }

  &__back {
    display flex
    align-items center
    justify-content center
    width: 100%;
    +below(1120px) {
      display none
    }

    img {
      width auto
    }
  }
}
</style>

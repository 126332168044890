<template>
  <div class="modal" v-if="showModal" style="margin-top: 20rem">
    <div class="modal__back" @click="switchModalCancel"></div>
    <div class="modal__body modal__sms">
      <span class="modal__sms__title"><h3>SMS-код</h3></span>
      <input type="number" v-model="smsCode" class="modal__sms__input">
      <span class="modal__sms__subtitle">
        Нажимая кнопку «Подтвердить», вы соглашаетесь с <a target="_blank" href="https://leasing-trade.ru/privacy-policy">Политикой конфиденциальности</a> и направлением запросов в бюро кредитных историй, а также даете согласие на <a target="_blank" href="https://leasing-trade.ru/documents/soglashenie.pdf">обработку персональных данных</a>, указанных вами в заявке.
      </span>
      <div class="modal__sms__btns">
        <button class="btn" @click="switchModalOk">Подтвердить</button>
        <span v-if="timeLeft !== 0">{{ `Повторная отправка возможна через ${this.timeLeft}` }}</span>
        <button v-else class="btn modal__sms__retry" @click="timer">Повторить отправку СМС</button>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import ADD_LEAD from '@/graphql/mutations/AddApplyLead.gql'
import ADD_NOTE from '@/graphql/mutations/AddApplyNote.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'SmsModal',
  watch: {
    '$store.state.smsModal' () {
      if (this.timeLeft === 0 && this.isSend) {
        this.timer()
        this.isSend = false
      }
    }
  },
  computed: {
    showModal () {
      return this.$store.state.smsModal
    }
  },
  data () {
    return {
      timeLeft: 0,
      checkTimer: this.$store.state.checkTimer,
      isSmsTrue: false,
      smsCode: null,
      isSend: true
    }
  },
  beforeDestroy () {
    this.$store.state.smsModal = false
  },
  methods: {
    async addLead (smsCode) {
      const date = new Date().toISOString()
      let input = {}
      if (this.$store.state.companyForm.id.value === '1') {
        input = {
          name: this.$store.state.companyForm.name.value,
          responsible_user_id: '6163eb7c2159179dc8203b70',
          status: 'first',
          user_data: [this.$store.state.companyForm, this.$store.state.contactForm]
        }
      } else {
        input = {
          name: this.$store.state.companyForm.name.value,
          responsible_user_id: '6163eb7c2159179dc8203b70',
          company_id: this.$store.state.companyForm.id.value,
          status: 'first',
          user_data: [this.$store.state.companyForm, this.$store.state.contactForm]
        }
      }
      await this.$apollo.mutate({
        mutation: ADD_LEAD,
        variables: {
          input,
          esignatureInput: {
            name: this.$store.state.contactForm.name.value,
            birthday: this.$store.state.contactForm.birthday.value,
            birthplace: this.$store.state.contactForm.birthplace.value,
            user_address: this.$store.state.contactForm.adresD.value,
            user_inn: this.$store.state.contactForm.inn.value,
            passport: this.$store.state.contactForm.passportId.value.toString(),
            passport_place: this.$store.state.contactForm.passportPlace.value,
            passport_code: this.$store.state.contactForm.passportCode.value,
            passport_date: this.$store.state.contactForm.passportDate.value,
            user_phone: this.$store.state.contactForm.phone.value,
            company_inn: this.$store.state.companyForm.inn.value,
            sms_key: smsCode,
            sms_phone: this.$store.state.contactForm.phone.value,
            sms_date: date
          }
        },
        update: async (_, { data }) => {
          if (data) {
            await this.addNotes(data, date, smsCode)
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async addNotes (lead, date, smsCode) {
      let esignature = `
        Электронная подпись:\n
        ФИО: ${this.$store.state.contactForm.name.value},
        Дата рождения: ${this.$store.state.contactForm.birthday.value},
        Место рождения: ${this.$store.state.contactForm.birthplace.value},
        Адрес: ${this.$store.state.contactForm.adresD.value},
        ИНН: ${this.$store.state.contactForm.inn.value},
        Серия и номер паспорта: ${this.$store.state.contactForm.passportId.value.toString()},
        Кем выдан: ${this.$store.state.contactForm.passportPlace.value},
        Код подразделения: ${this.$store.state.contactForm.passportCode.value},
        Номер телефона: ${this.$store.state.contactForm.phone.value},
        ИНН компании: ${this.$store.state.companyForm.inn.value},
        СМС код: ${smsCode},
        Номер телефона для подтверждения: ${this.$store.state.contactForm.phone.value},
        Дата получения подтверждения: ${date}
      `
      let textInput = 'Данные, которые ввёл клиент:\n'
      Object.keys(this.$store.state.companyForm).forEach(field => {
        if (field !== 'id') {
          textInput = textInput + this.$store.state.companyForm[field].title + ': ' + this.$store.state.companyForm[field].value + ';\n'
        }
      })
      Object.keys(this.$store.state.contactForm).forEach(field => {
        if (field !== 'id') {
          textInput = textInput + this.$store.state.contactForm[field].title + ': ' + this.$store.state.contactForm[field].value + ';\n'
        }
      })
      this.$apollo.mutate({
        mutation: ADD_NOTE,
        variables: {
          input: {
            element_type: 'Lead',
            element_id: lead.addApplyLead._id,
            note_type: 'Common',
            is_editable: false,
            text: textInput
          },
          esignatureInput: {
            element_type: 'Lead',
            element_id: lead.addApplyLead._id,
            note_type: 'Common',
            is_editable: false,
            text: esignature
          }
        },
        update: (_, { data }) => {
          if (data) {
            this.$store.state.step = 3
            this.$store.state.smsModal = false
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async switchModalOk () {
      if (this.$store.state.smsCode && Number(this.smsCode) === Number(this.$store.state.smsCode)) {
        this.addLead(this.smsCode)
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Неверный код'
        })
      }
      this.smsCode = ''
      // this.isSend = true
    },
    switchModalCancel () {
      this.$store.state.smsModal = false
    },
    timer () {
      this.timeLeft = 120
      this.sendSms(this.$store.state.clientPhone.value)
      let timer = setInterval(() => {
        if (this.timeLeft === 0) {
          clearInterval(timer)
        } else {
          this.timeLeft--
        }
      }, 1000)
    },
    sendSms (phone) {
      const smsCode = Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000
      this.$store.state.smsCode = smsCode
      const text = `Vash code: ${smsCode} dlya podpisania Soglasheniya ob ispolsovanii prostoi elektronoy podpisi https://leasing-trade.ru/documents/soglashenie.pdf`
      const BASE_URL = `${process.env.VUE_APP_HTTP}`
      const url = `${BASE_URL}/sms`
      axios.post(url, { text: text, phone: phone }).then(res => {
        if (res.status === 200) {
          return true
        } else return false
      })
    }
  }
}
</script>

<style lang="stylus">

.modal__sms {
  display flex
  flex-direction column
  justify-content space-around
  align-items flex-start !important
  width 500px
  min-height 289px

  +below(500px) {
    min-height 400px
    margin 0 10px
  }

  &__input {
    border-top none !important
    border-left none !important
    border-right none !important
    border-radius 0 !important
    max-width 309px
    box-shadow none !important
    font-size 24px !important
  }

  &__subtitle,
  &__title {
    color $c7A
  }

  &__title > h3 {
    margin 0 !important
  }

  &__btns {
    width 100%
    display flex
    flex row wrap
    align-items center
    justify-content space-between

    +below(500px) {
      flex-direction column
      margin-top 10px
      height 90px
      align-items flex-start
    }
  }
}

</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body">
      <div class="trade__modal" style="position: relative">
        <mq-layout :mq="[ 'xl' ]">
          <div class="trade__modal__header">
            <h1>{{ $store.state.data.trades.fullCalcDataModalProps.header }}</h1>
            <div class="trade__modal__header__buttons">
              <button class="btn btn--gray" style="margin-left: 5px" @click.prevent="switchModal">Закрыть</button>
            </div>
          </div>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md', 'lg', 'xlg' ]">
          <div class="trade__modal__header">
            <h3>{{ $store.state.data.trades.fullCalcDataModalProps.header }}</h3>
          </div>
          <div class="trade__modal__close-icon" @click="switchModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15">
              <path d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"></path>
            </svg>
          </div>
        </mq-layout>
        <div v-if="loadCalc" class="messages apollo">
          <div class="messages__status">Загрузка</div>
        </div>
        <div v-else-if="errorCalc" class="messages apollo">
          <div class="messages__status error">{{ errorCalc }}</div>
        </div>
        <div v-else class="trade__modal__body">
          <div class="ct__inputs">
            <div class="ct__col mb-30">
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="name">Наименование:</label>
                    <input type="text" id="name" v-model="modelName">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="propType">Тип имущества:</label>
                    <select v-model="createFields.propType" id="propType" :disabled="$store.state.data.trades.fullCalcDataModalProps.type === 'update'">
                      <option v-for="(item, mIndex) in propTypeSelect" :key="mIndex" :value="item.value">{{ item.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="price">Стоимость:</label>
                    <input type="text" id="price" v-model="modelPrice" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="currency">Валюта:</label>
                    <select v-model="createFields.currency" id="currency">
                      <option v-for="(item, mIndex) in currencyOptions" :key="mIndex">{{ item }}</option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="isCalculation">Расчётный:</label>
                    <checkbox id="isCalculation" v-model="createFields.isCalculation"></checkbox>
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="prepaidProc">Аванс(%)</label>
                    <input type="number" id="prepaidProc" v-model.number="prepaidProc">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="prepaid">Аванс:</label>
                    <input type="text" id="prepaid" v-model="modelPrepaid" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="duration">Срок лизинга (мес.)</label>
                    <input type="number" id="duration" v-model.number="createFields.duration">
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="rate">Ставка привлечения <div v-if="calcData" style="cursor: pointer" class="tag tag--green tag--small" @click="() => { this.createFields.rate = calcData.minRate }">от {{ prettifyPrice(calcData.minRate) }}%</div></label>
                    <input type="number" id="rate" v-model.number="createFields.rate">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="managerCom">Комиссия менеджера <div v-if="calcData" style="cursor: pointer" class="tag tag--green tag--small" @click="() => { this.createFields.managerCom = calcData.maxAgent }">до {{ prettifyPrice(calcData.maxAgent) }}%</div></label>
                    <input type="number" id="managerCom" v-model.number="createFields.managerCom">
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="agentCom">Комиссия агента</label>
                    <input type="number" id="agentCom" v-model.number="createFields.agentCom">
                  </div>
                </div>
              </div>
              <div v-if="calcData && calcData.error" class="messages apollo error" style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-around; max-width: 70rem">
                <div class="messages__status error">{{ calcData.error.message }}</div>
                <!-- <div class="messages__status" style="min-width: 150px; width: auto" v-if="calcData">Min. ставка от {{ prettifyPrice(calcData.minRate) }}%</div>
                <div class="messages__status" style="min-width: 150px; width: auto" v-if="calcData">Max. комиссия до {{ prettifyPrice(calcData.maxAgent) }}%</div> -->
              </div>
              <div class="ct__row">
                <div class="ct__col-2" v-if="createFields.agentCom">
                  <div class="ct__input">
                    <label>Сторонний исполнитель</label>
                    <AgentInput v-if="createFields.agent && typeof createFields.agent === 'string'" :default="createFields.agent" ref="agentInput" :placeholder="'Введите наименование агента'" @input="createFields.agent = $event"/>
                    <AgentInput v-else @input="createFields.agent = $event" :default="createFields.agent" ref="agentInput" :placeholder="'Введите наименование агента'"/>
                  </div>
                </div>
                <div class="ct__col-2">
                  <div class="ct__input">
                    <label>Поставщик</label>
                    <ProviderInput v-if="createFields.provider" :default="createFields.provider.name" @input="createFields.provider = $event"/>
                    <ProviderInput v-else @input="createFields.provider = $event"/>
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="graphType">Вид графика</label>
                    <select v-model="createFields.graphType" id="graphType">
                      <option v-for="(item, mIndex) in $store.state.data.trades.fullCalcDataModalProps.type === 'update' ? graphTypeUpdateSelect : graphTypeSelect" :key="mIndex" :value="item.value">{{ item.name }}</option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3" v-if="createFields.graphType === 'drawdown' || createFields.graphType === 'annuityStair'">
                  <div class="ct__input">
                    <label for="strength">Сила наклона</label>
                    <input type="number" min="0" max="300" step="1" id="strength" v-model.number="createFields.strength">
                  </div>
                </div>
                <div class="ct__col-3" v-if="createFields.graphType === 'annuityStair'">
                  <div class="ct__input">
                    <label for="stair">Месяцев в ступени</label>
                    <select id="stair" v-model="createFields.stair" class="dark-select">
                      <option
                        v-for="(item, mIndex) in graphStairSelect"
                        :key="mIndex"
                        :value="item.value"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label for="nds">НДС (%)</label>
                    <select v-model="createFields.nds">
                      <option value="НДС20" default>НДС 20</option>
                      <option value="НДС0">НДС 0</option>
                      <option value="БезНДС">Без НДС</option>
                      <option value="НДС20_120">НДС 20/120</option>
                    </select>
                  </div>
                </div>
                <div v-if="createFields.nds === 'НДС20_120'" class="ct__col-3">
                  <div class="ct__input">
                    <label for="nds">Входящая стоимость</label>
                    <input type="text" id="inputPrice" v-model="modelInputPrice" onkeyup="this.value = this.value.replace(/[^\d\s.,]/g,'');">
                  </div>
                </div>
              </div>
              <div :key="forceRecomputeCounter" class="ct__row" v-if="['individualNumber', 'individualAnnually'].includes(createFields.graphType)">
                <div class="ct__col">
                  <div class="ct__input">
                    <label>Индивидуальный график</label>
                    <div class="phones" style="max-width: 500px">
                      <div v-if="!individualGraph.length" class="group_buttons">
                        <button class="group_buttons__item add" @click="addIndividualGraphRow(individualGraph.length)">
                          <PlusIco/>
                        </button>
                      </div>
                      <div class="phones__item" v-for="(row, index) in individualGraph" :key="index">
                        <div v-if="createFields.graphType === 'individualNumber'" class="phones__phone" style="max-width: 80px; margin-right: 10px">
                          <input type="number" :value="row.number" @change="updateIndividualGraphRow(row.number, $event)">
                        </div>
                        <div v-else-if="createFields.graphType === 'individualAnnually'" class="phones__phone" style="min-width: 80px; max-width: 150px; margin-right: 10px">
                          <select v-model="row.number">
                            <option
                              v-for="(month, index) in months"
                              :key="index"
                              :value="index + 1"
                            >
                              {{ month }}
                            </option>
                          </select>
                        </div>
                        <div class="phones__phone" style="max-width: 200px">
                          <input type="number" v-model.number="row.value">
                        </div>
                        <div class="group_buttons">
                          <button class="group_button__item delete" @click="removeRow($event, index)">
                            <PlusIco/>
                          </button>
                          <button class="group_buttons__item add" @click="addIndividualGraphRow(individualGraph.length)">
                            <PlusIco/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ct__row" v-if="$route.name !== 'trade' && $route.name !== 'check_trade'">
                <div class="ct__col-3">
                  <div class="ct__input">
                    <label>Лизингополучатель:</label>
                    <CompanyInput
                      v-if="!createFields.company"
                      :key="createFields.company ? createFields.company.name : ''"
                      columnName="name"
                      :placeholder="createFields.company ? createFields.company.name : ''"
                      :currentValue="createFields.company ? createFields.company.name: ''"
                      filterName="q"
                      @change="onCompanyChange"
                      @changeInput="onChangeInput"
                    />
                    <input v-else type="text" :value="createFields.company.name" disabled />
                  </div>
                </div>
              </div>
              <div class="ct__row">
                <div class="ct__col">
                  <div class="ct__input">
                    <label>Комментарий:</label>
                    <textarea v-model="createFields.comment" style="min-height: 3rem"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!loadCalc && !errorCalc && this.$store.state.data.trades.fullCalcDataModalProps.type === 'update'" class="ct__col">
          <div class="subject-data" @click="handlerServices = !handlerServices">
            <h3>Доп. услуги</h3>
            <div>
              <svg v-if="!handlerServices" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerServices && createFields.id">
            <ApolloQuery
              v-if="input"
              :query="require('@/graphql/queries/CalcServices.gql')"
              :variables="{ calcId: createFields.id }"
              @result="doneLoadingCalcServices"
              @error="handleErrors"
            >
              <template slot-scope="{ result: { error, data }, isLoading }">
                <div v-if="error" class="messages apollo error">
                  <div class="messages__status error">{{ error.message }}</div>
                </div>
                <div v-if="isLoading" class="messages apollo">
                  <div class="messages__status">Загрузка...</div>
                </div>
                <template v-if="!error && data">
                  <div class="ct__col" v-if="services.calcServices.length">
                    <div class="ct__input">
                      <div class="phones" style="max-width: 800px">
                        <div class="phones__item" v-for="(row, index) in services.calcServices" :key="index">
                          <div class="phones__phone" style="min-width: 80px; max-width: 100%; margin-right: 10px">
                            <select v-model="row.name">
                              <option
                                v-for="(service, serviceIndex) in services.calcServicesList"
                                :key="serviceIndex"
                                :value="service.list_name"
                              >
                                {{ service.list_name }}
                              </option>
                            </select>
                          </div>
                          <div class="phones__phone" style="max-width: 100%; margin-right: 10px">
                            <input type="number" v-model.number="row.price" placeholder="Стоимость">
                          </div>
                          <div class="group_buttons">
                            <button class="group_button__item delete" @click="removeCalcServiceRow($event, index)">
                              <PlusIco/>
                            </button>
                            <button class="group_buttons__item add" @click="addCalcService">
                              <PlusIco/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else class="ct__col">
                    <div class="ct__input">
                      <div class="group_buttons">
                        <button class="group_buttons__item add" @click="addCalcService">
                          <PlusIco/>
                        </button>
                      </div>
                    </div>
                  </div>
                </template>
              </template>
            </ApolloQuery>
          </div>
        </div>
        <div v-if="!loadCalc && !errorCalc" class="ct__col">
          <div class="subject-data" @click="handlerSubject = !handlerSubject">
            <h3>Предмет</h3>
            <div>
              <svg v-if="!handlerSubject" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerSubject" class="trade__modal__body">
            <div class="ct__inputs">
              <div class="ct__col mb-30">
                <div class="ct__row">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label for="year">Год</label>
                      <input type="text" name="" id="year" v-model.number="createFields.subjectData.year">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label for="inStock">В наличии</label>
                      <checkbox id="inStock" :class-name="createFields.subjectData.inStock ? 'active' : ''" v-model="createFields.subjectData.inStock" :value="true"></checkbox>
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label for="wasInUse">Бывшее в употреблении</label>
                      <checkbox id="wasInUse" :class-name="createFields.subjectData.wasInUse ? 'active' : ''" v-model="createFields.subjectData.wasInUse" :value="true"></checkbox>
                    </div>
                  </div>
                </div>
                <div class="ct__row" v-if="createFields.subjectData.inStock && ['equipment'].includes(createFields.propType)">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Заводской номер:</label>
                      <input type="text" v-model="createFields.subjectData.vin">
                    </div>
                  </div>
                </div>
                <div class="ct__row" v-if="createFields.subjectData.inStock && ['realEstate', 'residentialEstate'].includes(createFields.propType)">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Кадастровый номер:</label>
                      <input type="text" v-model="createFields.subjectData.vin">
                    </div>
                  </div>
                </div>
                <div class="ct__row" v-if="createFields.subjectData.inStock && ['light', 'lightcommercial', 'commercial', 'special'].includes(createFields.propType)">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Шасси:</label>
                      <input type="text" v-model="createFields.subjectData.chassis">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Цвет:</label>
                      <input type="text" v-model="createFields.subjectData.colour">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Двигатель:</label>
                      <input type="text" v-model="createFields.subjectData.engine">
                    </div>
                  </div>
                </div>
                <div class="ct__row" v-if="createFields.subjectData.inStock && ['light', 'lightcommercial', 'commercial', 'special'].includes(createFields.propType)">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>VIN:</label>
                      <input type="text" v-model="createFields.subjectData.vin">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>ПТС/ПСМ:</label>
                      <input type="text" v-model="createFields.subjectData.pts">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Дата выдачи ПТС/ПСМ:</label>
                      <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="createFields.subjectData.pts_date"></date-picker>
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Кузов:</label>
                      <input type="text" v-model="createFields.subjectData.body">
                    </div>
                  </div>
                </div>
                <div class="ct__row">
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Дата оплаты аванса:</label>
                      <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="createFields.prepaidDate"></date-picker>
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label>Срок поставки:</label>
                      <input type="text" v-model="createFields.subjectData.srokPostavki">
                    </div>
                  </div>
                  <div class="ct__col-3">
                    <div class="ct__input">
                      <label></label>
                      <select v-model="createFields.subjectData.srokPostavkiDni">
                        <option v-for="(item, i) in srokPostavkiDniSelect" :key="i" :value="item" :selected="i === 0">{{ item }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="ct__row">
                  <div class="ct__col-2">
                    <div class="ct__input">
                      <label>Адрес отгрузки ДКП</label>
                      <input type="text" v-model="createFields.subjectData.adresDKP">
                    </div>
                  </div>
                  <div class="ct__col-2">
                    <div class="ct__input">
                      <label>Адрес эксплуатации ДЛ</label>
                      <input type="text" v-model="createFields.subjectData.adresDL">
                    </div>
                  </div>
                </div>
                <div class="ct__row">
                  <div class="ct__col">
                    <div class="ct__input">
                      <label>Технические характеристики:</label>
                      <textarea v-model="createFields.subjectData.tehChar"></textarea>
                    </div>
                  </div>
                  <div class="ct__col">
                    <div class="ct__input">
                      <label>Условия гарантии:</label>
                      <textarea v-model="createFields.subjectData.conditions" style="min-height: 3rem"></textarea>
                    </div>
                  </div>
                  <div class="ct__col">
                    <div class="ct__input">
                      <label>Комментарий:</label>
                      <textarea v-model="createFields.subjectData.commentSubject" style="min-height: 3rem"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <mq-layout :mq="[ 'xl' ]">
          <div v-if="!loadCalc && !errorCalc" style="margin-bottom: 20px" class="trade__modal__header__buttons-groups">
            <div v-if="input" class="trade__modal__header__buttons">
              <button class="btn" style="margin-left: 5px" v-if="$store.state.data.trades.fullCalcDataModalProps.type === 'update' && createFields.id" @click="downloadCalc(createFields.id, `LeasingTrade_${createFields.duration}_${Math.round(prepaidProc)}`)">PDF</button>
              <div class="btn" style="margin-left: 5px" v-tooltip="'Сначала сохраните калькулятор'" v-if="$store.state.data.trades.fullCalcDataModalProps.type === 'add'" @click="downloadNewCalc(`LeasingTrade_${createFields.duration}_${Math.round(prepaidProc)}`)">PDF</div>
              <div class="pdfLayout">
                <select v-model="pdfLayout">
                  <option disabled value=''>Выберите один из вариантов</option>
                  <option v-for="layout in pdfLayoutOptions" :key="layout.field" :value="layout.field">{{ layout.name }}</option>
                </select>
              </div>
            </div>
            <div class="trade__modal__header__buttons">
              <button class="btn" @click="saveCalc">Сохранить</button>
            </div>
          </div>
        </mq-layout>
        <mq-layout :mq="[ 'xs', 'sm', 'xsm', 'md', 'lg', 'xlg' ]">
          <div v-if="!loadCalc && !errorCalc" style="margin-bottom: 20px" class="trade__modal__header__buttons-groups">
            <div v-if="input" class="trade__modal__header__buttons trade__modal__header__buttons--mobile">
              <button class="btn" style="margin-left: 5px" v-if="$store.state.data.trades.fullCalcDataModalProps.type === 'update' && createFields.id" @click="downloadCalc(createFields.id, `LeasingTrade_${createFields.duration}_${Math.round(prepaidProc)}`)">PDF</button>
              <div class="btn" style="margin-left: 5px" v-tooltip="'Сначала сохраните калькулятор'" v-if="$store.state.data.trades.fullCalcDataModalProps.type === 'add'" @click="downloadNewCalc(`LeasingTrade_${createFields.duration}_${Math.round(prepaidProc)}`)">PDF</div>
              <div class="pdfLayout">
                <select v-model="pdfLayout">
                  <option disabled value=''>Выберите один из вариантов</option>
                  <option v-for="layout in pdfLayoutOptions" :key="layout.field" :value="layout.field">{{ layout.name }}</option>
                </select>
              </div>
            </div>
            <div class="trade__modal__header__buttons trade__modal__header__buttons--mobile">
              <button class="btn" v-if="!isChanged && input" @click="saveCalc">Сохранить</button>
            </div>
          </div>
        </mq-layout>
        <div v-if="!loadCalc && !errorCalc" class="ct__col">
          <!-- <div class="graph" @click="handlerGraph = !handlerGraph"> -->
          <div class="graph">
            <h3>График</h3>
            <div>
              <svg v-if="!handlerGraph" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerGraph">
            <ApolloQuery
              v-if="input"
              :query="
                ['individualAnnually', 'individualNumber']
                  .includes(this.createFields.graphType) ?
                  require('@/graphql/queries/IndividualTryFullCalc.gql') :
                  require('@/graphql/queries/TryFullCalc.gql')
              "
              :variables="
                ['individualAnnually', 'individualNumber']
                  .includes(this.createFields.graphType) &&
                  this.individualGraph.length ?
                  { input: individualGraphInput } :
                  { input }"
              @error="handleErrors"
              class="calc__info"
              v-bind:class="{ 'calc__info--center': this.$store.state.me.role === 'partner' }"
              @result="calcResult"
              :fetch-policy="'no-cache'"
            >
              <template slot-scope="{ result: { error, data }, isLoading }">
                <div v-if="error" class="messages apollo error">
                  <div class="messages__status error">{{ error.message }}</div>
                </div>
                <div v-if="isLoading" class="messages apollo">
                  <div class="messages__status">Загрузка...</div>
                </div>
                <template v-if="!error && data">
                  <div class="calc__info__header" v-if="data && calcData">
                    <div v-if="$store.state.me.role !== 'partner'" class="_row">
                      <div class="calc__info__item calc__info__item--orange _col-3">
                        <span class="calc__info__item__title">Сумма договора</span>
                        <span class="calc__info__item__subtitle">{{ prettifyPrice(calcData.summ) }} руб.</span>
                      </div>
                      <div class="calc__info__item _col-3">
                        <span class="calc__info__item__title">Минимальная ставка</span>
                        <span class="calc__info__item__subtitle">{{ prettifyPrice(calcData.minRate) }} %</span>
                      </div>
                      <div class="calc__info__item _col-3">
                        <span class="calc__info__item__title">Максимальная комиссия</span>
                        <span class="calc__info__item__subtitle">
                          <span>{{ prettifyPrice(calcData.maxAgent) }} %</span>
                        </span>
                      </div>
                    </div>
                    <div class="_row" v-if="calcData.graph">
                    <div v-bind:style="{ display: 'none' }">{{ readSum(prettifyPrice(calcData.summ)) }}</div>
                    <div :class="{ '_col-2': calcData.graph.length > 14, '_col-1': calcData.graph.length <= 14}">
                      <vue-good-table v-if="calcData.graph.length <= 14" :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(calcData.graph)" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.summ) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                      <vue-good-table v-else :columns="columns" :pagination-options="{enabled: false}" :rows="readPayment(calcData.graph.slice(0, calcData.graph.length / 2))" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.summ) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                    </div>
                    <div class="_col-2" v-if="calcData.graph.length > 14">
                      <vue-good-table :columns="columns" :pagination-options="{enabled: false}" :rows="calcData.graph.slice(calcData.graph.length / 2, calcData.graph.length)" compactMode>
                        <template slot="table-row" slot-scope="props">
                          <span v-if="props.column.field === 'number'">{{ props.row.lineNo }}</span>
                          <span v-if="props.column.field === 'plan'">{{ prettifyPrice(props.row.summ) }} руб.</span>
                        </template>
                        <div slot="emptystate">Данных нет</div>
                      </vue-good-table>
                    </div>
                    <!-- <div class="calc__info__download _col-1">
                      <a href="#" class="btn btn--black" style="" @click.prevent>Завести проект</a>
                    </div> -->
                  </div>
                </div>
                </template>
              </template>
            </ApolloQuery>
            <div v-else class="messages apollo error">
              <div class="messages__status error">Заполните поля: "Стоимость", "Аванс" и "Срок лимита"</div>
            </div>
          </div>
        </div>
        <div
          class="ct__col"
          v-if="
            $store.state.Lead &&
            [
              'first',
              'created',
              'meeting',
              'documents'
            ].includes($store.state.Lead.status) &&
            $store.state.data.trades.fullCalcDataModalProps.type === 'add' &&
            ($route.name === 'trade' || $route.name === 'check_trade')
          "
        >
          <div class="graph" @click="handlerCalc = !handlerCalc">
            <h3>Созданные калькуляторы</h3>
            <div>
              <svg v-if="!handlerCalc" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5V19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                <path d="M5 12H19" stroke="#7a7a7a" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
              </svg>
              <MinusIco v-else/>
            </div>
          </div>
          <div v-if="handlerCalc">
            <ApolloQuery
              :query="require('@/graphql/queries/AllCalculators.gql')"
              :variables="{
                free: true,
                q: q,
                filter: {
                  user: this.$store.state.me.user_id,
                  groupId: this.$store.state.me.group_id
                },
                page: 1,
                perPage: 10
              }"
              class="calc__info"
            >
              <template slot-scope="{ result: { error, data } }">
                <div v-if="error" class="messages apollo error">
                  <div class="messages__status error">{{ error.message }}</div>
                </div>
                <div v-if="!error && !data" class="messages apollo error">
                  <div class="messages__status">Загрузка</div>
                </div>
                <div class="tbl" v-else-if="data">
                  <vue-good-table
                    :columns="columnsCalc"
                    :rows="data.allCalculators || []"
                    :select-options="{ selectOnCheckboxOnly: true, selectionText: 'строка выбрана', clearSelectionText: 'очистить', enabled: true }"
                    @on-selected-rows-change="selectedRowsChange"
                    max-height="500px"
                    :line-numbers="true"
                    :sort-options="{
                      enabled: true,
                      initialSortBy: {field: 'created_date', type: 'desc'}
                    }"
                  >
                    <div slot="table-actions">
                      <input type="text" v-model="q">
                    </div>
                    <div slot="emptystate">Данных нет</div>
                    <template slot="table-row" slot-scope="props">
                      <div v-if="props.column.field === 'name'"><span>{{ props.row.name }}</span></div>
                      <div v-else-if="props.column.field === 'product'"><span>{{ props.row.product }}</span></div>
                      <div v-else-if="props.column.field === 'price'"><span>{{ props.row.price }}</span></div>
                      <div v-else-if="props.column.field === 'created_date'"><span>{{ props.row.created_date ? new Date(props.row.created_date).toLocaleDateString() : null }}</span></div>
                    </template>
                  </vue-good-table>
                </div>
              </template>
            </ApolloQuery>
            <div class="buttons">
              <div class="btn" @click="saveCalcs">Сохранить</div>
            </div>
          </div>
        </div>
        <!-- <div class="trade__modal__footer">
          <a href="#" class="btn" v-if="!isChanged" @click="saveCalc">Сохранить</a>
          <a href="#" class="btn" style="margin-left: 5px" v-if="$store.state.data.trades.fullCalcDataModalProps.data && $store.state.data.trades.fullCalcDataModalProps.data.id" @click="downloadCalc($store.state.data.trades.fullCalcDataModalProps.data.id)">PDF</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import ADD_CALC from '@/graphql/mutations/AddFullCalc.gql'
import UPDATE_SERVICES from '@/graphql/mutations/UpdateCalcServices.gql'
import UPDATE_CALC from '@/graphql/mutations/UpdateFullCalc.gql'
import GET_CALC from '@/graphql/queries/GetCalc.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import MinusIco from '@/components/svg/minus'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import AgentInput from '@/components/ui/GraphInputs/AgentInput'
import ProviderInput from '@/components/ui/GraphInputs/ProviderInput'
import axios from 'axios'
import PlusIco from '@/components/svg/plus'
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
import BIND_CALC from '@/graphql/mutations/BindCalc.gql'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'FullCalcModal',
  components: { MinusIco, DatePicker, AgentInput, ProviderInput, PlusIco, CompanyInput },
  data: () => {
    return {
      virtualCalculation: 0,
      handlerGraph: true,
      handlerSubject: false,
      handlerServices: false,
      calcActive: true,
      columns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Сумма платежа',
          field: 'plan'
        }
      ],
      services: {
        calcServices: [],
        calcServicesList: []
      },
      loadCalc: true,
      errorCalc: null,
      pdfLayout: 'offer',
      calcData: null,
      selectedRows: [],
      handlerCalc: false,
      q: '',
      columnsCalc: [
        { label: 'Заголовок', field: 'name', hidden: false },
        { label: 'Дата создания', field: 'created_date', hidden: false },
        { label: 'Имущество', field: 'product', hidden: false },
        { label: 'Стоимость', field: 'price', hidden: false }
      ],
      pdfLayoutOptions: [
        {
          name: 'Оферта к калькулятору',
          field: 'offer'
        },
        {
          name: 'Оферта к калькулятору без налога',
          field: 'taxFree'
        },
        {
          name: 'Оферта к калькулятору без удорожания',
          field: 'notAppreciation'
        },
        {
          name: 'Оферта к калькулятору без удорожания, без досрочного',
          field: 'notAppreciationEarly'
        }
      ],
      currencyOptions: [
        'руб.',
        'CHF',
        'USD',
        'EUR',
        'CNY',
        'GBP',
        'JPY',
        'SEK',
        'TRY'
      ],
      months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь'
      ],
      createFields: {
        id: null,
        name: '',
        price: 0,
        currency: 'руб.',
        inputPrice: 0,
        prepaidProc: 0,
        prepaid: 0,
        financeAmount: 0,
        duration: 0,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 0,
        graphType: 'annuity',
        propType: 'light',
        nds: 'НДС20',
        agent: null,
        balanceholder: '',
        provider: null,
        comment: '',
        prepaidDate: null,
        company: null,
        // prepaidDate: null,
        isCalculation: true,
        rate: 0,

        subjectData: {
          year: '',
          inStock: false,
          wasInUse: false,
          commentSubject: '',
          conditions: '',
          vin: '',

          adresDKP: '',
          adresDL: '',
          srokPostavki: '',
          srokPostavkiDni: '',

          tehChar: '',
          body: '',
          chassis: '',
          colour: '',
          engine: '',
          pts: '',
          pts_date: null
        }
      },
      individualGraph: [],
      forceRecomputeCounter: 0,
      srokPostavkiDniSelect: [
        'рабочие дни',
        'календарные дни'
      ],
      graphTypeSelect: [{
        value: 'annuity',
        name: 'Равномерный'
      }, {
        value: 'drawdown',
        name: 'Убывающий'
      }, {
        value: 'annuityStair',
        name: 'Равномерный | Лестница'
      }, {
        value: 'holidays',
        name: 'Лёгкий старт'
      }],
      graphTypeUpdateSelect: [{
        value: 'annuity',
        name: 'Равномерный'
      }, {
        value: 'drawdown',
        name: 'Убывающий'
      }, {
        value: 'annuityStair',
        name: 'Равномерный | Лестница'
      }, {
        value: 'holidays',
        name: 'Лёгкий старт'
      }, {
        value: 'individualNumber',
        name: 'Индивидуальный (по номеру платежа)'
      }, {
        value: 'individualAnnually',
        name: 'Индивидуальный (ежегодно)'
      }],
      propTypeSelect: [{
        value: 'light',
        name: 'Легковой автотранспорт'
      }, {
        value: 'lightcommercial',
        name: 'Легкий коммерческий автотранспорт'
      }, {
        value: 'commercial',
        name: 'Коммерческий автотранспорт'
      }, {
        value: 'special',
        name: 'Спец техника'
      }, {
        value: 'equipment',
        name: 'Оборудование'
      }, {
        value: 'realEstate',
        name: 'Недвижимость'
      }, {
        value: 'residentialEstate',
        name: 'Жилая недвижимость'
      }],
      graphStairSelect: [
        {
          value: 1,
          name: 3
        },
        {
          value: 2,
          name: 6
        },
        {
          value: 3,
          name: 9
        },
        {
          value: 4,
          name: 12
        }
      ],
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        }
      }
    }
  },
  computed: {
    modelName: {
      get () {
        // if (this.createFields.name && this.createFields.name.trim() === '') {
        // this.createFields.name = this.fetchPropName(this.createFields.propType)
        // }
        return this.createFields.name
      },
      set (value) {
        // if (value.trim()) {
        this.createFields.name = value
        // }
      }
    },
    modelPrice: {
      get () {
        return parseFloat(this.createFields.price).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.createFields.price = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.createFields.price) || typeof this.createFields.price !== 'number') this.createFields.price = 0
      }
    },
    modelInputPrice: {
      get () {
        return parseFloat(this.createFields.inputPrice).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.createFields.inputPrice = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.createFields.inputPrice) || typeof this.createFields.inputPrice !== 'number') this.createFields.price = 0
      }
    },
    modelPrepaid: {
      get () {
        return parseFloat(this.createFields.prepaid).toLocaleString('ru-RU')
      },
      set (value) {
        const newValue = value.toString().replace(',', '.')
        this.createFields.prepaid = +(newValue.replace(/[^\d.,]/ig, ''))
        if (isNaN(this.createFields.prepaid) || typeof this.createFields.prepaid !== 'number') this.createFields.prepaid = 0
      }
    },
    // TODO: Удалить
    // modelPrepaidProc: {
    //   get () {
    //     return parseFloat(this.createFields.prepaidProc).toLocaleString('ru-RU')
    //   },
    //   set (value) {
    //     const newValue = value.toString().replace(',', '.')
    //     this.createFields.prepaidProc = +(newValue.replace(/[^\d.,]/ig, ''))
    //     if (isNaN(this.createFields.prepaidProc) || typeof this.createFields.prepaidProc !== 'number') this.createFields.prepaidProc = 0
    //   }
    // },
    showModal () {
      return this.$store.state.data.trades.fullCalcModal
    },
    prepaidProc: {
      get () {
        return 100 * this.createFields.prepaid / this.createFields.price || 0
      },
      set (newValue) {
        this.createFields.prepaid = this.createFields.price * newValue / 100 || 0
      }
    },
    isChanged () {
      if (this.$store.state.data.trades.fullCalcDataModalProps.type === 'update') {
        return (this.createFields.price === '' || this.createFields.price === this.$store.state.data.trades.fullCalcDataModalProps.data.price) &&
          (this.createFields.prepaid === '' || this.createFields.prepaid === this.$store.state.data.trades.fullCalcDataModalProps.data.prepaid) &&
          (this.createFields.duration === '' || this.createFields.duration === this.$store.state.data.trades.fullCalcDataModalProps.data.duration) &&
          (this.createFields.rate === '' || this.createFields.rate === this.$store.state.data.trades.fullCalcDataModalProps.data.rate)
      } else {
        return [
          this.createFields.price,
          this.createFields.rate,
          this.createFields.prepaid,
          this.createFields.duration,
          this.createFields.managerCom,
          this.createFields.agentCom,
          this.createFields.strength,
          this.createFields.stair].every(val => val === 0 || val === '0')
      }
    },
    input () {
      const result = { ...this.createFields }
      if (
        result.duration === 0 ||
        result.price === 0 ||
        result.prepaid === 0
      ) return null
      delete result['subjectData']
      Object.keys(result).map(key => {
        if (typeof result[key] === 'number') result[key] = String(result[key])
      })
      return {
        ...result
      }
    },
    individualGraphInput () {
      const result = { ...this.createFields }
      if (
        result.duration === 0 ||
        result.price === 0 ||
        result.prepaid === 0
      ) return null
      delete result['subjectData']
      Object.keys(result).map(key => {
        if (typeof result[key] === 'number') result[key] = String(result[key])
      })
      return { ...result, individualGraph: this.individualGraph }
    },
    inputSave () {
      let result = { ...this.createFields }
      if (
        result.duration === 0 ||
        result.price === 0 ||
        result.prepaid === 0
      ) return null
      if (result.provider && result.provider.id) {
        result.provider = result.provider.id
      }
      if (result.agent && typeof result.agent === 'string') {
        const agent = this.$store.state.agentList.filter(agent => agent['Наименование'].toLowerCase().includes(this.createFields.agent.toLowerCase())).slice(0, 5)[0]
        result.agent = agent ? agent['Ссылка'] : null
      } else if (result.agent && result.agent.id) {
        result.agent = result.agent.id
      }
      Object.keys(result).map(key => {
        if (typeof result[key] === 'number') result[key] = String(result[key])
      })
      if (!this.handlerSubject) {
        delete result['subjectData']
      } else {
        Object.keys(result.subjectData).map(key => {
          if (typeof result.subjectData[key] === 'number') result.subjectData[key] = String(result.subjectData[key])
        })
      }

      if (['individualAnnually', 'individualNumber'].includes(this.createFields.graphType) && this.individualGraph.length) {
        result.individualGraph = this.individualGraph
      }

      if (this.$store.state.data.trades.fullCalcDataModalProps.leadId) {
        result.leadId = this.$store.state.data.trades.fullCalcDataModalProps.leadId
      }

      return {
        ...result
      }
    }
  },
  async beforeCreate () {
    this.$store.dispatch('agentsList', this)
    this.$store.dispatch('providersList', {
      context: this,
      data: {
        inn: '16586'
      }
    })
  },
  beforeDestroy () {
    this.virtualCalculation = 0
    this.calcData = null
  },
  methods: {
    selectedRowsChange ({ selectedRows }) {
      this.selectedRows = selectedRows.map(row => row.id)
    },
    onCompanyChange (item) {
      this.createFields.company = {
        inn: item.inn,
        name: item.name
      }
      this.$forceUpdate()
    },
    onChangeInput ({ columnName, value }) {
      this.createFields.company[columnName] = value
    },
    getLastDateOfMonth () {
      const result = moment(new Date(), 'DDMMYYYY')
        .endOf('month')
        .format('DD.MM.YYYY')
      return result
    },
    async updateCalcServices () {
      await this.$apollo.mutate({
        mutation: UPDATE_SERVICES,
        variables: {
          calcId: this.createFields.id,
          input: this.services.calcServices
        },
        update: (store, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Данные доп услуг обновлены'
            })
            this.services.calcServices = data.updateCalcServices
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    saveCalcs () {
      if (this.selectedRows.length) {
        this.selectedRows.forEach(id => {
          this.$apollo
            .mutate({
              mutation: BIND_CALC,
              variables: {
                leadId: this.$route.params.id,
                calcId: id
              },
              update: (_, { data }) => {
                if (data) {
                  this.$notify({
                    group: 'lsg-notify',
                    text: `Калькулятор добавлен`
                  })
                  this.switchModal()
                  this.$store.commit('incrementCalcRerenderKey')
                  this.$store.commit('incrementTradeRerenderKey')
                }
              }
            })
        })
        this.switchModal()
      }
    },
    doneLoadingCalcServices ({ data }) {
      if (!data) return

      this.services.calcServices = data.calcServices
      this.services.calcServicesList = data.calcServicesList
    },
    updateIndividualGraphRow (oldNumber, $event) {
      const newNumber = +$event.target.value
      if (oldNumber === newNumber) return
      if (isNaN(newNumber)) {
        this.thisShouldTriggerRecompute()
        return
      }
      // TODO: Проверка на уникальность
      let unique = true
      for (let i = 0; i < this.individualGraph.length; i++) {
        if (this.individualGraph[i].number === newNumber) {
          unique = false
          break
        }
      }
      if (!unique) {
        this.thisShouldTriggerRecompute()
        return
      }

      this.individualGraph.forEach((elem, index) => {
        if (elem.number === oldNumber) {
          this.individualGraph[index].number = newNumber
        }
      })
      this.thisShouldTriggerRecompute()
    },
    removeRow (e, index) {
      e.preventDefault()

      this.individualGraph.splice(index, 1)
    },
    removeCalcServiceRow (e, index) {
      e.preventDefault()

      this.services.calcServices.splice(index, 1)
    },
    thisShouldTriggerRecompute () {
      this.forceRecomputeCounter++
    },
    addCalcService () {
      this.services.calcServices.push({
        'name': 'Расходы на оформ. недвиж.',
        'price': ''
      })
    },
    addIndividualGraphRow (index) {
      if (!this.individualGraph.length) this.individualGraph.push({ number: index + 1, value: 0 })
      if (
        this.createFields.graphType === 'individualAnnually' &&
        this.individualGraph.length > 12
      ) {
        this.individualGraph.slice(0, 12)
      }

      if (this.individualGraph[this.individualGraph.length - 1].value === 0) return

      this.individualGraph.push({ number: index + 1, value: 0 })
    },
    calcResult ({ data }) {
      if (!this.virtualCalculation && this.$store.state.data.trades.fullCalcDataModalProps.type === 'update') {
        this.virtualCalculation++
        return
      }
      this.calcData = data.tryFullCalc || data.individualTryFullCalc
    },
    fetchPropName (propValue) {
      if (propValue) {
        const propName = this.propTypeSelect.find(elem => elem.value === propValue)
        return propName.name
      }
      return this.propTypeSelect[0].name
    },
    handleErrors (e) {
      // this.$notify({
      //   group: 'lsg-notify',
      //   text: e.message
      // })
    },
    readSum (value) {
      this.sum = value
      return value
    },
    readPayment (value) {
      if (!value || !value.length) return
      this.payment = value[1].summ
      return value
    },
    switchModal () {
      this.$store.state.data.trades.fullCalcModal = !this.$store.state.data.trades.fullCalcModal
      this.$store.state.data.trades.fullCalcDataModalProps.leadId = null
      this.$store.state.data.trades.fullCalcDataModalProps.type = null
      this.$store.state.data.trades.fullCalcDataModalProps.header = null
      this.$store.state.data.trades.fullCalcDataModalProps.data = null
      this.virtualCalculation = 0
      this.calcData = null
      this.createFields = {
        id: null,
        name: '',
        price: 0,
        inputPrice: 0,
        prepaidProc: 0,
        prepaid: 0,
        financeAmount: 0,
        duration: 0,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 0,
        graphType: 'annuity',
        propType: 'light',
        nds: 'НДС20',
        agent: null,
        balanceholder: '',
        provider: null,
        comment: '',
        prepaidDate: null,
        rate: 0,
        isCalculation: true,
        currency: 'руб.',

        subjectData: {
          year: '',
          inStock: false,
          wasInUse: false,
          commentSubject: '',
          conditions: '',
          vin: '',

          adresDKP: '',
          adresDL: '',
          srokPostavki: '',
          srokPostavkiDni: 'рабочие дни',

          tehChar: '',
          body: '',
          chassis: '',
          colour: '',
          engine: '',
          pts: '',
          pts_date: null
        }
      }
    },
    removeCalc (e) {
      e.preventDefault()

      this.$emit('removeAddCalc')
    },
    async saveCalc (e) {
      e.preventDefault()

      if (this.$store.state.data.trades.fullCalcDataModalProps.type !== 'add') this.inputSave['id'] = this.$store.state.data.trades.fullCalcDataModalProps.data.id

      if (!this.inputSave) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Данные калькулятора заполнены некорректно'
        })
      }

      if (this.$store.state.data.trades.fullCalcDataModalProps.type === 'update') await this.updateCalcServices()

      await this.$apollo.mutate({
        mutation: this.$store.state.data.trades.fullCalcDataModalProps.type === 'add' ? ADD_CALC : UPDATE_CALC,
        variables: {
          input: this.inputSave
        },
        update: async (store, { data }) => {
          if (data) {
            if (data && data.addFullCalc && data.addFullCalc[0]) {
              this.selectedRows = [data.addFullCalc[0].id]
              await this.saveCalcs()
            }
            this.$notify({
              group: 'lsg-notify',
              text: 'Данные сохранены'
            })
            this.switchModal()
            this.$store.commit('incrementCalcRerenderKey')
            this.$store.commit('incrementTradeRerenderKey')
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    prettifyPrice (price) {
      if (isNaN(price)) return 0
      return Number(price).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    downloadCalc (calcId, filename) {
      window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=${filename}&id=` + calcId + `&layout=${this.pdfLayout}`, '_blank')
    },
    async downloadNewCalc (filename) {
      const baseUrl = process.env.VUE_APP_HTTP
      await axios.post(`${baseUrl}/calc-pdf?layout=${this.pdfLayout}&managerId=${this.$store.state.me.user_id}&departmentId=${this.$store.state.me.group.id}`, { ...this.createFields }, { responseType: 'blob' })
        .then(response => {
          const blobFile = response.data
          const fileUrl = URL.createObjectURL(blobFile)
          let link = document.createElement('a')
          link.href = fileUrl
          link.setAttribute('download', filename)
          document.body.appendChild(link)
          link.click()
          link.remove()
        })
        .catch(error => {
          this.$notify({
            group: 'lsg-notify',
            text: error
          })
        })
    }
  },
  watch: {
    'createFields.subjectData.pts_date': function (newValue, oldValue) {
      const regExp = /^\d{4}-(?:0[1-9]|1[0-2])-(?:[0-2][1-9]|[1-3]0|3[01])T(?:[0-1][0-9]|2[0-3])(?::[0-6]\d)(?::[0-6]\d)?(?:\.\d{3})?(?:[+-][0-2]\d:[0-5]\d|Z)?$/
      if (regExp.test(newValue)) {
        this.createFields.subjectData.pts_date = moment(newValue).format('DD.MM.YYYY')
      } else {
        this.createFields.subjectData.pts_date = newValue
      }
    },
    'createFields.prepaidDate': function (newValue, oldValue) {
      const regExp = /^\d{4}-(?:0[1-9]|1[0-2])-(?:[0-2][1-9]|[1-3]0|3[01])T(?:[0-1][0-9]|2[0-3])(?::[0-6]\d)(?::[0-6]\d)?(?:\.\d{3})?(?:[+-][0-2]\d:[0-5]\d|Z)?$/
      if (regExp.test(newValue)) {
        this.createFields.prepaidDate = moment(newValue).format('DD.MM.YYYY')
      } else {
        this.createFields.prepaidDate = newValue
      }
    },
    'createFields.subjectData.inStock': function (newValue, oldValue) {
      if (newValue && !oldValue && !this.createFields.subjectData.srokPostavki) {
        this.createFields.subjectData.srokPostavki = 20
        this.createFields.subjectData.srokPostavkiDni = 'рабочие дни'
      }
    },
    individualGraph: function (newValue, oldValue) {
      if (this.individualGraph.length > 12) this.individualGraph.pop()
    },
    '$store.state.data.trades.fullCalcDataModalProps.type': async function (newValue, oldValue) {
      this.loadCalc = true
      this.errorCalc = null
      if (newValue === 'update') {
        await this.$apollo.mutate({
          mutation: GET_CALC,
          variables: {
            id: this.$store.state.data.trades.fullCalcDataModalProps.calcId
          },
          update: (store, { data }) => {
            if (data) {
              const calc = data.getCalc[0]
              const result = {}
              Object.keys(calc).forEach(elem => {
                // result[elem] = !isNaN(calc[elem]) && typeof calc[elem] !== 'boolean' ? +calc[elem] : calc[elem]
                result[elem] = calc[elem]

                if (['id', 'name', 'price', 'inputPrice', 'prepaidProc', 'prepaid', 'financeAmount', 'duration', 'managerCom', 'agentCom', 'strength', 'stair', 'graphType', 'propType', 'nds', 'agent', 'balanceholder', 'provider', 'comment', 'prepaidDate', 'rate', 'individualFields', 'exceptions', 'isCalculation', 'company', 'currency'].includes(elem)) {
                  if (elem === 'exceptions' && !this.individualGraph.length) {
                    this.individualGraph = result[elem] || []
                  } else this.createFields[elem] = result[elem]
                } else {
                  this.createFields.subjectData[elem] = result[elem]
                }
                // this.$store.state.data.trades.fullCalcDataModalProps.data = result
                // this.createFields = { ...this.createFields, ...result }
              })
              this.calcData = calc
              this.loadCalc = false
            }
          }
        }).then(() => {
          if (this.individualGraph.length && this.individualGraph[0].way) {
            if (this.individualGraph[0].way !== 'ПоНомеруПлатежа') this.createFields.graphType = 'individualAnnually'
          }
        }).catch(error => {
          this.loadCalc = false
          this.errorCalc = 'Ошибка загрузки данных. Обратитесь в тех. поддержку'
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      } else if (newValue === 'add') {
        if (!this.createFields.prepaidDate) this.createFields.prepaidDate = this.getLastDateOfMonth()
        if (this.createFields.name === '') {
          this.createFields.name = this.fetchPropName(this.createFields.propType)
        }
      }
      this.loadCalc = false
    },
    'createFields.propType' () {
      // if (this.createFields.propType === 'realEstate') this.createFields.nds = 'НДС20'
      // else this.createFields.nds = null

      if (
        this.$store.state.data.trades.fullCalcDataModalProps.type === 'add' &&
        (
          this.createFields.name === '' ||
          this.propTypeSelect.find(elem => elem.name === this.createFields.name)
        )
      ) {
        this.createFields.name = this.fetchPropName(this.createFields.propType)
      }
    },
    '$store.state.data.trades.fullCalcModal' () {
      if (this.$store.state.data.trades.fullCalcDataModalProps.type === 'update') {
        this.createFields.name = this.$store.state.data.trades.fullCalcDataModalProps.data.name
        this.createFields.propType = this.$store.state.data.trades.fullCalcDataModalProps.data.propType
        this.createFields.graphType = this.$store.state.data.trades.fullCalcDataModalProps.data.graphType
        this.createFields.price = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.price)
        this.createFields.prepaid = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.prepaid)
        this.createFields.strength = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.strength)
        this.createFields.stair = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.stair)
        this.createFields.duration = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.duration)
        this.createFields.rate = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.rate)
        this.createFields.managerCom = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.managerCom)
        this.createFields.agentCom = Number(this.$store.state.data.trades.fullCalcDataModalProps.data.agentCom)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.modal__sms {
  display flex
  flex-direction column
  justify-content space-around
  align-items flex-start !important
  width 500px
  min-height 289px

  +below(500px) {
    min-height 400px
    margin 0 10px
  }

  &__input {
    border-top none !important
    border-left none !important
    border-right none !important
    border-radius 0 !important
    max-width 309px
    box-shadow none !important
    font-size 24px !important
  }

  &__subtitle,
  &__title {
    color $c7A
  }

  &__title > h3 {
    margin 0 !important
  }

  &__btns {
    width 100%
    display flex
    flex row wrap
    align-items center
    justify-content space-between

    +below(500px) {
      flex-direction column
      margin-top 10px
      height 90px
      align-items flex-start
    }
  }
}

.tag--small {
  font-size 0.7em
}
.modal__body {
  width 60%

  +below(703px) {
    width 90%
  }
}
.ct__inputs {
  max-width none
}
.trade__modal__body {
  margin-bottom 0
}
.graph, .subject-data {
  display flex
  flex-direction row
  justify-content space-between
  cursor pointer
  align-items center
}
.pdfLayout {
  display inline-block
  max-width 200px
  min-width 60px
  width 50%
  margin-left 5px
}

.trade__modal__close-icon {
  display inline-block
  position absolute
  right 5px
  top 5px
  width 15px
  height 15px

  > svg path {
    color #979797
    stroke #979797
    fill #979797
    width 15px
    height 15px
  }
}

.trade__modal__header__buttons-groups {
  display flex
  justify-content space-between
  flex-direction row
  flex-wrap wrap
}

.trade__modal__header__buttons--mobile {
  display flex
  justify-content space-between
  width 40%
  margin-bottom 10px
}

.btn--disable {
  background $c979797 !important
  color $white !important
  border-color $c979797 !important

  &:hover {
    background $c979797 !important
    cursor auto
  }

  &:active {
    transform none !important
  }
}

.phones
  width 100%
.phones__item
  display flex
  align-items center
  margin-top 15px

  &:first-child
    margin-top 0

.phones__phone
  flex 1

  > input
    background: $white !important;
    border: 1px solid $c4E555A !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 18px !important;
    display: flex;
    align-items: center;
    color: $black !important;
    resize vertica

.phones
  .small-buttons
    margin-left 10px

    .btn
      border: 1px solid $c7A;
      border-radius 10px

      path[stroke] {
        stroke $c7A
      }

      path[fill] {
        fill $c7A
      }

      &:not(:last-child)
        margin-right 10px

.calc__info__item
  display flex
  flex-direction column
  align-items center
  justify-content center
  padding 12px 24px

  &--orange {
    background $orange

    span {
      font-weight bold
      font-style normal
    }
  }

.calc__info__item__title {
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  text-align center
  color: $black;
}

</style>

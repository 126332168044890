<template>
  <div class="modal" v-if="$store.state.faqNotification">
    <div class="modal__back" @click="switchFaqNotification"></div>
    <div class="modal__body">
      <div class="modal__attention">
        <i class="modal__attention__icon">
          <AttentionIco/>
        </i>
        <span class="modal__attention__title">Ваше обращение принято на рассмотрение</span>
        <span class="modal__attention__subtitle modal__attention__subtitle--faq">Время ответа 2 дня</span>
        <div class="modal__attention__btns">
          <button class="modal__attention__btn modal__attention__btn--green" @click="switchFaqNotification">Ок</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIco from '@/components/svg/attention'

export default {
  name: 'FaqNotification',
  components: { AttentionIco },
  methods: {
    switchFaqNotification () {
      this.$store.state.faqNotification = !this.$store.state.faqNotification
      this.$router.go()
    }
  }
}
</script>

<style lang="stylus">

.modal__attention__subtitle--faq {
  color $black !important
}

</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="saveHolding" autocomplete="off">
        <div class="trade__modal__header">
          <h1>Холдинг</h1>
          <button class="btn btn--gray" @click.prevent="switchModal">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Наименование:</label>
                <HoldingInput
                  v-if="!$store.state.data.companies.holdingDataModalProps.elementId"
                  :default="holding.name"
                  placeholder="Введите наименование"
                  @input="onHoldingChange"
                  @newInput="onHoldingCreate"
                  requared
                />
                <input
                  v-else
                  type="text"
                  v-model="holding.name"
                  placeholder="Введите наименование"
                  required
                >
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Описание:</label>
                <textarea
                  v-model="holding.description"
                  placeholder="Введите описание"
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div :key="rerenderKey" class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label v-if="$store.state.data.companies.holdingDataModalProps.elementId">Компании:</label>
                <label v-else>Еще компании:</label>
                <div class="phones" v-if="holding.companies.length">
                  <div :key="index" class="phones__item" v-for="(company, index) in holding.companies">
                    <div class="phones__phone"
                      :class="{
                        'holding__item--valid': !company.disabled && isValidCompany(company),
                        'holding__item--not-valid': !company.disabled && !isValidCompany(company),
                      }">
                      <input v-if="company.disabled" type="text" disabled :value="company.name">
                      <CompanyInput
                        :key="company.name"
                        v-else
                        columnName="name"
                        :currentValue="company.name"
                        filterName="q"
                        :placeholder="company.name || 'Наименование компании'"
                        @change="onCompanyChange($event, index)"
                      />
                    </div>
                    <div class="small-buttons">
                      <button type="button" @click="removeCompany($event, index)" class="btn btn--small">
                        <MinusIco/>
                      </button>
                      <button type="button" @click="addCompany" class="btn btn--small">
                        <PlusIco/>
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else class="small-buttons">
                  <button type="button" @click="addCompany" class="btn btn--small">
                    <PlusIco/>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button type="submit" class="btn">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
import HoldingInput from '@/components/ui/GraphInputs/HoldingInput'
import MinusIco from '@/components/svg/minus'
import PlusIco from '@/components/svg/plus'
import CREATE_HOLDING from '@/graphql/mutations/CreateHolding.gql'
import UPDATE_HOLDING from '@/graphql/mutations/UpdateHolding.gql'
import HOLDING from '@/graphql/queries/Holding.gql'
import BIND_HOLDING from '@/graphql/mutations/BindHolding.gql'

export default {
  name: 'HoldingDataModal',
  components: { PlusIco, MinusIco, CompanyInput, HoldingInput },
  data: () => {
    return {
      holding: {
        name: '',
        description: '',
        companies: []
      },
      bindHolding: false,
      rerenderKey: 0
    }
  },
  computed: {
    showModal () {
      if (this.$store.state.data.companies.holdingDataModal && this.$store.state.data.companies.holdingDataModalProps.elementId) {
        this.loadData()
      }
      return this.$store.state.data.companies.holdingDataModal
    }
  },
  methods: {
    onHoldingChange (e) {
      this.holding.name = e.name
      this.$store.state.data.companies.holdingDataModalProps.elementId = e.id
      this.bindHolding = true
    },
    onHoldingCreate (e) {
      this.holding.name = e
    },
    getCompaniesId () {
      if (!this.holding.companies.length) return []

      if (this.holding.companies.find(company => !this.isValidCompany(company))) throw new Error('Неверно заполнены компании')

      return this.holding.companies.map(company => company.id)
    },
    bindingHolding (holdingId, companyId) {
      this.$apollo
        .mutate({
          mutation: BIND_HOLDING,
          variables: {
            input: {
              company_id: companyId,
              holding_id: holdingId
            }
          },
          update: (_, { data }) => {
            if (data && data.bindingHolding) {
              this.switchModal()
              this.$notify({
                group: 'lsg-notify',
                text: `Компания успешно привязана к холдингу`
              })
            }
          }
        })
    },
    createHolding (input) {
      this.$apollo
        .mutate({
          mutation: CREATE_HOLDING,
          variables: {
            input: input
          },
          update: (_, { data }) => {
            if (data && data.createHolding) {
              this.bindingHolding(data.createHolding.id, this.$route.params.id)
              this.switchModal()
              this.$notify({
                group: 'lsg-notify',
                text: `Холдинг ${data.createHolding.name} успешно создан`
              })
            }
          }
        })
    },
    updateHolding (input) {
      if (this.bindHolding) {
        input.companies_id.push(this.$route.params.id)
      }
      this.$apollo
        .mutate({
          mutation: UPDATE_HOLDING,
          variables: {
            id: this.$store.state.data.companies.holdingDataModalProps.elementId,
            input: input
          },
          update: (_, { data }) => {
            if (data && data.updateHolding) {
              this.switchModal()
              this.$notify({
                group: 'lsg-notify',
                text: `Холдинг ${data.updateHolding.name} успешно изменён`
              })
            }
          }
        })
    },
    saveHolding (e) {
      e.preventDefault()

      const input = {}

      input.name = this.holding.name
      input.description = this.holding.description

      try {
        input.companies_id = this.getCompaniesId()
      } catch (err) {
        this.$notify({
          group: 'lsg-notify',
          text: err.message
        })
        return
      }

      if (this.$store.state.data.companies.holdingDataModalProps.elementId) {
        this.updateHolding(input)
      } else {
        this.createHolding(input)
      }
    },
    isValidCompany (company) {
      if (
        company &&
        company.name &&
        company.name.length &&
        company.id &&
        company.id.length
      ) return true
      else return false
    },
    loadData () {
      this.$apollo
        .mutate({
          mutation: HOLDING,
          variables: {
            id: this.$store.state.data.companies.holdingDataModalProps.elementId
          },
          update: (_, { data }) => {
            if (data && data.Holding) {
              this.holding = {
                name: data.Holding.name,
                description: data.Holding.description,
                companies: data.Holding.companies.map(elem => {
                  return {
                    ...elem,
                    disabled: true
                  }
                })
              }
            }
          }
        })
    },
    switchModal () {
      this.$store.commit('clearHoldingData')
      this.$store.commit('incrementCompanyRerenderKey')

      this.holding = {
        name: '',
        description: '',
        companies: []
      }

      this.bindHolding = false
    },
    addCompany () {
      this.holding.companies.push({ _id: '' })
    },
    removeCompany (e, index) {
      e.preventDefault()

      this.holding.companies.splice(index, 1)
    },
    onCompanyChange (e, index) {
      this.holding.companies[index] = {
        id: e._id,
        name: e.name
      }
      this.$nextTick(() => {
        this.rerenderKey++
      })
    },
    onChangeInput (e, index) {
      this.holding.companies[index][e.columnName] = e.value
    }
  }
}
</script>

<style lang="stylus">
  .holding__item--valid
    border-radius 10px
    box-shadow 0px 0px 8px $green

  .holding__item--not-valid
    border-radius 10px
    box-shadow 0px 0px 8px $red
</style>

<template>
  <div class="modal modal--calc" v-if="showModal">
    <div class="modal__back" @click="switchModalClose"></div>
    <div class="modal__body modal__body--calc">
      <div class="calc" v-bind:class="{ 'calc--modal': this.$store.state.me.role === 'partner' }">
        <div v-if="this.$store.state.me.role === 'partner'" class="calc__form calc__form--client" v-bind:style="{marginTop: marginTopCalc + 'px'}">
          <div class="_row calc-types--client">
            <label class="_col-4 calc__form__type" :class="{active: activeItem.light}">
              <input type="radio" value="light" name="propType" @click="setType">
              <i>
                <CarIcon/>
              </i>
              <span>Легковой транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.commercial}">
              <input type="radio" value="commercial" name="propType" @click="setType">
              <i>
                <TruckIcon/>
              </i>
              <span>Коммерческий транспорт</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.special}">
              <input type="radio" value="special" name="propType" @click="setType">
              <i>
                <TractorIcon/>
              </i>
              <span>Спецтехника</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.equipment}">
              <input type="radio" value="equipment" name="propType" @click="setType">
              <i>
                <FactoryIcon/>
              </i>
              <span>Оборудование</span>
            </label>
            <label class="_col-4 calc__form__type" :class="{active: activeItem.realEstate}">
              <input type="radio" value="realEstate" name="propType" @click="setType">
              <i>
                <RealEstateIcon/>
              </i>
              <span>Недвижимость</span>
            </label>
          </div>
          <div class="_row">
            <div class="calc__form__item _col-2">
              <label>Стоимость предмета лизинга</label>
              <input
                min="0"
                v-model="modelPrice"
                :type="indicators.price ? 'number' : 'text'"
                @focus="indicators.price = true"
                @blur="indicators.price = false"
              />
            </div>
            <div class="calc__form__item _col-2">
              <label>Первоначальный взнос, (до {{ prettifyPrice(Number(modelPrice.replace(/\s/g, '')) * 0.499) }} руб.)</label>
              <input
                min="0"
                v-model="modelPrepaid"
                :type="indicators.prepaid ? 'number' : 'text'"
                @focus="indicators.prepaid = true"
                @blur="indicators.prepaid = false"
              />
            </div>
            <div class="calc__form__item _col-1">
              <label>Срок лизинга (от 12 до 60 мес)</label>
              <input type="number" v-model.number="createFields.duration" min="12" max="60">
            </div>
          </div>
          <div class="calc__form__note _row">
            <span>
              Расчет является примерным и не включает страховые взносы. Представленные сведения носят исключительно информационный характер и ни при каких условиях не
              являются публичной офертой. Окончательные условия каждого договора определяются в индивидуальном порядке. Для получения конечной цены вы можете заполнить форму заявки.
            </span>
          </div>
        </div>
        <div v-if="this.$store.state.me.role === 'partner'" class="calc__results">
          <div class="calc__results__white">
            <span class="calc__results__title">Результаты расчёта:</span>
            <span class="calc__results__price">{{ prettifyPrice(this.payment) }} ₽</span>
            <span class="calc__results__type">Ежемесячный платеж</span>
          </div>
          <div class="calc__results__dark">
            <div class="calc__results__item">
              <div class="calc__dark__price">{{ prettifyPrice(this.sum) }} ₽</div>
              <div class="calc__dark__type">Сумма договора</div>
            </div>
            <div class="calc__results__item">
              <div class="calc__dark__price">{{ prettifyPrice( this.economy ) }} ₽</div>
              <div class="calc__dark__type">Экономия на налогах</div>
            </div>
          </div>
          <div @click="() => {this.$store.state.calcModal = false}" class="calc__results__btn">
            <router-link :to="{ name: 'apply' }" class="btn">Отправить заявку</router-link>
          </div>
          <span class="calc__results__note">
            Сезонным бизнесам — сезонные графики. Подробности у менеджера.
          </span>
        </div>
        <ApolloQuery
          v-if="input"
          :query="require('@/graphql/queries/TryCalc.gql')"
          :variables="{ input }"
          @error="handleErrors"
          class="calc__info"
          @result="doneLoadingCalc"
        >
          <template slot-scope="{ result: {}}"></template>
        </ApolloQuery>
      </div>
    </div>
  </div>
</template>

<script>
import UPDATE_CALC from '@/graphql/mutations/UpdateCalc.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import CarIcon from '../../../svg/CarIcon'
import TruckIcon from '../../../svg/TruckIcon'
import TractorIcon from '../../../svg/TractorIcon'
import FactoryIcon from '../../../svg/FactoryIcon'
import RealEstateIcon from '../../../svg/RealEstate'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CalcModal',
  components: { FactoryIcon, TractorIcon, TruckIcon, CarIcon, RealEstateIcon },
  data () {
    return {
      marginTopCalc: 15,
      indicators: {
        price: false,
        prepaid: false
      },
      isPartner: true,
      sum: 0,
      payment: 0,
      economy: 0,
      activeItem: {
        light: true,
        lightcommercial: false,
        commercial: false,
        special: false,
        equipment: false,
        realEstate: false
      },
      createFields: {
        name: 'Тестовое имущество',
        price: 5000000,
        rate: 12,
        prepaid: 520000,
        duration: 20,
        managerCom: 0,
        agentCom: 0,
        strength: 0,
        stair: 1,
        graphType: 'annuity',
        propType: 'light'
      },
      columns: [
        {
          label: '№',
          field: 'number'
        },
        {
          label: 'Сумма платежа',
          field: 'plan'
        }
      ],
      graphTypeSelect: [
        {
          value: 'annuity',
          name: 'Равномерный'
        }, {
          value: 'drawdown',
          name: 'Убывающий'
        }, {
          value: 'annuityStair',
          name: 'Равномерный | Лестница'
        }
      ],
      graphStairSelect: [
        {
          value: 1,
          name: 3
        },
        {
          value: 2,
          name: 6
        },
        {
          value: 3,
          name: 9
        },
        {
          value: 4,
          name: 12
        }
      ],
      propTypeSelect: [
        {
          value: 'light',
          name: 'Легковой автотранспорт'
        }, {
          value: 'commercial',
          name: 'Коммерческий автотранспорт'
        }, {
          value: 'special',
          name: 'Спец техника'
        }, {
          value: 'equipment',
          name: 'Оборудование'
        }, {
          value: 'realEstate',
          name: 'Недвижимость'
        }],
      additional: false,
      timeLeft: 0,
      checkTimer: this.$store.state.checkTimer,
      isSmsTrue: false,
      smsCode: ''
    }
  },
  beforeDestroy () {
    this.$store.state.calcModal = false
  },
  methods: {
    doneLoadingCalc ({ data }) {
      this.economy = data.tryCalc.economy
      this.sum = data.tryCalc.summ
      this.payment = data.tryCalc.graph[1].plan
    },
    handleScroll (event) {
      if (window.innerWidth > 1130) {
        window.pageYOffset < 16
          ? this.marginTopCalc = 15
          : this.marginTopCalc = window.pageYOffset
      } else {
        this.marginTopCalc = 15
      }
    },
    readSum (value) {
      this.sum = value
      return value
    },
    readPayment (value) {
      this.payment = value[1].plan
      return value
    },
    setType (event) {
      this.createFields.propType = event.target.value
      if (event.target.value === 'light') {
        this.activeItem.light = !(this.activeItem.realEstate = this.activeItem.commercial = this.activeItem.special = this.activeItem.equipment = false)
      }
      if (event.target.value === 'commercial') {
        this.activeItem.commercial = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.special = this.activeItem.equipment = false)
      }
      if (event.target.value === 'special') {
        this.activeItem.special = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.commercial = this.activeItem.equipment = false)
      }
      if (event.target.value === 'equipment') {
        this.activeItem.equipment = !(this.activeItem.realEstate = this.activeItem.light = this.activeItem.commercial = this.activeItem.special = false)
      }
      if (event.target.value === 'realEstate') {
        this.activeItem.realEstate = !(this.activeItem.light = this.activeItem.commercial = this.activeItem.special = this.activeItem.equipment = false)
      }
    },
    handleErrors (e) {
      // this.$notify({
      //   group: 'lsg-notify',
      //   text: e.message
      // })
    },
    prettifyPrice (price) {
      return Number(price).toLocaleString('ru', {
        maximumFractionDigits: 2
      })
    },
    async downloadCalc (calcId) {
      if (this.createFields.propType === 'realEstate') {
        this.createFields.propType = 'special'
      }
      let currentInput = {
        id: calcId,
        leadId: '5e807e742f07c76dd7a473ad',
        ...this.createFields
      }
      await this.$apollo.mutate({
        mutation: UPDATE_CALC,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=${calcId}`, '_blank')
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    switchModalOk () {
      if (this.smsCode === '1234') {
        this.$store.state.step = 3
        this.$store.state.smsModal = false
      } else {
        this.$notify({
          group: 'lsg-notify',
          text: 'Неверный код'
        })
      }
      this.smsCode = ''
    },
    switchModalClose () {
      this.$store.state.calcModal = false
    },
    timer () {
      this.timeLeft = 120
      let timer = setInterval(() => {
        if (this.timeLeft === 0) {
          clearInterval(timer)
        } else {
          this.timeLeft--
        }
      }, 1000)
    }
  },
  computed: {
    modelPrice: {
      get () {
        return this.indicators.price ? this.createFields.price : this.createFields.price.toLocaleString()
      },
      set (value) {
        this.createFields.price = +value.replace(/\s/g, '')
        this.$emit('input', this.createFields.price)
      }
    },
    modelPrepaid: {
      get () {
        return this.indicators.prepaid ? this.createFields.prepaid : this.createFields.prepaid.toLocaleString()
      },
      set (value) {
        this.createFields.prepaid = +value.replace(/\s/g, '')
        this.$emit('input', this.createFields.prepaid)
      }
    },
    prepaidProc: {
      get () {
        return 100 * this.createFields.prepaid / this.createFields.price || 0
      },
      set (newValue) {
        this.createFields.prepaid = this.createFields.price * newValue / 100 || 0
      }
    },
    input () {
      if (
        this.createFields.duration === 0 ||
        this.createFields.price === 0 ||
        this.createFields.prepaid === 0) return null
      return {
        ...this.createFields
      }
    },
    showModal () {
      return this.$store.state.calcModal
    }
  }
}
</script>

<style lang="stylus">

.modal--calc {
  margin 150px auto

  +below(1130px) {
    margin 0 auto
  }
}

.modal__body--calc {
  background none !important
  border none !important
}

.calc--modal {
  display flex
  justify-content center
  background none !important
  border none !important
  padding none !important
}

.modal__sms {
  display flex
  flex-direction column
  justify-content space-around
  align-items flex-start !important
  width 500px
  min-height 289px

  +below(500px) {
    min-height 400px
    margin 0 10px
  }

  &__input {
    border-top none !important
    border-left none !important
    border-right none !important
    border-radius 0 !important
    max-width 309px
    box-shadow none !important
    font-size 24px !important
  }

  &__subtitle,
  &__title {
    color $c7A
  }

  &__title > h3 {
    margin 0 !important
  }

  &__btns {
    width 100%
    display flex
    flex row wrap
    align-items center
    justify-content space-between

    +below(500px) {
      flex-direction column
      margin-top 10px
      height 90px
      align-items flex-start
    }
  }
}

.calc {
  grid()
  background $white
  border-radius 10px
  border 2px solid $pinky
  padding 32px 28px
  margin-top 12px
  align-items: flex-start;

  &--center {
    margin-top 0
    background $gray
    border none
    justify-content center
  }

  &__form {
    grid_item(50%)
    background: linear-gradient(0deg, $c2C3944 0%, $c2C3944 100%);
    padding 12px 16px 28px
    border-radius 10px
    +below(1130px) {
      grid_item(100%)
    }

    > ._row > .active {
      > i > svg {
        rect
        polygon {
          fill #ff8000
        }

        path:not([stroke]):not([fill]) {
          fill #ff8000
        }

        path[stroke] {
          stroke #ff8000
        }

        path[fill] {
          fill #ff8000
        }
      }
      > span {
        color #ff8000
      }
    }

    &__additional {
      display flex
      flex-direction column
      justify-content flex-start
      align-items flex-end

      &__header {
        display flex
        align-items center
        margin 16px 0 7px
        cursor pointer
        user-select none

        span {
          font-size 12px
          line-height 16px
          color $white
          margin-left 5px
        }

        i {
          width 16px
          height 16px
          display flex
          align-items center
          justify-content center

          svg {
            width 100%

            path[stroke] {
              stroke $white
            }

            path[fill] {
              fill $white
            }
          }
        }
      }

      &__body {

      }
    }

    &__item {
      display flex
      flex-wrap wrap

      label {
        width 100%
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        margin-bottom 5px
      }

      > input
      > ._row--width-fix input
      > textarea
      .mx-input-wrapper > input {
        background $c848A8F !important
        color $white !important
        width 100% !important

        &:focus {
          box-shadow none
        }
      }
      span {
        width 100%
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        margin-left 15px
      }

      > select {
        appearance none
        -moz-appearance none
        -webkit-appearance none
        margin 0
        width 100%
        color $white
        padding-right 40px
        display inline-flex
        align-items center
        justify-content center
        font-family font_regular
        background-color $c848A8F
        background-image: linear-gradient(45deg, transparent 50%, $white 50%), linear-gradient(135deg, $white 50%, transparent 50%);
        background-position calc(100% - 20px) calc(1em + 3px), calc(100% - 15px) calc(1em + 3px)
        background-size 5px 5px, 5px 5px
        background-repeat no-repeat
      }
    }

    &__note {
      margin 30px 0 0 0 !important
      color $white
      font-weight 400
      font-size 12px
      line-height 15px
    }

    &__type {
      cursor pointer
      display flex
      flex-direction column
      justify-content flex-start
      align-items center
      position relative

      &__list {
        margin-top: 0 !important;
      }

      input {
        visibility hidden
        opacity 0
        position absolute
      }

      i {
        width 50px
        height 50px
        display flex
        align-items center
        justify-content center

        svg {
          width 100%

          rect
          polygon {
            fill $white
          }

          path:not([stroke]):not([fill]) {
            fill $white
          }

          path[stroke] {
            stroke $white
          }

          path[fill] {
            fill $white
          }
        }
      }

      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $white;
        text-align center
        margin-top 12px
      }
    }
  }

  &__form--client {
    width 600px
    min-height 418px
    margin 0 !important
    flex-basis auto
    padding 12px 16px
    +below(1130px) {
      max-width 100%
      grid_item(100%)
      padding 12px 16px 28px
      min-height auto
    }
  }

  &__dark {
    padding 0 12px
    display flex
    flex-direction column
    width 100%

    &__price {
      font-size 20px !important
      line-height 26px !important
    }
  }

  &__results {
    background #dae3ea
    border-radius 15px
    padding 15px
    display flex
    flex-direction column
    align-items center
    justify-content center
    max-width 290px
    width 100%
    height 100%

    +below(1130px) {
      max-width 100%
      grid_item(100%)
    }

    &__white {
      display flex
      flex-direction column
      width 100%
      align-items center
      justify-content center
      text-align center
      background #fff
      border-radius 5px
      padding 15px 15px 21px
      color #000
    }

    &__title {
      font-weight 500
      font-size 16px
      line-height 20px
      text-align center
    }

    &__price {
      font-weight 400
      font-size 32px
      line-height 38px
      text-align center
      margin 10px 0
    }

    &__type {
      font-weight 400
      font-size 14px
      line-height 17px
      text-align center
    }

    &__dark {
      padding 0 12px
      display flex
      flex-direction column
      width 100%
    }

    &__item {
      display flex
      flex-direction column
      text-align center
      justify-content center
      padding 15px 15px 20px
      background #2a3742
      color #fff

      &:not(:last-child) {
        border-bottom 1px solid hsla(0,0%,100%,.1)
      }

      &:last-child {
        border-radius 0 0 5px 5px
        padding-bottom 40px
      }
    }

    &__btn {
      display flex
      justify-content center
      text-align center
      width 100%
      transform translateY(-50%)
    }

    &__note {
      font-weight 400
      font-size 12px
      line-height 15px
      text-align center
      color #000
      width 100%
    }
  }

  &__info {
    grid_item(50%)
    +below(1130px) {
      grid_item(100%)
    }

    &--center {
      margin 15px auto !important
      width 888px
      flex-basis auto

      +below(1130px) {
        width auto
        grid_item(100%)
      }
    }

    &__download {
      display flex
      justify-content flex-end
      margin 8px 0 12px
    }

    &__item {
      display flex
      flex-direction column
      align-items center
      justify-content center
      padding 12px 24px

      &--orange {
        background $orange

        span {
          font-weight bold
          font-style normal
        }
      }

      &__title {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 16px;
        text-align center
        color: $black;
      }

      &__subtitle {
        font-style: italic;
        font-weight: 300;
        font-size: 20px;
        line-height: 21px;
        color: #000000;
        margin-top 8px
        text-align center
      }
    }
  }
}

.calc-types {
  display grid !important
  grid-template-columns repeat(3, 1fr) !important

  +below(1330px) {
    grid-template-columns repeat(3, 1fr) !important
  }

  +below(1130px) {
    grid-template-columns repeat(6, 1fr) !important
  }

  +below(670px) {
    grid-template-columns repeat(3, 1fr) !important
  }

  +below(460px) {
    grid-template-columns repeat(2, 1fr) !important
  }

  &--client {
    display flex !important
    flex-flow row nowrap !important

    +below(670px) {
      display grid !important
      grid-template-columns repeat(3, 1fr) !important
    }

    +below(460px) {
      grid-template-columns repeat(2, 1fr) !important
    }
  }
}

</style>

<template>
  <div class="modal" v-if="showOtherLeasingModal">
    <div class="modal__back" @click="switchOtherLeasingModal"></div>
    <div class="modal__body tbl" style="max-width: 90vw">
      <h2 class="ct_row ol-title">Сторонний лизинг<br><i>*за исключением платежей в Лизинг-Трейд</i></h2>
      <template>
        <ApolloQuery
          class="ct_row"
          :query="require('@/graphql/queries/OtherLeasing.gql')"
          :variables="{ inn: $store.state.data.companies.inn, conturfocus }"
          @result="doneLoadingOtherLeasing"
        >
          <template slot-scope="{ result: { error, data } }">
            <div class="ct__row" v-if="error">
              <div class="ct__col">
                <div class="ct__input">
                  <label for="message">Ошибка:</label>
                  <input type="text" :value="error.message" id="message" disabled />
                </div>
              </div>
            </div>
            <div v-if="isLoading" class="messages apollo">
              <div v-if="isLoading" class="messages__status">Загрузка...</div>
            </div>
            <div v-else-if="data" class="tbl">
              <div style="display: flex; flex-direction: row">
                <label for="counterfocus" style="color: #808080; font-size: 16px; font-weight: 600; margin-right: 10px">Контурфокус</label>
                <SwitcherAlt id="counterfocus" :value="conturfocus" @updateTable="switchConturfocus" />
              </div>
              <div class="tbl__container">
                <mq-layout :mq="['xlg', 'xl']">
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <span>Лизинговая компания</span>
                        </th>
                        <th>
                          <span>Ежем. платёж</span>
                        </th>
                        <th>
                          <span>Дата начала</span>
                        </th>
                        <th>
                          <span>Дата окончания</span>
                        </th>
                        <th class="phones" style="width: 40px">
                          <div class="small-buttons" v-if="rows.length === 0">
                            <button
                              class="btn btn--small"
                              @click="addLeasing(0)"
                            >
                              <PlusIco/>
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="rows.length > 0">
                      <tr v-for="(row, index) in rows" :key="index" class="no-hover">
                        <td>
                          <input type="text" v-model.trim="row.company_name">
                        </td>
                        <td>
                          <input type="number" v-model="row.payment">
                        </td>
                        <td>
                          <input type="date" v-model="row.date_start">
                        </td>
                        <td>
                          <input type="date" v-model="row.date_end">
                        </td>
                        <td class="phones" style="width: 60px">
                          <div class="small-buttons" >
                            <button
                              class="btn btn--small"
                              @click="deleteLeasing(index)"
                            >
                              <MinusIco/>
                            </button>
                            <button
                              class="btn btn--small"
                              @click="addLeasing(index)"
                            >
                              <PlusIco/>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="rows.length === 0">
                      <tr>
                        <td colspan="5">Нет результатов</td>
                      </tr>
                    </tbody>
                  </table>
                </mq-layout>
                <mq-layout :mq="['xs', 'sm', 'xsm', 'md', 'lg']" class="tbl__container tbl__mobile">
                  <table>
                    <tbody v-if="isLoading">
                      <div v-if="isLoading" class="messages apollo">
                        <div v-if="isLoading" class="messages__status">Загрузка...</div>
                      </div>
                    </tbody>
                    <tbody v-else-if="rows.length > 0">
                      <tr v-for="(row, index) in rows" :key="index" class="no-hover">
                        <td class="no-hover">
                          <div class="tbl__content__container" style="display: flex; flex-direction: column">
                            <div class="tbl__content">
                              <label>
                                <span>Лизинговая компания</span>
                                <input type="text" v-model.trim="row.company_name">
                              </label>
                              <label>
                                <span>Ежем. платёж</span>
                                <input type="number" v-model="row.payment">
                              </label>
                              <label>
                                <span>Дата начала</span>
                                <input type="date" v-model="row.date_start">
                              </label>
                              <label>
                                <span>Дата окончания</span>
                                <input type="date" v-model="row.date_end">
                              </label>
                            </div>
                            <div class="tbl__content" style="margin-top: 10px">
                              <div class="phones">
                                <div class="small-buttons" >
                                  <button
                                    class="btn btn--small"
                                    @click="deleteLeasing(index)"
                                  >
                                    <MinusIco/>
                                  </button>
                                  <button
                                    class="btn btn--small"
                                    @click="addLeasing(index)"
                                  >
                                    <PlusIco/>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="rows.length === 0">
                      <tr>
                        <td colspan="5">Нет результатов</td>
                      </tr>
                    </tbody>
                  </table>
                </mq-layout>
              </div>
              <div class="buttons" style="display: flex; flex-direction: row; flex-wrap:wrap; justify-content: space-between; margin: 20px;">
                <div class="buttons" style="display: flex; flex-direction: row; flex-wrap: nowrap; margin-right: 10px; margin-bottom: 10px;">
                  <button class="btn btn--gray" @click="switchOtherLeasingModal">Закрыть</button>
                  <button class="btn btn--gray" style="margin-left: 20px" @click="resetRows">Сброс</button>
                </div>
                <button class="btn" style="max-height: 40px" @click="saveOtherLeasing">Сохранить</button>
              </div>
            </div>
          </template>
        </ApolloQuery>
      </template>
    </div>
  </div>
</template>

<script>
import OTHER_LEASING from '@/graphql/mutations/OtherLeasing.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import PlusIco from '@/components/svg/plus'
import MinusIco from '@/components/svg/minus'
import SwitcherAlt from '@/components/ui/SwitcherAlt'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'OtherLeasingModal',
  components: { PlusIco, MinusIco, SwitcherAlt },
  computed: {
    // Показ модалки, если всё норм
    showOtherLeasingModal () {
      return this.$store.state.data.companies.otherLeasingModal && this.$route.params.id && this.$store.state.data.companies.inn
    }
  },
  data () {
    return {
      columns: [
        { label: 'Лизинговая компания', field: 'company_name' },
        { label: 'Ежем. платёж', field: 'payment' },
        { label: 'Дата начала', field: 'date_start' },
        { label: 'Дата окончания', field: 'date_end' }
      ],
      conturfocus: false, // true = справочник + контурфокус
      rows: [],
      isLoading: true,
      cloneRows: []
    }
  },
  methods: {
    // Сброс введенных данных
    resetRows () {
      this.rows = Array.from(this.cloneRows)
    },

    // Ограничение на 1 пустой объект (проверка наименования)
    isManyEmptyObjects () {
      return this.rows
        .filter(elem => !elem.company_name)
        .length > 0
    },

    // Переключатель видимости
    switchOtherLeasingModal () {
      this.$store.state.data.companies.otherLeasingModal = !this.$store.state.data.companies.otherLeasingModal
    },

    // Обработка результата запроса
    async doneLoadingOtherLeasing ({ data }) {
      if (data) {
        this.rows = await data.otherLeasing
        this.cloneRows = Array.from(this.rows) // Создание копии для сброса введенных данных
        this.isLoading = false
      }
    },

    // Добавление строки
    addLeasing (index) {
      if (this.isManyEmptyObjects()) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Должно быть не более одной пустой новой записи'
        })
        return
      }
      this.rows.splice(index, 0, { company_name: null, payment: null, date_start: null, date_end: null })
    },

    // Удаление строки
    deleteLeasing (index) {
      this.rows.splice(index, 1)
    },

    // Отправка данных
    async saveOtherLeasing () {
      if (this.rows.filter(elem => !elem.company_name).length !== 0) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Новые записи не должны содержать пустую строку в поле "Лизинговая компания"'
        })
      } else {
        await this.$apollo.mutate({
          mutation: OTHER_LEASING,
          variables: {
            list: this.rows,
            inn: this.$store.state.data.companies.inn
          }
        }).then(() => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Успешно'
          })
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },

    // Переключатель
    switchConturfocus (isCheck) {
      this.conturfocus = isCheck
    }
  }
}
</script>

<style scoped>
  .no-hover:hover {
    background: auto !important;
  }
  .ol-title {
    text-align: center;
  }
  .ol-title > i {
    font-size: 14px;
    font-weight: 200 !important;
    color: gray;
  }
</style>

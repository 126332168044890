<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body">
      <div class="column-field-set">
        <strong>Виджеты</strong>
        <ul v-if="$store.state.me.role !== 'partner'">
          <li v-for="(col, index) in columns" :key="index">
            <checkbox :class-name="col.show ? 'active' : ''" v-model="col.show" :value="false">{{ col.label }}</checkbox>
          </li>
        </ul>
        <ul v-else>
          <li v-for="(col, index) in clientColumns" :key="index">
            <checkbox :class-name="col.show ? 'active' : ''" v-model="col.show" :value="false">{{ col.label }}</checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnWidgetsModal',
  data () {
    return {
      columns: this.$store.state.data.widgets.columns.map(col => ({
        show: !col.hidden,
        label: col.label,
        field: col.field,
        type: col.type
      })),
      clientColumns: this.$store.state.data.widgets.clientColumns.map(col => ({
        show: !col.hidden,
        label: col.label,
        field: col.field,
        type: col.type
      }))
    }
  },
  computed: {
    showModal () {
      return this.$store.state.data.widgets.columnModal
    },
    hidden () {
      return this.$store.state.me.role !== 'partner' ? this.columns.map(col => !col.show) : this.clientColumns.map(col => !col.show)
    }
  },
  watch: {
    hidden () {
      localStorage.widgetsColumns = JSON.stringify(this.columns)
      this.columns.forEach((col, i) => {
        this.$store.state.data.widgets.columns[i].hidden = !col.show
      })
      localStorage.clientWidgetsColumns = JSON.stringify(this.clientColumns)
      this.clientColumns.forEach((col, i) => {
        this.$store.state.data.widgets.clientColumns[i].hidden = !col.show
      })
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.widgets.columnModal = !this.$store.state.data.widgets.columnModal
    }
  }
}
</script>

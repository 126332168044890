<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body modal__body--flex-start">
      <div class="column-field-set">
        <strong>Столбцы</strong>
        <ul>
          <li v-for="(col, index) in columns" :key="index">
            <checkbox :class-name="col.show ? 'active' : ''" v-model="col.show" :value="false">{{ col.label }}</checkbox>
          </li>
        </ul>
      </div>
      <div class="column-field-set">
        <strong>Фильтры</strong>
        <ul>
          <li v-for="(filter, index) in filters" :key="index">
            <checkbox :class-name="filter.show ? 'active' : ''" v-model="filter.show" :value="false">{{ filter.label }}</checkbox>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColumnCompanyModal',
  data () {
    return {
      columns: this.$store.state.data.companies.columns.map(col => ({
        show: !col.hidden,
        label: col.label,
        field: col.field
      })),
      filters: this.$store.state.data.companies.filters.map(filter => ({
        show: !filter.hidden,
        label: filter.label,
        field: filter.field
      }))
    }
  },
  computed: {
    showModal () {
      return this.$store.state.data.companies.columnModal
    },
    hidden () {
      return this.columns.map(col => !col.show)
    },
    hiddenFilters () {
      return this.filters.map(filter => !filter.show)
    }
  },
  watch: {
    hidden () {
      localStorage.companyColumns = JSON.stringify(this.columns)
      this.columns.forEach((col, i) => {
        this.$store.state.data.companies.columns[i].hidden = !col.show
      })
    },
    hiddenFilters () {
      localStorage.companyFilters = JSON.stringify(this.filters)
      this.filters.forEach((col, i) => {
        this.$store.state.data.companies.filters[i].hidden = !col.show
      })
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.companies.columnModal = !this.$store.state.data.companies.columnModal
    }
  }
}
</script>

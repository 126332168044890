<template>
  <div class="field">
    <input class="field__input" type="text" v-model="inputText" @input="fetchHolding"/>
    <div class="field__list" v-if="holdings.length && inputText.length">
      <div class="field__list__item" @click="selectHolding(holding)" v-for="(holding, i) in holdings" :key="i">{{ holding.name }}</div>
    </div>
    <div class="field__list" v-if="!holdings.length && inputText.length && !clickNewHolding">
      <div class="field__list__item" @click="newHolding(inputText)">+ новый холдинг</div>
    </div>
  </div>
</template>

<script>
import ALL_HOLDINGS from '@/graphql/queries/AllHoldings.gql'

export default {
  name: 'HoldingInput',
  props: {
    default: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      clickNewHolding: false,
      inputText: null,
      timeout: null,
      holdings: []
    }
  },
  created () {
    this.inputText = this.default
  },
  methods: {
    fetchHolding (e) {
      this.holdings = []
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$apollo.mutate({
          mutation: ALL_HOLDINGS,
          variables: {
            input: {
              name: this.inputText
            }
          }
        }).then(({ data }) => {
          this.holdings = data.allHoldings
        })
      }, 500)
    },
    newHolding (name) {
      this.$emit('newInput', name)
      this.clickNewHolding = true
    },
    selectHolding (holding) {
      this.clickNewHolding = false
      this.inputText = holding.name
      clearTimeout(this.timeout)
      this.holdings = []
      this.$emit('input', holding)
    }
  }
}
</script>

<style lang="stylus">
.field {
  position relative

  &__list {
    absolute left bottom
    transform translateY(100%)
    z-index 999
    background $white
    border-radius 0 0 5px 5px
    box-shadow: 0 10px 30px rgba($darker, 0.17);
    user-select none

    &__item {
      padding 10px
      font-size 12px
      line-height 16px
      font-weight bold
      cursor pointer
      color $dark
      transition .3s

      &:hover {
        background $orange
        color $white
      }
    }
  }
}
</style>

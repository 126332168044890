<template>
  <div class="modal" v-if="showDeleteModal">
    <div class="modal__back" @click="switchDeleteModal"></div>
    <div class="modal__body">
      <div class="modal__attention">
        <i class="modal__attention__icon">
          <AttentionIco/>
        </i>
        <span class="modal__attention__title">Вы уверены?</span>
        <span class="modal__attention__subtitle">Данные изменения будут необратимы</span>
        <div class="modal__attention__btns">
          <button class="modal__attention__btn" @click="removeTasks">Да</button>
          <button class="modal__attention__btn modal__attention__btn--orange" @click="switchDeleteModal">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIco from '@/components/svg/attention'
import DELETE_TASKS from '@/graphql/mutations/DeleteTasks.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'DeleteTasksModal',
  components: { AttentionIco },
  computed: {
    showDeleteModal () {
      return this.$store.state.data.tasks.deleteModal
    }
  },
  methods: {
    switchDeleteModal () {
      this.$store.state.data.tasks.deleteModal = !this.$store.state.data.tasks.deleteModal
    },
    async removeTasks () {
      await this.$apollo.mutate({
        mutation: DELETE_TASKS,
        variables: {
          ids: this.$store.state.checkedTasks
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="saveContact" autocomplete="off">
        <div class="trade__modal__header">
          <h1>Контакт</h1>
          <button class="btn btn--gray" @click.prevent="switchModal">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>ФИО:</label>
                <input type="text" v-model="contact.name">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Дата рождения:</label>
                <input type="date" v-model="contact.birthday">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Телефон:</label>
                <div class="phones">
                  <div :key="index" class="phones__item" v-for="(phone, index) in contact.phones">
                    <div class="phones__phone">
                      <input title="79990006699" type="text" maxlength="11" v-model="phone.value" @input="thisShouldTriggerRecompute">
                    </div>
                    <div class="small-buttons">
                      <button type="button" @click="removePhone($event, index)" class="btn btn--small" v-if="index !== 0">
                        <MinusIco/>
                      </button>
                      <button type="button" @click="addPhone" class="btn btn--small">
                        <PlusIco/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Email</label>
                <input type="text" v-model="contact.email">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Город</label>
                <input placeholder="Город" type="text" v-model="contact.city">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Должность</label>
                <input placeholder="Должность" type="text" v-model="contact.post" required>
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button class="btn" type="submit" v-if="!isChanged">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import PlusIco from '@/components/svg/plus'
import UPDATE_CONTACT from '@/graphql/mutations/UpdateContact.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import MinusIco from '../../../svg/minus'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TradeContactDataModal',
  components: { MinusIco, PlusIco },
  data: () => {
    return {
      contact: {
        name: '',
        phones: [],
        email: '',
        city: '',
        post: '',
        birthday: ''
      },
      backup_phones: [],
      forceRecomputeCounter: 0,
      contact_id: null,
      contactActive: true
    }
  },
  computed: {
    isChanged () {
      if (this.$store.state.data.cabinet.contactDataModalProps.type === 'add') {
        return [this.contact.name, this.contact.phone, this.contact.email, this.contact.city, this.contact.post].every(val => val === '')
      } else {
        return (this.contact.name === '' || this.contact.name === this.$store.state.data.cabinet.contactDataModalProps.data.name) &&
          JSON.stringify(this.contact.phones) === JSON.stringify(this.backup_phones) &&
          (this.contact.email === '' || this.contact.email === this.$store.state.data.cabinet.contactDataModalProps.data.email) &&
          (this.contact.city === '' || this.contact.city === this.$store.state.data.cabinet.contactDataModalProps.data.city) &&
          (this.contact.post === '' || this.contact.post === this.$store.state.data.cabinet.contactDataModalProps.data.post) &&
          (this.contact.birthday === '' || this.contact.birthday === this.$store.state.data.cabinet.contactDataModalProps.data.birthday)
      }
    },
    showModal () {
      return this.$store.state.data.cabinet.contactDataModal
    }
  },
  watch: {
    '$store.state.data.cabinet.contactDataModal' () {
      if (this.$store.state.data.cabinet.contactDataModalProps.data && this.$store.state.data.cabinet.contactDataModalProps.type !== 'add') {
        this.contact.name = this.$store.state.data.cabinet.contactDataModalProps.data.name
        this.contact.email = this.$store.state.data.cabinet.contactDataModalProps.data.email
        this.contact.city = this.$store.state.data.cabinet.contactDataModalProps.data.city
        this.contact.post = this.$store.state.data.cabinet.contactDataModalProps.data.post
        this.contact.birthday = this.$store.state.data.cabinet.contactDataModalProps.data.birthday
        this.contact.phones = this.getCurrentInputPhones(this.$store.state.data.cabinet.contactDataModalProps.data.phone || [])
      }
      if (this.contact.phones.length === 0) {
        this.contact.phones.push({ value: '' })
      }
      this.backup_phones = JSON.parse(JSON.stringify(this.contact.phones))
    },
    contact (value) {
      if (
        value.name === '' &&
        (value.phones === [] || (value.phones[0] ?? value.phones[0].value === '')) &&
        value.email === '' &&
        value.city === '' &&
        value.post === ''
      ) {
        this.contact_id = null
      }
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.cabinet.contactDataModal = !this.$store.state.data.cabinet.contactDataModal
      this.$store.state.data.cabinet.contactDataModalProps.companyId = null
      this.$store.state.data.cabinet.contactDataModalProps.data = null
      this.$store.state.data.cabinet.contactDataModalProps.type = null
    },
    getCurrentInputPhones (phones) {
      let currentPhones = []
      phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone)
        } else {
          currentPhones.push({ value: phone })
        }
      })

      return currentPhones
    },
    getCurrentPhone () {
      let currentPhones = []
      this.contact.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })
      return currentPhones
    },
    thisShouldTriggerRecompute () {
      this.forceRecomputeCounter++
    },
    addPhone () {
      this.contact.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()
      this.contact.phones.splice(index, 1)
    },
    async saveContact (e) {
      e.preventDefault()
      let currentInput = {
        name: this.contact.name,
        email: this.contact.email,
        phone: this.contact.phones.map(item => item.value),
        city: this.contact.city,
        post: this.contact.post,
        birthday: this.contact.birthday,
        company_id: this.$store.state.data.cabinet.contactDataModalProps.companyId
      }
      if (this.$store.state.data.cabinet.contactDataModalProps.type !== 'add') currentInput['id'] = this.$store.state.data.cabinet.contactDataModalProps.data._id
      if (this.contact_id !== null && this.$store.state.data.cabinet.contactDataModalProps.type === 'add') currentInput['id'] = this.contact_id
      await this.$apollo.mutate({
        mutation: UPDATE_CONTACT,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data) {
            this.$router.go()
          }
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    onContactChange (item) {
      this.graphItem = item
      this.graphItem.phone = this.getCurrentInputPhones(item.phone || [])
      this.contact_id = item._id
      this.contact.name = item.name
      this.contact.phones = this.getCurrentInputPhones(item.phone || [])
      this.contact.email = item.email
      this.contact.city = item.city
      this.contact.post = item.post
      this.contact.birthday = item.birthday
      if (this.contact.phones.length === 0) {
        this.contact.phones.push({ value: '' })
        this.graphItem.phone.push({ value: '' })
      }
      this.$nextTick(() => {
        this.thisShouldTriggerRecompute()
      })
    }
  }
}
</script>

<style lang="stylus">

input[type='date']
  resize none !important

</style>

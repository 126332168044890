<template>
  <div class="bn__form">
    <div class="bn__docs">
      <template v-for="(item, i) in files">
        <div class="ct__doc" v-if="item.files && item.files.length" :key="i">
          <h3 class="ct__doc__title">{{ item.title }}</h3>
          <ul class="ct__doc__list">
            <li v-for="(file, index) in item.files.filter(file => file.path)" :key="index" class="ct__doc__list__item">
              <div class="ct__doc__type__container">
                <div class="ct__doc__link">
                  <!-- <div>
                    <a href="#" @click.prevent>
                      <Close/>
                    </a>
                  </div> -->
                  <a target="_blank" :href="'https://' + file.path" v-if="file.path" download>{{ file.name }}.{{ file.ext }}</a>
                </div>
                <span class="ct__doc__type" v-if="file.date">{{ dateTransform(file.date) }}</span>
                <span v-else class="ct__doc__type">{{ file.type }}</span>
              </div>
              <div v-if="file.date" class="ct__doc__type__container ct__doc__type__container--end">
                <span class="ct__doc__type">{{ file.type }}</span>
              </div>
            </li>
            <li v-for="(nfile, index) in newFiles[i].files" :key="index + 100" class="ct__doc__list__item ct__doc__list__item--old" style="z-index: 99999">
              <a class="ct__doc__link" href="javascript:void(0)">{{ nfile.file.name }}</a>
              <v-select appendToBody :options="item.filetypes.map(file => ({label: file.type, code: file}))" v-model="nfile.info">
                <template slot="no-options">Нет результатов</template>
                <template #open-indicator="{ attributes }">
                  <div v-bind="attributes">
                    <DownIcon/>
                  </div>
                </template>
              </v-select>
            </li>
          </ul>
          <div class="ct__doc__drop">
            <vue-dropzone
              :id="'dropzone' + i"
              @vdropzone-files-added="filesPicked($event, i)"
              :includeStyling="false"
              useCustomSlot
              :options="dropzoneOptions">
              <div class="lk__dz">
                <i>
                  <PaperClip/>
                </i>
                <span>Выберите или перетащите сюда файлы</span>
              </div>
            </vue-dropzone>
          </div>
        </div>
      </template>
      <div class="ct__bottom" style="margin-top: 2rem; margin-bottom: 2rem">
        <div class="ct__buttons">
          <button class="btn hidden"></button>
          <button @click="validate" class="btn" type="button" :disabled="loading">{{ loading ? 'Подождите...' : 'Сохранить' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import PaperClip from '@/components/svg/PaperClip'
import DownIcon from '@/components/svg/DownIcon'
// import Close from '../../../../components/svg/close'
import moment from 'moment'

export default {
  name: 'CheckFiles',
  components: {
    // Close,
    DownIcon,
    PaperClip,
    vueDropzone: vue2Dropzone
  },
  props: {
    files: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        acceptedFiles: '*'
      },
      newFiles: [],
      loading: false
    }
  },
  created () {
    this.newFiles = this.files.map(file => ({
      title: file.title,
      files: []
    }))
  },
  beforeDestroy () {
    if (this.$store.state.data.trades.checkTradeModal === true) {
      this.loading = false
      // this.$emit('next', this.newFiles)
    }
  },
  methods: {
    dateTransform (date) {
      if (!date) return ''

      return moment(date).format('DD.MM.YYYY HH:mm')
    },
    validate () {
      this.loading = true
      let valid = true
      this.newFiles.forEach(object => {
        object.files.forEach(file => {
          if (!file.info) {
            alert('Не все поля заполнены')
            valid = false
            this.loading = false
          }
        })
      })
      if (valid) {
        this.$emit('next', this.newFiles)
        this.loading = false
        this.$store.state.data.trades.checkTradeModal = false
        this.$store.state.data.trades.checkSuccessModal = true
      }
    },
    filesPicked (e, i) {
      this.newFiles[i].files = [...this.newFiles[i].files, ...Array.from(e).map(file => ({ file: file, info: null }))]
    }
  }
}
</script>

<template>
  <div class="field">
    <input class="field__input" type="text" :placeholder="placeholder" v-model="inputText" @input="sortAgents" :required="required"/>
    <div class="field__list" v-if="inputText && agents && inputText.length && agents.length">
      <div class="field__list__item" @click="selectAgent(agent)" v-for="(agent, i) in agents" :key="i">{{ agent['Наименование'] }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgentInput',
  props: {
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      required: false
    },
    default: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      inputText: null,
      timeout: null,
      agents: []
    }
  },
  created () {
    this.inputText = this.default
  },
  methods: {
    sortAgents () {
      this.agents = this.$store.state.agentList.filter(agent => agent['Наименование'].toLowerCase().includes(this.inputText.toLowerCase())).slice(0, 5)
    },
    selectAgent (agent) {
      this.inputText = agent['Наименование']
      this.agents = []
      this.$emit('input', {
        name: agent['Наименование'],
        id: agent['Ссылка']
      })
    }
  }
}
</script>

<style lang="stylus">
.field {
  position relative

  &__list {
    absolute left bottom
    transform translateY(100%)
    z-index 999
    background $white
    border-radius 0 0 5px 5px
    box-shadow: 0 10px 30px rgba($darker, 0.17);
    user-select none

    &__item {
      padding 10px
      font-size 12px
      line-height 16px
      font-weight bold
      cursor pointer
      color $dark
      transition .3s

      &:hover {
        background $orange
        color $white
      }
    }
  }
}
</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body trade__modal--clamp">
      <div class="trade__modal">
        <div class="trade__modal__header">
          <h1>Документы</h1>
          <button class="btn btn--gray" @click.prevent="switchModal">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input"></div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button class="btn">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeDocsModal',
  computed: {
    showModal () {
      return this.$store.state.data.trades.docsModal
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.trades.docsModal = !this.$store.state.data.trades.docsModal
    }
  }
}
</script>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body trade__modal--clamp">
      <form class="trade__modal" @submit.prevent="saveCompany" autocomplete="off">
        <div class="trade__modal__header">
          <h1>Организация</h1>
          <button class="btn btn--gray" @click.prevent="switchModal">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Наименование:</label>
                <CompanyInput
                  v-if="!$store.state.data.trades.companyDataModalProps.data._id"
                  columnName="name"
                  :currentValue="graphItem.name"
                  filterName="q"
                  placeholder="Добавление компании"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
                />
                <input v-else type="text" v-model="company.name">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Телефон:</label>
                <div class="phones">
                  <div :key="index" class="phones__item" v-for="(phone, index) in company.phones">
                    <div class="phones__phone">
                      <!-- <input title="79990006699" pattern="d{11}" type="tel" v-model="phone.value" @input="thisShouldTriggerRecompute"> -->
                      <input title="79990006699" type="text" v-model="phone.value" @input="thisShouldTriggerRecompute">
                    </div>
                    <div class="small-buttons">
                      <button type="button" @click="callPhone(phone.value)" class="btn btn--small" v-if="phone.value">
                        <PhoneIcon/>
                      </button>
                      <button type="button" @click="removePhone($event, index)" class="btn btn--small" v-if="index !== 0">
                        <MinusIco/>
                      </button>
                      <button type="button" @click="addPhone" class="btn btn--small">
                        <PlusIco/>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>E-mail:</label>
                <CompanyInput
                  v-if="!$store.state.data.trades.companyDataModalProps.data._id"
                  columnName="email"
                  :currentValue="graphItem.email"
                  filterName="q"
                  placeholder="Email"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
                />
                <input v-else type="text" v-model="company.email">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>ИНН:</label>
                <CompanyInput
                  v-if="!$store.state.data.trades.companyDataModalProps.data._id"
                  columnName="inn"
                  :currentValue="graphItem.inn"
                  filterName="q"
                  placeholder="ИНН"
                  @change="onCompanyChange"
                  @changeInput="onChangeInput"
                />
                <input v-else type="text" v-model="company.inn">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Теги:</label>
                <TagsInput :tags="$store.state.data.trades.companyDataModalProps.data ? $store.state.data.trades.companyDataModalProps.data.tags.map(item => ({value: item.id, label: item.name})) : []" @input="onTagsInput"/>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Тип:</label>
                <SelectComponent
                  :options="$store.state.data.companies.typeOptions"
                  :placeholder="'Тип'"
                  :multiple="true"
                  @input="onTypesChanged"
                  :value="company.types"
                />
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="$store.state.me.role !== 'sop' || (company.responsible_user && $store.state.me._id === company.responsible_user._id)">
            <div class="ct__col">
              <div class="ct__input">
                <label>Ответственный:</label>
                <v-select :value="{ label: company.responsible_user.name, value: company.responsible_user._id}" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onUserChange"/>
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button type="submit" class="btn" v-if="!isChanged">Сохранить</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import CompanyInput from '@/components/ui/GraphInputs/CompanyInput'
import PhoneIcon from '@/components/svg/phone'
import MinusIco from '@/components/svg/minus'
import PlusIco from '@/components/svg/plus'
// import MAKE_CALL from '@/graphql/mutations/MakeCall.gql'
import ADD_COMPANY from '@/graphql/mutations/AddCompany.gql'
import UPDATE_COMPANY from '@/graphql/mutations/UpdateCompany.gql'
import UPDATE_LEAD from '@/graphql/mutations/UpdateLead.gql'
import UPDATE_CONTACT from '@/graphql/mutations/UpdateContact.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import SelectComponent from '@/components/ui/GraphInputs/SelectInput'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TradeCompanyDataModal',
  components: { PlusIco, MinusIco, PhoneIcon, CompanyInput, TagsInput, SelectComponent },
  data: () => {
    return {
      company: {
        name: '',
        phones: [],
        email: '',
        inn: '',
        tags: [],
        responsible_user: {
          _id: null,
          name: null
        },
        types: []
      },
      backup_phones: [],
      graphItem: {
        name: '',
        phones: [],
        email: '',
        inn: '',
        tags: [],
        types: [],
        responsible_user: {
          _id: null,
          name: null
        }
      },
      company_id: null
    }
  },
  computed: {
    showModal () {
      return this.$store.state.data.trades.companyDataModal
    },
    isChanged () {
      if (this.$store.state.data.trades.companyDataModalProps.data && this.$store.state.data.trades.companyDataModalProps.data._id) {
        return (this.company.name === '' || this.company.name === this.$store.state.data.trades.companyDataModalProps.data.name) &&
          JSON.stringify(this.company.phones) === JSON.stringify(this.backup_phones) &&
          (this.company.email === '' || this.company.email === this.$store.state.data.trades.companyDataModalProps.data.email) &&
          (this.company.inn === '' || this.company.inn === this.$store.state.data.trades.companyDataModalProps.data.inn) &&
          (this.company.responsible_user._id === this.$store.state.data.trades.companyDataModalProps.data.responsible_user._id) &&
          JSON.stringify(this.company.tags) === JSON.stringify(this.$store.state.data.trades.companyDataModalProps.data.tags.map(item => item.id)) &&
          JSON.stringify(this.company.types) === JSON.stringify(this.$store.state.data.trades.companyDataModalProps.data.types)
      } else {
        return [this.company.name, this.company.email, this.company.inn].every(val => val === '') && JSON.stringify(this.company.phones) === JSON.stringify(this.backup_phones)
      }
    }
  },
  watch: {
    '$store.state.data.trades.companyDataModal' () {
      if (this.$store.state.data.trades.companyDataModalProps.data && this.$store.state.data.trades.companyDataModalProps.data._id) {
        this.company.name = this.$store.state.data.trades.companyDataModalProps.data.name
        this.company.email = this.$store.state.data.trades.companyDataModalProps.data.email
        this.company.inn = this.$store.state.data.trades.companyDataModalProps.data.inn
        this.company_id = this.$store.state.data.trades.companyDataModalProps.data._id
        this.company.phones = this.getCurrentInputPhones(this.$store.state.data.trades.companyDataModalProps.data.phone || [])
        this.company.responsible_user._id = this.$store.state.data.trades.companyDataModalProps.data.responsible_user._id
        this.company.responsible_user.name = this.$store.state.data.trades.companyDataModalProps.data.responsible_user.name
        this.company.tags = this.$store.state.data.trades.companyDataModalProps.data.tags.map(item => item.id)
        this.company.types = this.$store.state.data.trades.companyDataModalProps.data.types
      }

      if (this.company.phones.length === 0) {
        this.company.phones.push({ value: '' })
      }
      this.backup_phones = JSON.parse(JSON.stringify(this.company.phones))
    },
    company (value) {
      if (
        value.name === '' &&
        (value.phones === [] || (value.phones[0] ?? value.phones[0].value === '')) &&
        value.email === '' &&
        value.inn === ''
      ) {
        this.company_id = null
      }
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.trades.companyDataModal = !this.$store.state.data.trades.companyDataModal
      this.$store.state.data.trades.companyDataModalProps.elementId = null
      this.$store.state.data.trades.companyDataModalProps.data = null
      this.$store.state.data.trades.companyDataModalProps.elementType = null
      this.$store.state.data.trades.companyDataModalProps.isCanceled = null
    },
    getCurrentInputPhones (phones) {
      let currentPhones = []

      phones.forEach(phone => {
        currentPhones.push({ value: phone })
      })

      return currentPhones
    },
    getCurrentPhone () {
      let currentPhones = []

      this.company.phones.forEach(phone => {
        if (phone.value) {
          currentPhones.push(phone.value)
        }
      })

      return currentPhones
    },
    thisShouldTriggerRecompute () {
      this.forceRecomputeCounter++
    },
    addPhone () {
      this.company.phones.push({ value: '' })
    },
    removePhone (e, index) {
      e.preventDefault()

      this.company.phones.splice(index, 1)
    },
    async saveCompany (e) {
      e.preventDefault()
      let currentInput = {
        name: this.company.name,
        email: this.company.email,
        phone: this.company.phones.map(item => item.value),
        inn: this.company.inn,
        responsible_user_id: this.company.responsible_user._id,
        tags: this.company.tags,
        types: this.company.types
      }

      if (this.$store.state.data.trades.companyDataModalProps.data._id) currentInput['id'] = this.$store.state.data.trades.companyDataModalProps.data._id
      if (this.company_id !== null && !this.$store.state.data.trades.companyDataModalProps.data._id) currentInput['id'] = this.company_id

      await this.$apollo.mutate({
        mutation: this.$store.state.data.trades.companyDataModalProps.data._id || this.company_id !== null ? UPDATE_COMPANY : ADD_COMPANY,
        variables: {
          input: currentInput
        },
        update: (store, { data }) => {
          if (data && data.addCompany && data.addCompany._id) {
            this.addCompanyInElement(data.addCompany._id)
          }
          if (this.company_id !== null && !this.$store.state.data.trades.companyDataModalProps.data._id) {
            this.addCompanyInElement(data.updateCompany._id)
          }
          this.$notify({
            group: 'lsg-notify',
            text: `Данные компании обновлены`
          })
          this.$store.commit('incrementTradeRerenderKey')
          this.$store.commit('incrementCompanyRerenderKey')
          this.switchModal()
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    async addCompanyInElement (companyId) {
      if (!this.$store.state.data.trades.companyDataModalProps.elementId) {
        this.$emit('create', companyId)
        return
      }
      let mutation = this.$store.state.data.trades.companyDataModalProps.elementType === 'Lead' ? UPDATE_LEAD : UPDATE_CONTACT

      if (companyId) {
        await this.$apollo.mutate({
          mutation: mutation,
          variables: {
            input: {
              id: this.$store.state.data.trades.companyDataModalProps.elementId,
              company_id: companyId
            }
          },
          update: (store, { data }) => {
            if (data) this.$router.go()
          }
        }).catch(error => {
          _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
            this.$notify({
              group: 'lsg-notify',
              text: item
            })
          })
        })
      }
    },
    onCompanyChange (item) {
      this.graphItem = item
      this.company_id = item._id
      this.company.name = item.name
      this.company.phones = this.getCurrentInputPhones(item.phone || [])
      this.company.email = item.email
      this.company.inn = item.inn

      if (this.company.phones.length === 0) {
        this.company.phones.push({ value: '' })
      }
    },
    onUserChange (e) {
      if (e) {
        this.company.responsible_user._id = e.value
        this.company.responsible_user.name = e.label
      } else {
        this.company.responsible_user._id = null
        this.company.responsible_user.name = null
      }
    },
    onTagsInput (tags) {
      this.company.tags = tags.map(item => item.value)
    },
    onTypesChanged (types) {
      this.company.types = types
    },
    onChangeInput ({ columnName, value }) {
      this.company[columnName] = value
    },
    cancelEditCompany (e) {
      e.preventDefault()

      this.$emit('cancel')
    },
    async callPhone (phone) {
      if (!phone || !this.$store.state.me.workphone) {
        this.$notify({
          group: 'lsg-notify',
          text: 'Не указан телефон'
        })
        return
      }
      const callWindow = window.open(`http://192.168.10.3/amocrm/amocrm.php?_login=amocrm&_secret=mWYd1BU9hmWuezov&_action=call&to=${phone}&as=Amocrm&from=${this.$store.state.me.workphone}&rand=0.9550694509986963`)
      setTimeout(() => {
        callWindow.close()
      }, 1_000)
    }
  }
}
</script>

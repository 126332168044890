<template>
  <div>
    <DeleteTasksModal/>
    <ColumnTasksModal/>
    <ColumnEdo/>
    <ColumnSignedEdo/>
    <ColumnTradesModal/>
    <ColumnCompanyModal/>
    <ColumnContractsModal/>
    <ColumnOsContractsModal/>
    <ColumnAnalyticsModal/>
    <ColumnManagerDesktopModal/>
    <TradeMainDataModal/>
    <TradeContactDataModal/>
    <TradeCompanyDataModal/>
    <TradeCalcModal/>
    <TradeDocsModal/>
    <CheckTradeSuccessModal/>
    <CheckTradeModal/>
    <CheckTradeClose/>
    <CabinetContactData/>
    <SmsModal/>
    <CalcModal/>
    <FaqNotificationModal/>
    <ColumnDirectoriesModal/>
    <ColumnWidgetsModal/>
    <OtherLeasingModal/>
    <CardInfoModal/>
    <FullCalcModal/>
    <EdoModal/>
    <HoldingDataModal/>
  </div>
</template>

<script>
import DeleteTasksModal from './components/DeleteTasks'
import ColumnTasksModal from './components/ColumnTasks'
import ColumnTradesModal from './components/ColumnTrades'
import ColumnCompanyModal from './components/ColumnCompany'
import ColumnContractsModal from './components/ColumnContracts'
import ColumnOsContractsModal from './components/ColumnOsContracts'
import ColumnAnalyticsModal from './components/ColumnAnalytics'
import ColumnManagerDesktopModal from './components/ColumnManagerDesktop'
import TradeMainDataModal from './components/TradeMainData'
import TradeContactDataModal from './components/TradeContactData'
import CabinetContactData from './components/CabinetContactData'
import TradeCompanyDataModal from './components/TradeCompanyData'
import TradeDocsModal from './components/TradeDocs'
import TradeCalcModal from './components/TradeCalcModal'
import CheckTradeSuccessModal from './components/CheckTradeSuccess'
import CheckTradeModal from '../../../views/trade/check/CheckTradeModal'
import CheckTradeClose from './components/CheckTradeClose'
import SmsModal from './components/SmsModal'
import CalcModal from './components/CalcModal'
import FaqNotificationModal from './components/FaqNotification'
import ColumnDirectoriesModal from './components/ColumnDirectories'
import ColumnWidgetsModal from './components/ColumnWidgets'
import OtherLeasingModal from './components/OtherLeasingModal.vue'
import CardInfoModal from './components/CardInfoModal.vue'
import FullCalcModal from './components/FullCalcModal.vue'
import ColumnEdo from './components/ColumnEdo'
import ColumnSignedEdo from './components/ColumnSignedEdo'
import EdoModal from './components/EdoModal'
import HoldingDataModal from './components/HoldingData'

export default {
  name: 'ModalsComponent',
  components: {
    ColumnDirectoriesModal,
    FaqNotificationModal,
    CalcModal,
    ColumnEdo,
    EdoModal,
    SmsModal,
    CabinetContactData,
    CheckTradeClose,
    CheckTradeModal,
    CheckTradeSuccessModal,
    TradeCalcModal,
    TradeDocsModal,
    TradeCompanyDataModal,
    TradeContactDataModal,
    TradeMainDataModal,
    ColumnContractsModal,
    ColumnOsContractsModal,
    ColumnCompanyModal,
    ColumnAnalyticsModal,
    ColumnTradesModal,
    ColumnTasksModal,
    DeleteTasksModal,
    ColumnWidgetsModal,
    OtherLeasingModal,
    CardInfoModal,
    FullCalcModal,
    ColumnSignedEdo,
    HoldingDataModal,
    ColumnManagerDesktopModal
  }
}
</script>

<style lang="stylus">
.column-field-set {
  display flex
  flex-direction column
  align-items flex-start

  strong {
    margin-bottom 16px
  }

  ul {
    padding 0
  }
}

.modal {
  overflow-y: auto;
  box-sizing: border-box;
  fixed left top
  z-index 999
  width 100%
  height: 100vh;
  display flex
  align-items: flex-start;
  justify-content center

  +below(768px) {
    height: 100%
  }

  &__back {
    width 100%
    height 100vh
    fixed left top
    background rgba($black, 0.3)
  }

  &__body {
    display flex
    align-items center
    padding 15px
    background $white
    border: 1px solid $pinky;
    box-sizing: border-box;
    box-shadow: 0 10px 30px rgba($darker, 0.17);
    border-radius: 10px;
    z-index 1
    margin 32px 0

    &--flex-start {
      align-items flex-start
    }
  }

  &__attention {
    display flex
    flex-direction column
    justify-content center
    align-items center

    &__icon {
      width 52px
      height 52px
    }

    &__title {
      font-weight: bold;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom 8px
    }

    &__subtitle {
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: $darkgray;
      margin-bottom 19px
    }

    &__btn {
      button(rgba($darkgray, 0.3), $c4E555A)
      padding 8px 15px
      border-radius 10px
      outline none !important

      &--orange {
        button(rgba($orange, 0.3), $orange)
        border-color #ffd9b2
        padding 8px 15px
        border-radius 10px
      }

      &--green {
        button(rgba($green, 0.3), $green)
        color $anothergreen
        border-color $anothergreen
        padding 8px 15px
        border-radius 10px
      }

      &s {
        width 100%
        display flex
        align-items center
        justify-content space-around
      }
    }
  }
}

.trade__modal {
  padding 15px 25px
  box-sizing border-box
  width 100%

  &--clamp {
    max-width 840px
    width 100%
  }

  &__header {
    display flex
    align-items center
    justify-content space-between

    h1 {
      margin 0
    }
  }

  &__body {
    display flex
    flex-flow row wrap
    margin-top 15px
    margin-bottom 55px

    .ct__input {
      select {
        width 100%
      }
    }
  }

  &__footer {
    display flex
    justify-content flex-end
    align-items center
  }
}
</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModalCancel"></div>
    <div class="modal__body">
      <div class="modal__attention">
        <span class="modal__attention__title">Данные не сохранятся. Вы действительно хотите закрыть окно проверки?</span>
        <span class="modal__attention__subtitle"></span>
        <div class="modal__attention__btns">
          <button class="modal__attention__btn modal__attention__btn--orange" @click="switchModalOk" style="width: 6rem">Да</button>
          <button class="modal__attention__btn modal__attention__btn--black" @click="switchModalCancel" style="width: 6rem">Отмена</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CheckTradeClose',
  computed: {
    showModal () {
      return this.$store.state.data.trades.checkTradeClose
    }
  },
  methods: {
    switchModalOk () {
      this.$store.state.data.trades.checkTradeClose = false
      this.$store.state.data.trades.loadCheckTradeData = false
      // this.$router.go()
    },
    switchModalCancel () {
      this.$store.state.data.trades.checkTradeClose = false
      this.$store.state.data.trades.checkTradeModal = true
    }
  }
}
</script>

<template>
  <div class="bn__form">
    <form class="bn__inputs" v-if="companyForm" @submit.prevent="submitForm">
      <div class="ct__row">
        <div class="bn__col">
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="name">Наименование</label>
            <input id="name" type="text" v-model="companyForm.name.value" @change="company.name = companyForm.name.value">
            <span v-if="!!companyForm.name.message" class="ct__message">{{ companyForm.name.message }}</span>
          </div>
          <div
            class="ct__input"
          >
            <label for="fullname">Полное наименование</label>
            <input id="fullname" type="text" v-model="companyForm.fullname.value" @change="company.fullname = companyForm.fullname.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.fullname.message" class="ct__message">{{ companyForm.fullname.message }}</span>
          </div>
          <div
            class="ct__input"
          >
            <label for="inn">ИНН</label>
            <input id="inn" type="number" v-model="companyForm.inn.value" @change="company.inn = companyForm.inn.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.inn.message" class="ct__message">{{ companyForm.inn.message }}</span>
          </div>
          <div
            class="ct__input"
          >
            <label for="kpp">КПП</label>
            <input id="kpp" type="number" v-model="companyForm.kpp.value" @change="company.kpp = companyForm.kpp.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.kpp.message" class="ct__message">{{ companyForm.kpp.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="bank">Банк</label>
            <input id="bank" type="text" v-model="companyForm.bank.value" @change="company.bank = companyForm.bank.value" readonly>
            <span v-if="!!companyForm.bank.message" class="ct__message">{{ companyForm.bank.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label>БИК</label>
            <BankInput :default="companyForm.bik.value" @input="bikChange"  :readonly="['approved', 'dl'].includes($store.state.data.trades.status)"/>
            <span v-if="!!companyForm.bik.message" class="ct__message">{{ companyForm.bik.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="rs">Расчетный счет</label>
            <input id="rs" type="number" v-model="companyForm.rs.value" @change="company.rs = companyForm.rs.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.rs.message" class="ct__message">{{ companyForm.rs.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="adresU">Юридический адрес</label>
            <input id="adresU" type="text" v-model="companyForm.adresU.value" @change="company.adresU = companyForm.adresU.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.adresU.message" class="ct__message">{{ companyForm.adresU.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="adresF">Фактический адрес</label>
            <input id="adresF" type="text" v-model="companyForm.adresF.value" @change="company.adresF = companyForm.adresF.value">
            <span v-if="!!companyForm.adresF.message" class="ct__message">{{ companyForm.adresF.message }}</span>
          </div>
        </div>
        <div class="bn__col">
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="ks">Корреспондентский счет</label>
            <input id="ks" type="number" v-model="companyForm.ks.value" @change="company.ks = companyForm.ks.value" readonly>
            <span v-if="!!companyForm.ks.message" class="ct__message">{{ companyForm.ks.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="adresU">Юридический адрес</label>
            <input id="adresU" type="text" v-model="companyForm.adresU.value" @change="company.adresU = companyForm.adresU.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.adresU.message" class="ct__message">{{ companyForm.adresU.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="adresF">Фактический адрес</label>
            <input id="adresF" type="text" v-model="companyForm.adresF.value" @change="company.adresF = companyForm.adresF.value">
            <span v-if="!!companyForm.adresF.message" class="ct__message">{{ companyForm.adresF.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="adresD">Почтовый адрес</label>
            <input id="adresD" type="text" v-model="companyForm.adresD.value" @change="company.adresD = companyForm.adresD.value">
            <span v-if="!!companyForm.adresD.message" class="ct__message">{{ companyForm.adresD.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label>БИК</label>
            <BankInput :default="companyForm.bik.value" @input="bikChange"/>
            <span v-if="!!companyForm.bik.message" class="ct__message">{{ companyForm.bik.message }}</span>
          </div>
          <div
            class="ct__input"
            v-if="!['approved', 'dl'].includes($store.state.data.trades.status)"
          >
            <label for="rs">Расчетный счет</label>
            <input id="rs" type="number" v-model="companyForm.rs.value" @change="company.rs = companyForm.rs.value" :readonly="['approved', 'dl'].includes($store.state.data.trades.status)">
            <span v-if="!!companyForm.rs.message" class="ct__message">{{ companyForm.rs.message }}</span>
          </div>
          <div
            class="ct__input"
          >
            <label for="phone">Телефон</label>
            <input id="phone" type="tel" v-model="companyForm.phone.value" @change="company.phone = companyForm.phone.value">
            <span v-if="!!companyForm.phone.message" class="ct__message">{{ companyForm.phone.message }}</span>
          </div>
          <div
            class="ct__input"
          >
            <label for="email">E-mail</label>
            <input id="email" type="email" v-model="companyForm.email.value" @change="company.email = companyForm.email.value">
            <span v-if="!!companyForm.email.message" class="ct__message">{{ companyForm.email.message }}</span>
          </div>
          <!-- <div
            class="ct__input" style="visibility: hidden"
          >
            <label>hidden</label>
            <input>
          </div> -->
          <div>
            <div class="ct__input">
              <label>Дата предоставления согласия:</label>
              <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="companyForm.agreement.value"  @change="company.agreement = companyForm.agreement.value"></date-picker>
              <span class="ct__message" v-if="!!companyForm.agreement.message">{{ companyForm.agreement.message }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!['approved', 'dl'].includes($store.state.data.trades.status)" class="ct__bottom" style="margin-top: 2rem; margin-bottom: 2rem">
        <div class="ct__buttons">
          <button class="btn" type="button" @click="nextPage">
            Далее
            <i>
              <ArrowRightIcon/>
            </i>
          </button>
          <!-- <div class="ct__prefill">
            <span class="ct__prefill__title">
              Автоматически заполнить поля
            </span>
            <Switcher @updateTable="toggleUpdate" :value="togglePrefillCompany" />
          </div> -->
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import ArrowRightIcon from '@/components/svg/arrow_right'
import BankInput from '@/components/ui/GraphInputs/BankInput'
import PREFILL_PROJECT from '@/graphql/queries/PrefillProject.gql'
import GraphQLHelper from '@/helpers/GraphQLHelper'
// import Switcher from '@/components/ui/SwitcherAlt'
import moment from 'moment'
import DatePicker from 'vue2-datepicker'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'CheckCompany',
  components: {
    ArrowRightIcon,
    // Switcher,
    BankInput,
    DatePicker
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('YYYY-MM-DD') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'YYYY-MM-DD').toDate() : null
        }
      },
      togglePrefillCompany: false,
      prefillCompany: null,
      companyForm: {
        id: {
          value: '',
          message: ''
        },
        agreement: {
          value: '',
          message: ''
        },
        name: {
          value: '',
          message: ''
        },
        fullname: {
          value: '',
          message: ''
        },
        inn: {
          value: '',
          message: ''
        },
        kpp: {
          value: '',
          message: ''
        },
        bank: {
          value: '',
          message: ''
        },
        bik: {
          value: '',
          message: ''
        },
        rs: {
          value: '',
          message: ''
        },
        ks: {
          value: '',
          message: ''
        },
        adresU: {
          value: '',
          message: ''
        },
        adresF: {
          value: '',
          message: ''
        },
        adresD: {
          value: '',
          message: ''
        },
        phone: {
          value: '',
          message: ''
        },
        email: {
          value: '',
          message: ''
        }
      }
    }
  },
  created () {
    if (this.company !== null) {
      Object.keys(this.company).forEach(field => {
        if (field !== 'contact' && field !== 'files') {
          if (field === 'agreement' && this.company[field] === '0001-01-01T00:00:00') this.companyForm[field].value = null
          else if (this.companyForm[field]) {
            this.companyForm[field].value = this.company[field]
          }
        }
      })
    }
    this.getPrefillCompany()
  },
  beforeDestroy () {
    this.$emit('next', this.companyForm)
  },
  methods: {
    updateCompany () {
      if (this.togglePrefillCompany) {
        Object.keys(this.prefillCompany).forEach(field => {
          if (this.companyForm[field].value === '') this.companyForm[field].value = this.prefillCompany[field]
        })
      } else {
        Object.keys(this.company).forEach(field => {
          if (field !== 'contact' && field !== 'files') {
            if (this.companyForm[field]) {
              this.companyForm[field].value = this.company[field]
            }
          }
        })
      }
    },
    toggleUpdate (e) {
      this.togglePrefillCompany = e
      this.updateCompany()
    },
    async getPrefillCompany () {
      await this.$apollo.mutate({
        mutation: PREFILL_PROJECT,
        variables: {
          leadId: this.$route.params.id
        },
        update: (store, { data }) => {
          this.prefillCompany = data.PrefillProject.company_data
        }
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    },
    bikChange (e) {
      this.companyForm.bank.value = e.name
      this.companyForm.bik.value = e.bik
    },
    nextPage () {
      this.$store.state.step = 2
    },
    submitForm () {
      this.$emit('next', this.companyForm)
      this.nextPage()
    }
  }
}
</script>

<style lang="stylus" scoped>
.ct__prefill {
  display flex
  flex-direction row
  align-items center

  &__title {
    font-weight 500
    font-size 15px
    line-height 18px
    color #7a7a7a
    margin-right 10px
    margin-top -8px
  }

  +below(980px) {
    &__title {
      display none
    }
  }
}
</style>

<template>
  <form class="bn__form" @submit.prevent="submitForm">
    <div class="bn__inputs">
      <template v-if="calcForms.length">
        <div class="ct__col mb-30" v-for="(object, i) in calcForms" :key="i">
          <div class="ct__row">
            <div class="ct__col-2">
              <div class="ct__input">
                <label>Наименование:</label>
                <span v-if="object.name.value !== object.name.oldValue" class="old-value">{{ object.name.oldValue }}</span>
                <input type="text" v-model="object.name.value">
                <span v-if="!!object.name.message" class="ct__message">{{ object.name.message }}</span>
              </div>
            </div>
            <div class="ct__col-2">
              <div class="ct__input">
                <label>Поставщик</label>
                <!-- <span v-if="object.provider.value !== object.provider.oldValue" class="old-value">{{ object.provider.oldValue }}</span> -->
                <ProviderInput v-if="object.provider.value" :default="object.provider.value.name" @input="object.provider.value = $event"/>
                <ProviderInput v-else @input="object.provider.value = $event"/>
                <span v-if="!!object.provider.message" class="ct__message">{{ object.provider.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Тип имущества:</label>
                <span v-if="object.propType.value !== object.propType.oldValue" class="old-value">{{ propTypeSelect.find(elem => elem.value === object.propType.oldValue).name }}</span>
                <select v-model="object.propType.value">
                  <option v-for="(item, i) in propTypeSelect" :key="i" :value="item.value">{{ item.name }}</option>
                </select>
                <span v-if="!!object.propType.message" class="ct__message">{{ object.propType.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Вид графика:</label>
                <span v-if="object.graphType.value !== object.graphType.oldValue" class="old-value">{{ graphTypeSelect.find(elem => elem.value === object.graphType.oldValue).name }}</span>
                <select v-model="object.graphType.value">
                  <option v-for="(item, i) in graphTypeSelect" :key="i" :value="item.value">{{ item.name }}</option>
                </select>
                <span v-if="!!object.graphType.message" class="ct__message">{{ object.graphType.message }}</span>
              </div>
            </div>
            <div class="ct__col-3" v-if="['approved', 'dl'].includes($store.state.data.trades.status)">
              <div class="ct__input">
                <label>Балансодержатель:</label>
                <span v-if="object.balanceholder.value !== object.balanceholder.oldValue" class="old-value">{{ object.balanceholder.oldValue }}</span>
                <select v-model="object.balanceholder.value">
                  <option v-for="(item, i) in balanceholderSelect" :key="i" :value="item">{{ item }}</option>
                </select>
                <span v-if="!!object.balanceholder.message" class="ct__message">{{ object.balanceholder.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Стоимость:</label>
                <span v-if="object.price.value !== object.price.oldValue" class="old-value">{{ object.price.oldValue }}</span>
                <input type="number" step="0.01" v-model="object.price.value">
                <span v-if="!!object.price.message" class="ct__message">{{ object.price.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Аванс, руб:</label>
                <span v-if="object.prepaid.value !== object.prepaid.oldValue" class="old-value">{{ object.prepaid.oldValue }}</span>
                <input type="number" step="0.01" v-model="object.prepaid.value">
                <span v-if="!!object.prepaid.message" class="ct__message">{{ object.prepaid.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Срок лизинга:</label>
                <span v-if="object.duration.value !== object.duration.oldValue" class="old-value">{{ object.duration.oldValue }}</span>
                <input type="number" v-model="object.duration.value">
                <span v-if="!!object.duration.message" class="ct__message">{{ object.duration.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Ставка привлечения:</label>
                <span v-if="object.rate.value !== object.rate.oldValue" class="old-value">{{ object.rate.oldValue }}</span>
                <input type="number" step="0.001" v-model="object.rate.value">
                <span v-if="!!object.rate.message" class="ct__message">{{ object.rate.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Комиссия менеджера, %:</label>
                <span v-if="object.managerCom.value !== object.managerCom.oldValue" class="old-value">{{ object.managerCom.oldValue }}</span>
                <input type="number" step="0.001" v-model="object.managerCom.value">
                <span v-if="!!object.managerCom.message" class="ct__message">{{ object.managerCom.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Комиссия агента, %:</label>
                <span v-if="object.agentCom.value !== object.agentCom.oldValue" class="old-value">{{ object.agentCom.oldValue }}</span>
                <input type="number" step="0.001" v-model="object.agentCom.value" @input="handleAgent(i)">
                <span v-if="!!object.agentCom.message" class="ct__message">{{ object.agentCom.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>НДС:</label>
                <span v-if="object.nds.value !== object.nds.oldValue" class="old-value">{{ object.nds.oldValue }}</span>
                <select v-model="object.nds.value">
                  <option v-for="(item, i) in ndsSelect" :key="i" :value="item.value">{{ item.name }}</option>
                </select>
                <span v-if="!!object.nds.message" class="ct__message">{{ object.nds.message }}</span>
              </div>
            </div>
            <div v-if="object.nds.value === 'НДС20_120'" class="ct__col-3">
              <div class="ct__input">
                <label>Входящая стоимость:</label>
                <span v-if="object.inputPrice.value !== object.inputPrice.oldValue" class="old-value">{{ object.inputPrice.oldValue }}</span>
                <input type="number" step="0.001" v-model="object.inputPrice.value">
                <span v-if="!!object.inputPrice.message" class="ct__message">{{ object.inputPrice.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Расчетный:</label>
                <span v-if="object.isCalculation.value !== object.isCalculation.oldValue" class="old-value">{{ object.isCalculation.oldValue }}</span>
                <checkbox v-model="object.isCalculation.value" @input="checkErrors"></checkbox>
                <span v-if="!!object.isCalculation.message" class="ct__message">{{ object.isCalculation.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-4">
              <div class="ct__input" :class="{ required: object.inStock.value && object.year.required && object.year.error}">
                <label>Год:</label>
                <span v-if="object.year.value !== object.year.oldValue" class="old-value">{{ object.year.oldValue }}</span>
                <input type="number" v-model="object.year.value" :required="object.inStock.value && object.year.required" @input="checkErrors" @change="wasInUseToggle(object.year.value, i)">
                <span v-if="!!object.year.message" class="ct__message">{{ object.year.message }}</span>
              </div>
            </div>
            <div class="ct__col-4">
              <div class="ct__input" style="align-items: center;">
                <label>В наличии: </label>
                <span v-if="object.inStock.value !== object.inStock.oldValue" class="old-value">{{ object.inStock.oldValue }}</span>
                <checkbox :class-name="object.inStock.value ? 'active' : ''" v-model="object.inStock.value" :value="true"></checkbox>
                <span v-if="!!object.inStock.message" class="ct__message">{{ object.inStock.message }}</span>
              </div>
            </div>
            <div class="ct__col-4">
              <div class="ct__input" style="align-items: center;">
                <label>Бывшее в употреблении:</label>
                <span v-if="object.wasInUse.value !== object.wasInUse.oldValue" class="old-value">{{ object.wasInUse.oldValue }}</span>
                <checkbox :class-name="object.wasInUse.value ? 'active' : ''" v-model="object.wasInUse.value" :value="true" :disabled="+object.year.value < 2022"></checkbox>
                <span v-if="!!object.wasInUse.message" class="ct__message">{{ object.wasInUse.message }}</span>
              </div>
            </div>
            <div class="ct__col-4" v-if="object.agentCom.value && object.agentCom.value > 0">
              <div class="ct__input" :class="{ required: object.agent.required && object.agent.error }">
                <label>Сторонний исполнитель:</label>
                <span v-if="object.agent.value !== object.agent.oldValue" class="old-value">{{ object.agent.oldValue }}</span>
                <AgentInput v-if="object.agent.value" :default="object.agent.value" ref="agentInput" :placeholder="'Введите наименование агента'" @input="object.agent.value = $event; checkErrors" :required="object.agent.required"/>
                <AgentInput v-else @input="object.agent.value = $event" :default="object.agent.value" ref="agentInput" :placeholder="'Введите наименование агента'" :required="object.agent.required"/>
                <span v-if="!!object.agent.message" class="ct__message">{{ object.agent.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Дата оплаты аванса:</label>
                <span v-if="object.prepaidDate.value !== object.prepaidDate.oldValue" class="old-value">{{ object.prepaidDate.oldValue }}</span>
                <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="object.prepaidDate.value"></date-picker>
                <span v-if="!!object.prepaidDate.message" class="ct__message">{{ object.prepaidDate.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label>Срок поставки:</label>
                <span v-if="object.srokPostavki.value !== object.srokPostavki.oldValue" class="old-value">{{ object.srokPostavki.oldValue }}</span>
                <input type="text" v-model="object.srokPostavki.value">
                <span v-if="!!object.srokPostavki.message" class="ct__message">{{ object.srokPostavki.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input">
                <label></label>
                <span v-if="object.srokPostavkiDni.value !== object.srokPostavkiDni.oldValue" class="old-value">{{ object.srokPostavkiDni.oldValue }}</span>
                <select v-model="object.srokPostavkiDni.value">
                  <option v-for="(item, i) in srokPostavkiDniSelect" :key="i" :value="item">{{ item }}</option>
                </select>
                <span v-if="!!object.srokPostavkiDni.message" class="ct__message">{{ object.srokPostavkiDni.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="object.inStock.value">
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.vin.required && object.vin.error}">
                <label>VIN:</label>
                <span v-if="object.vin.value !== object.vin.oldValue" class="old-value">{{ object.vin.oldValue }}</span>
                <input type="text" v-model="object.vin.value" :required="object.inStock.value && object.vin.required" @input="checkErrors">
                <span v-if="!!object.vin.message" class="ct__message">{{ object.vin.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.pts.required && object.pts.error}">
                <label>ПТС/ПСМ:</label>
                <span v-if="object.pts.value !== object.pts.oldValue" class="old-value">{{ object.pts.oldValue }}</span>
                <input type="text" v-model="object.pts.value" :required="object.inStock.value && object.pts.required" @input="checkErrors">
                <span v-if="!!object.pts.message" class="ct__message">{{ object.pts.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.pts.required && object.pts.error}">
                <label>Дата выдачи ПТС/ПСМ:</label>
                <span v-if="object.pts_date.value !== object.pts_date.oldValue" class="old-value">{{ object.pts_date.oldValue }}</span>
                <date-picker valueType="DD.MM.YYYY" :formatter="momentFormat" v-model="object.pts_date.value"></date-picker>
                <span v-if="!!object.pts_date.message" class="ct__message">{{ object.pts_date.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.body.required && object.body.error}">
                <label>Кузов:</label>
                <span v-if="object.body.value !== object.body.oldValue" class="old-value">{{ object.body.oldValue }}</span>
                <input type="text" v-model="object.body.value" :required="object.inStock.value && object.body.required" @input="checkErrors">
                <span v-if="!!object.body.message" class="ct__message">{{ object.body.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="object.inStock.value">
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.chassis.required && object.chassis.error}">
                <label>Шасси:</label>
                <span v-if="object.chassis.value !== object.chassis.oldValue" class="old-value">{{ object.chassis.oldValue }}</span>
                <input type="text" v-model="object.chassis.value" :required="object.inStock.value && object.chassis.required" @input="checkErrors">
                <span v-if="!!object.chassis.message" class="ct__message">{{ object.chassis.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.colour.required && object.colour.error}">
                <label>Цвет:</label>
                <span v-if="object.colour.value !== object.colour.oldValue" class="old-value">{{ object.colour.oldValue }}</span>
                <input type="text" v-model="object.colour.value" :required="object.inStock.value && object.colour.required" @input="checkErrors">
                <span v-if="!!object.colour.message" class="ct__message">{{ object.colour.message }}</span>
              </div>
            </div>
            <div class="ct__col-3">
              <div class="ct__input" :class="{ required: object.inStock.value && object.engine.required && object.engine.error}">
                <label>Двигатель:</label>
                <span v-if="object.engine.value !== object.engine.oldValue" class="old-value">{{ object.engine.oldValue }}</span>
                <input type="text" v-model="object.engine.value" :required="object.inStock.value && object.engine.required" @input="checkErrors">
                <span v-if="!!object.engine.message" class="ct__message">{{ object.engine.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col-2" v-if="['approved', 'dl'].includes($store.state.data.trades.status)">
              <div class="ct__input">
                <label>Адрес отгрузки ДКП:</label>
                <span v-if="object.adresDKP.value !== object.adresDKP.oldValue" class="old-value">{{ object.adresDKP.oldValue }}</span>
                <input type="text" v-model="object.adresDKP.value">
                <span v-if="!!object.adresDKP.message" class="ct__message">{{ object.adresDKP.message }}</span>
              </div>
            </div>
            <div class="ct__col-2" v-if="['approved', 'dl'].includes($store.state.data.trades.status)">
              <div class="ct__input">
                <label>Адрес эксплуатации ДЛ</label>
                <span v-if="object.adresDL.value !== object.adresDL.oldValue" class="old-value">{{ object.adresDL.oldValue }}</span>
                <input type="text" v-model="object.adresDL.value">
                <span v-if="!!object.adresDL.message" class="ct__message">{{ object.adresDL.message }}</span>
              </div>
            </div>
          </div>
          <div class="ct__col" v-if="['approved', 'dl'].includes($store.state.data.trades.status)">
            <div class="ct__input">
              <label>Технические характеристики:</label>
                <span v-if="object.tehChar.value !== object.tehChar.oldValue" class="old-value">{{ object.tehChar.oldValue }}</span>
              <textarea v-model="object.tehChar.value"></textarea>
              <span v-if="!!object.tehChar.message" class="ct__message">{{ object.tehChar.message }}</span>
            </div>
          </div>
          <div class="ct__col" v-if="['approved', 'dl'].includes($store.state.data.trades.status)">
            <div class="ct__input">
              <label>Условия гарантии:</label>
                <span v-if="object.conditions.value !== object.conditions.oldValue" class="old-value">{{ object.conditions.oldValue }}</span>
              <textarea v-model="object.conditions.value" style="min-height: 3rem"></textarea>
              <span v-if="!!object.conditions.message" class="ct__message">{{ object.conditions.message }}</span>
            </div>
          </div>
          <div class="ct__col">
            <div class="ct__input">
              <label>Комментарий:</label>
                <span v-if="object.comment.value !== object.comment.oldValue" class="old-value">{{ object.comment.oldValue }}</span>
              <textarea v-model="object.comment.value" style="min-height: 3rem"></textarea>
              <span v-if="!!object.comment.message" class="ct__message">{{ object.comment.message }}</span>
            </div>
          </div>
        </div>
        <br>
        <div class="ct__bottom">
          <span class="ct__message" v-if="haveErrors">Заполните все обязательные поля, чтобы продолжить</span>
          <div class="ct__buttons">
            <button class="btn" type="submit" @click="checkErrors" v-if="!['approved', 'dl'].includes($store.state.data.trades.status)">
              Далее
              <i>
                <ArrowRightIcon/>
              </i>
            </button>
            <button class="btn hidden"></button>
            <button v-if="['approved', 'dl'].includes($store.state.data.trades.status)" @click="saveCheck" class="btn" type="button">Сохранить</button>
          </div>
        </div>
      </template>
    </div>
  </form>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import moment from 'moment'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'
import AgentInput from '@/components/ui/GraphInputs/AgentInput'
import ProviderInput from '@/components/ui/GraphInputs/ProviderInput'
import ArrowRightIcon from '@/components/svg/arrow_right'

export default {
  name: 'CheckCalc',
  components: {
    DatePicker,
    AgentInput,
    ProviderInput,
    ArrowRightIcon
  },
  props: {
    calc: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      momentFormat: {
        stringify: (date) => {
          return date ? moment(date).format('DD.MM.YYYY') : ''
        },
        parse: (value) => {
          return value ? moment(value, 'DD.MM.YYYY').toDate() : null
        }
      },
      calcForms: [
        {
          isCalculation: {
            value: false,
            oldValue: false,
            message: '',
            error: false
          },
          agent: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          agentCom: {
            value: '',
            oldValue: '',
            message: ''
          },
          balanceholder: {
            value: '',
            oldValue: '',
            message: ''
          },
          body: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          chassis: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          colour: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          comment: {
            value: '',
            oldValue: '',
            message: ''
          },
          duration: {
            value: '',
            oldValue: '',
            message: ''
          },
          engine: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          graphType: {
            value: '',
            oldValue: '',
            message: ''
          },
          id: {
            value: '',
            oldValue: '',
            message: ''
          },
          managerCom: {
            value: '',
            oldValue: '',
            message: ''
          },
          name: {
            value: '',
            oldValue: '',
            message: ''
          },
          prepaid: {
            value: '',
            oldValue: '',
            message: ''
          },
          prepaidDate: {
            value: '',
            oldValue: '',
            message: ''
          },
          price: {
            value: '',
            oldValue: '',
            message: ''
          },
          inputPrice: {
            value: '',
            oldValue: '',
            message: ''
          },
          nds: {
            value: '',
            oldValue: '',
            message: ''
          },
          propType: {
            value: '',
            oldValue: '',
            message: ''
          },
          provider: {
            value: '',
            oldValue: '',
            message: ''
          },
          pts: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          pts_date: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          rate: {
            value: '',
            oldValue: '',
            message: ''
          },
          vin: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          year: {
            value: '',
            oldValue: '',
            message: '',
            required: true,
            error: false
          },
          inStock: {
            value: false,
            oldValue: false,
            message: ''
          },
          srokPostavki: {
            value: '',
            message: ''
          },
          srokPostavkiDni: {
            value: '',
            oldValue: '',
            message: ''
          },
          tehChar: {
            value: '',
            oldValue: '',
            message: ''
          },
          wasInUse: {
            value: false,
            oldValue: false,
            message: ''
          },
          adresDKP: {
            value: '',
            oldValue: '',
            message: ''
          },
          adresDL: {
            value: '',
            oldValue: '',
            message: ''
          },
          conditions: {
            value: '',
            oldValue: '',
            message: ''
          }
        }
      ],
      balanceholderSelect: [
        'Лизингодатель',
        'Лизингополучатель'
      ],
      srokPostavkiDniSelect: [
        'рабочие дни',
        'календарные дни'
      ],
      ndsSelect: [
        {
          value: 'НДС20',
          name: 'НДС 20'
        },
        {
          value: 'НДС0',
          name: 'НДС 0'
        },
        {
          value: 'БезНДС',
          name: 'Без НДС'
        },
        {
          value: 'НДС20_120',
          name: '20/120'
        }
      ],
      graphTypeSelect: [
        {
          value: 'annuity',
          name: 'Равномерный'
        }, {
          value: 'drawdown',
          name: 'Убывающий'
        }, {
          value: 'annuityStair',
          name: 'Равномерный | Лестница'
        }
      ],
      propTypeSelect: [
        {
          value: 'light',
          name: 'Легковой автотранспорт'
        }, {
          value: 'lightcommercial',
          name: 'Легкий коммерческий автотранспорт'
        }, {
          value: 'commercial',
          name: 'Коммерческий автотранспорт'
        }, {
          value: 'special',
          name: 'Спец техника'
        }, {
          value: 'equipment',
          name: 'Оборудование'
        }, {
          value: 'residentialEstate',
          name: 'Жилая недвижимость'
        }]
    }
  },
  created () {
    if (this.calc !== null) {
      this.calc.forEach((calc, i) => {
        if (i === 0) {
          Object.keys(calc).forEach(field => {
            if (field === 'prepaidDate') {
              if (calc[field] === '0001-01-01T00:00:00') this.calcForms[i][field].value = null
              else this.calcForms[i][field].value = moment(calc[field]).format('DD.MM.YYYY')
              this.calcForms[i][field].oldValue = this.calcForms[i][field].value
            } else if (field === 'pts_date') {
              if (calc[field] === '0001-01-01T00:00:00') this.calcForms[i][field].value = null
              else this.calcForms[i][field].value = this.checkPtsDate(calc[field])
              this.calcForms[i][field].oldValue = this.calcForms[i][field].value
            } else {
              if (this.calcForms[i] && this.calcForms[i][field]) {
                this.calcForms[i][field].value = calc[field]
                this.calcForms[i][field].oldValue = this.calcForms[i][field].value
              }
            }
          })
        } else {
          let form = JSON.parse(JSON.stringify(this.calcForms[0]))
          Object.keys(calc).forEach(field => {
            if (field === 'prepaidDate') {
              if (calc[field] === '0001-01-01T00:00:00') this.form[i][field].value = null
              else form[field].value = moment(calc[field]).format('DD.MM.YYYY')
              form[field].oldValue = form[field].value
            } else {
              if (form[field]) {
                form[field].value = calc[field]
                form[field].oldValue = form[field].value
              }
            }
          })
          this.calcForms.push(form)
        }
      })
      this.calcForms.forEach((elem, index) => {
        if (+elem.year.value < 2022) {
          this.calcForms[index].wasInUse.value = true
          this.calcForms[index].wasInUse.oldValue = true
        }
      })
    }
  },
  beforeDestroy () {
    this.$emit('next', this.calcForms)
  },
  computed: {
    haveErrors () {
      return this.calcForms.filter(form => Object.keys(form).filter(key => form[key].error).length > 0).length > 0
    }
  },
  methods: {
    checkPtsDate (newValue) {
      const regExp = /^\d{4}-(?:0[1-9]|1[0-2])-(?:[0-2][1-9]|[1-3]0|3[01])T(?:[0-1][0-9]|2[0-3])(?::[0-6]\d)(?::[0-6]\d)?(?:\.\d{3})?(?:[+-][0-2]\d:[0-5]\d|Z)?$/
      if (regExp.test(newValue)) {
        return moment(newValue).format('DD.MM.YYYY')
      } else {
        return newValue
      }
    },
    wasInUseToggle (value, i) {
      if (+value < 2022) this.calcForms[i].wasInUse.value = true
    },
    saveCheck () {
      this.$store.state.step = 1
    },
    handleAgent (i) {
      if (!this.calcForms[i].agentCom.value) {
        this.calcForms[i].agent.value = ''
      }
    },
    checkErrors () {
      this.calcForms.forEach(form => {
        Object.keys(form).forEach(key => {
          form[key].error = !!(form[key].required && (form[key].value === '' || form[key].value === null))
        })
      })
    },
    downloadCalc (id) {
      window.open(`${process.env.VUE_APP_HTTP}/download?type=calc&name=Calc&id=${id}`, '_blank')
    },
    nextPage () {
      this.$store.state.step = 4
    },
    submitForm () {
      this.$emit('next', this.calcForms)
      this.nextPage()
    }
  }
}
</script>

<style lang="stylus" scoped>
  .old-value {
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    margin: 5px 10px;
    color: #f00;
    text-align: center;
    width: 100%;
  }
</style>

<template>
  <div class="modal" v-if="showModal && card">
    <div class="modal__back" @click="closeModal"></div>
    <div class="modal__body" style="flex-direction: column; margin-bottom: 70px">
      <div class="modal__body__attention">
        <h3 class="modal__attention__title">
          {{ card.type_doc }}
        </h3>
        <div class="modal__attention__text">
          {{ card.samplebp }}
        </div>
        <div class="modal__attention__text">
          {{ card.comment }}
        </div>
        <div v-if="card.history" class="modal__attention__subtitle">
          {{ card.history }}
        </div>
        <div v-if="card.visas" class="visas">
          <div class="modal__attention__title">Визы</div>
          <div v-for="(visa, index) in card.visas" :key="visa.autor + index" class="cardVisas__container text-hidden">
            <div class="cardVisas__autor">{{ shortName(visa.autor) }}</div>
            <div class="cardVisas__status tag"
              :class="{
                'tag--green': visa.result === 'Согласовано',
                'tag--yellow': visa.result === 'СогласованоСЗамечаниями',
                'tag--red': visa.result === 'НеСогласовано'
              }"
            >{{ visa.result === 'СогласованоСЗамечаниями' ? 'Замечания' : visa.result || 'ожидание' }}</div>
            <div class="cardVisas__comment">{{ visa.comment }}</div>
          </div>
        </div>
        <div v-if="card.docs && card.docs.length" class="docs">
          <div class="modal__attention__title">Документы</div>
          <ul>
            <li v-for="(item, index) in card.docs" :key="index"><a class="cardFiles__item" @click="downloadDocument(item.id)">{{`${item.name.length > 20 ? item.name.substr(0, 20) + '_' + item.ext : item.name}`}}</a></li>
          </ul>
        </div>
        <div v-if="card.type_doc === 'Реестр платежей'">
          <span class="modal__attention__title">Реестр платежей</span>
          <ul>
            <li>
              <a class="modal__attention__files" @click="downloadDoc(card.id_doc)">Скачать</a>
            </li>
          </ul>
        </div>
        <div>
          <div class="modal__attention__title">Комментарий</div>
          <input type="text" v-model="newComment">
        </div>
      </div>
      <div class="modal__attention__btns modal__attention__btns--mobile">
        <div class="modal__attention__btns" style="justify-content:center;">
          <button v-if="card.path_dot==='Исполнить'" class="modal__attention__btn modal__attention__btn--orange" @click="makeDone">Исполнено</button>
          <button v-if="card.path_dot==='Согласовать'" :disabled="card.newComment === ''" class="modal__attention__btn modal__attention__btn--green" @click="makeApprove">Согласовано</button>
          <button v-if="card.path_dot==='Согласовать'" :disabled="card.newComment === ''" class="modal__attention__btn modal__attention__btn--orange" @click="makeReject">Не согласовано</button>
          <button v-if="card.path_dot==='Проверить исполнение'" :disabled="card.newComment === ''" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Проверено</button>
          <button v-if="card.path_dot==='Проверить исполнение'" :disabled="card.newComment === ''" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturnForRevision">Вернуть на доработку</button>
          <button v-if="/Ознакомиться/.test(card.path_dot)" class="modal__attention__btn modal__attention__btn--green" @click="makeDone">Завершить</button>
          <button v-if="/результат/.test(card.path_dot) && card.result !== 'Согласовано'" class="modal__attention__btn modal__attention__btn--orange" @click="makeReturn">Повторить согласование</button>
          <button class="modal__attention__btn" @click="showModal = false">Закрыть</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GraphQLHelper from '@/helpers/GraphQLHelper'
import gql from 'graphql-tag'

const _graphQlHelper = new GraphQLHelper()

const BASE_URL = process.env.VUE_APP_HTTP

export default {
  name: 'CardInfoModal',
  data () {
    return {
      visas: [],
      docs: [],
      BASE_URL
    }
  },
  computed: {
    showModal: {
      get () {
        return this.$store.state.data.tasks.mobileModal.showInfo
      },
      set (value) {
        this.$store.state.data.tasks.mobileModal.showInfo = value
      }
    },
    card () {
      return this.$store.state.data.tasks.mobileModal.data
    },
    newComment: {
      get () {
        return this.$store.state.data.tasks.mobileModal.newComment
      },
      set (value) {
        this.$store.state.data.tasks.mobileModal.newComment = value
      }
    }
  },
  beforeDestroy () {
    this.$store.state.data.tasks.mobileModal = {
      show: false,
      showInfo: false,
      data: null
    }
  },
  methods: {
    fileName (file) {
      if (file.name.length > 20) {
        return file.name.substr(0, 20) + '_.' + file.ext
      } else {
        if (/\./.test(file.name)) return file.name
        else return `${file.name}.${file.ext}`
      }
    },
    closeModal () {
      this.$store.state.data.tasks.mobileModal.showInfo = false
    },
    shortName (name) {
      const splitName = name.split(' ')
      return `${splitName[0]} ${splitName[1] ? splitName[1][0] : ''}.${splitName[2] ? splitName[2][0] : ''}.`
    },
    downloadDocument (id) {
      window.open(`${BASE_URL}/file?id=${id}`, '_blank')
    },
    async downloadDoc (id) {
      window.open(`${BASE_URL}/download?id=${id}&type=registry`, '_blank')
    },
    makeDone () {
      this.showModal = false
      this.updateTask('done') // Исполнено или ознакомлено
    },
    makeApprove () {
      this.showModal = false
      this.updateTask('approve') // Согласовано
    },
    makeReject () {
      this.showModal = false
      this.updateTask('reject') // Не согласовано
    },
    makeReturn () {
      this.showModal = false
      this.updateTask('return') // Повторить согласование
    },
    makeReturnForRevision () {
      this.showModal = false
      this.updateTask('revision') // Доработка
    },
    async updateTask (type) {
      await this.$apollo.mutate({
        mutation: gql`mutation updateDOTask($ids: [String!], $type: String, $comment:String) {
          updateDOTask (ids: $ids, type: $type, comment: $comment)
        }`,
        variables: {
          type,
          comment: this.newComment,
          ids: [this.card.id]
        }
      }).then(() => {
        this.$router.go()
      }).catch(error => {
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: item
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

  $fs-card-comment: 0.7em;

  .cardVisas {
    display: flex;
    flex-direction: column;
    // height: 100%;
    width: 100%;
    // scroll-snap-align: end;
    max-height: 40%;
  }

  .cardVisas__container {
    display: grid;
    // grid-auto-rows: minmax(100px, auto);
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 10px;
    grid-template-areas:
      "autor status"
      "comment comment";
  }

  .cardVisas__autor {
    grid-area: autor;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .cardVisas__status {
    grid-area: status;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardVisas__comment {
    grid-area: comment;
    display: flex;
    padding: auto 10px;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: $fs-card-comment;
    max-height: 30%;
  }

  .cardFiles__item {
    cursor: pointer;
    color: #FF8000;
    z-index: 3;
  }

  .visas {
    margin-top: 10px;
  }

  .modal__attention__btns--mobile {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #212529;
    height: 60px;
    padding: 10px
  }
</style>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body trade__modal--clamp">
      <div class="trade__modal">
        <div class="trade__modal__header">
          <h1>Основное</h1>
          <button class="btn btn--gray" @click.prevent="switchModal">Закрыть</button>
        </div>
        <div class="trade__modal__body">
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label for="name">Наименование:</label>
                <input type="text" v-model="form.name" id="name">
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label for="name">Статус:</label>
                <v-select
                  :value="{ label: $store.state.leadStatusListClear.find(s => s.value === form.status).title, value: form.status }"
                  :options="$store.state.leadStatusListClear.filter(elem => elem.value !== 'atWork').map(item => ({ value: item.value, label: item.title }))"
                  placeholder="Статус сделки"
                  @input="onStatusChange"
                />
              </div>
            </div>
          </div>
          <div class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Теги:</label>
                <TagsInput :tags="$store.state.Lead ? $store.state.Lead.tags.map(item => ({value: item.id, label: item.name})) : []" @input="onTagsInput"/>
              </div>
            </div>
          </div>
          <div v-if="form.company.inn && ($store.state.me.role !== 'sop' || (form.responsible_user && $store.state.me._id === form.responsible_user._id))" class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Ответственный:</label>
                <v-select :value="{ label: form.responsible_user.name, value: form.responsible_user._id}" :options="$store.state.allUsers.map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onUserChange"/>
              </div>
            </div>
          </div>
          <div v-if="$store.state.me.role === 'admin' || ($store.state.me.role === 'callcenter' && $store.state.Lead.internet)" class="ct__row">
            <div class="ct__col">
              <div class="ct__input">
                <label>Создатель:</label>
                <v-select :value="{ label: form.creator.name, value: form.creator._id}" :options="$store.state.allUsers.filter(user => user.role === 'callcenter' && !user.banned).map(item => ({ value: item.id, label: item.name }))" placeholder="" @input="onCreatorChange"/>
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="$store.state.Lead.status === 'first' && ($store.state.me.role === 'admin' || $store.state.me.role === 'callcenter')">
            <div class="ct__col">
              <div class="ct__input">
                <label>ИНН:</label>
                <CompanyInput
                  columnName="inn"
                  :currentValue="form.graphItem.inn"
                  filterName="q"
                  @change="onCompanyChange"
                />
              </div>
            </div>
          </div>
          <div class="ct__row" v-if="!['spam', 'first', 'closed'].includes($store.state.Lead.status)">
            <div class="ct__col">
              <div class="ct__input">
                <label>Продукт:</label>
                <v-select
                  v-if="$store.state.Lead.status === 'created'"
                  v-model="selectProduct"
                  :options="products"
                  placeholder="Изменить продукт"
                ></v-select>
                <select
                  v-else
                  disabled
                  placeholder="Продукт не выбран"
                >
                  <option value="selectProduct">{{ $store.state.Lead.status.product }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="trade__modal__footer">
          <button class="btn" @click.prevent="saveTrade($store.state.Lead.project_id)" v-if="!isChanged">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CompanyInput from '../../../ui/GraphInputs/CompanyInput'
import TagsInput from '@/components/ui/GraphInputs/TagsInput'
import GraphQLHelper from '@/helpers/GraphQLHelper'

import CHANGE_PROJECT from '@/graphql/mutations/ChangeProjectParams.gql'

// import ExternalIcon from '../../components/svg/ExternalIcon'

const _graphQlHelper = new GraphQLHelper()

export default {
  name: 'TradeMainDataModal',
  components: { CompanyInput, TagsInput },
  data () {
    return {
      products: [
        'Недвижимость',
        'Стандарт',
        'СуперЭкспресс'
        // 'СуперЭкспрессРФ'
      ],
      selectProduct: this.$store.state.Lead ? this.$store.state.Lead.product : null,
      form: {
        status: '',
        name: '',
        responsible_user: {
          _id: null,
          name: null
        },
        tags: [],
        company: {
          _id: null,
          inn: null
        },
        graphItem: {
          _id: null,
          inn: null
        },
        creator: {
          _id: null,
          name: null
        }
      }
    }
  },
  computed: {
    showModal () {
      return this.$store.state.data.trades.mainDataModal
    },
    isChanged () {
      return this.form.responsible_user._id === this.$store.state.Lead.responsible_user._id &&
        (this.form.creator.name === this.$store.state.Lead.creator.name) &&
        (this.form.name === this.$store.state.Lead.name && this.form.name !== '') &&
        (this.form.status === this.$store.state.Lead.status && this.form.status !== '') &&
        JSON.stringify(this.form.tags) === JSON.stringify(this.$store.state.Lead.tags.map(item => item.id)) &&
        this.form.company.inn === null
        // (this.form.company.inn === this.$store.state.Lead.company.inn && this.form.company)
    }
  },
  watch: {
    '$store.state.data.trades.mainDataModal' () {
      if (this.$store.state.Lead && this.$store.state.Lead.name) {
        this.form.name = this.$store.state.Lead.name
      } else {
        this.form.name = this.$store.state.Lead.company && this.$store.state.Lead.company.name ? this.$store.state.Lead.company.name : ''
      }
      this.form.status = this.$store.state.Lead.status
      this.form.responsible_user._id = this.$store.state.Lead.responsible_user._id
      this.form.responsible_user.name = this.$store.state.Lead.responsible_user.name
      this.form.tags = this.$store.state.Lead.tags.map(item => item.id)
      this.form.company._id = this.$store.state.Lead.company._id
      this.form.company.inn = this.$store.state.Lead.company.inn
      this.form.creator._id = this.$store.state.Lead.creator._id
      this.form.creator.name = this.$store.state.Lead.creator.name
      // this.selectProduct = this.$store.state.Lead.product
    }
  },
  methods: {
    async changeProduct (projectId, product) {
      await this.$apollo.mutate({
        mutation: CHANGE_PROJECT,
        variables: {
          projectId: projectId,
          data: {
            product: product
          }
        },
        update: (store, { data }) => {
          if (data) {
            this.$notify({
              group: 'lsg-notify',
              text: 'Продукт успешно изменен'
            })
          }
        }
      }).catch(error => {
        this.loadCalc = false
        _graphQlHelper.graphQLErrorMessages(error).forEach(item => {
          this.$notify({
            group: 'lsg-notify',
            text: 'Ошибка при изменении продукта'
          })
        })
      })
    },
    saveTrade (projectId) {
      this.$store.dispatch('updateLead', {
        context: this,
        data: {
          id: this.$store.state.Lead._id,
          status: this.form.status,
          name: this.form.name,
          responsible_user_id: this.form.responsible_user._id,
          created_by: this.form.creator._id,
          tags: this.form.tags,
          company_id: this.form.company._id
        }
      }).then(async () => {
        if (this.selectProduct && projectId) {
          await this.changeProduct(projectId, this.selectProduct)
        }
        this.$notify({
          group: 'lsg-notify',
          text: `Данные сделки обновлены`
        })
        this.$store.commit('incrementTradeRerenderKey')
        this.$store.commit('incrementCompanyRerenderKey')
        this.switchModal()
        // this.$router.go()
      })
    },
    onUserChange (e) {
      if (e) {
        this.form.responsible_user._id = e.value
        this.form.responsible_user.name = e.label
      } else {
        this.form.responsible_user._id = null
        this.form.responsible_user.name = null
      }
    },
    onCreatorChange (e) {
      if (e) {
        this.form.creator._id = e.value
        this.form.creator.name = e.label
      } else {
        this.form.creator._id = null
        this.form.creator.name = null
      }
    },
    onStatusChange (status) {
      if (status) {
        this.form.status = status.value
      } else {
        this.form.status = null
      }
    },
    onCompanyChange (item) {
      if (item) {
        this.form.graphItem = item
        this.form.company._id = item._id
        this.form.company.inn = item.inn
      } else {
        this.form.graphItem._id = null
        this.form.graphItem.inn = null
        this.form.company._id = null
        this.form.company.inn = null
      }
    },
    onTagsInput (tags) {
      this.form.tags = tags.map(item => item.value)
    },
    switchModal () {
      this.$store.state.data.trades.mainDataModal = !this.$store.state.data.trades.mainDataModal
    }
  }
}
</script>

<template>
  <div class="modal" v-if="showModal">
    <div class="modal__back" @click="switchModal"></div>
    <div class="modal__body">
      <div class="modal__attention">
        <i class="modal__attention__icon">
          <AttentionIco/>
        </i>
        <span class="modal__attention__title">Проверка пройдена успешно</span>
        <span class="modal__attention__subtitle"></span>
        <div class="modal__attention__btns">
          <button class="modal__attention__btn modal__attention__btn--orange" @click="switchModal">OK</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AttentionIco from '@/components/svg/attention'

export default {
  name: 'CheckTradeSuccessModal',
  components: { AttentionIco },
  computed: {
    showModal () {
      return this.$store.state.data.trades.checkSuccessModal
    }
  },
  methods: {
    switchModal () {
      this.$store.state.data.trades.checkSuccessModal = !this.$store.state.data.trades.checkSuccessModal
      this.$store.state.data.trades.loadCheckTradeData = false
      // this.$router.go()
    }
  }
}
</script>
